import * as React from "react";
import { JobsTableFilter } from "../../components/Jobs/JobsTableFilter";
import { JobsTable } from "../../components/Jobs/JobsTable";
import { applySpec, defaultTo, find, path, pathOr, pipe, prop, propEq, propOr } from "ramda";
import { isPresent } from "../../lib/functions";
import { useQuery } from "@apollo/client";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { JobsFilterState, useJobsFilter } from "../../contexts/jobs-filter-context";

const JobsPage: React.FC<{}> = () => {
  const { state, updateJobsFilter } = useJobsFilter();

  const { data } = useQuery(GET_TECHNICIANS);
  if (!data) return null;
  const jobNumber = propOr(null, "jobNumber", state);
  const filters = {
    "jobCopy.type": propOr(null, "type", state),
    "jobCopy.status": propOr(null, "status", state),
    technician: propOr(null, "technician", state),
    "jobCopy.market": propOr(null, "market", state),
    ...(isPresent(jobNumber) ? { "jobCopy.jobNumber": { $regex: `^${jobNumber}` } } : {}),
    startDate:
      state.startRange || state.endRange
        ? {
            $lte: propOr(null, "endRange", state),
            $gte: propOr(null, "startRange", state),
          }
        : null,
    "jobCopy.partsInfo.partsOrdered": propOr(null, "partsOrdered", state),
  };
  const techToPassIntoForm = pipe(
    find(propEq("id", prop("technician", filters))),
    defaultTo(null)
  )(data.getTechnicians);
  // @ts-ignore
  const filtersToValuesSpec: JobsTableFilterFormValues = applySpec({
    endRange: path(["startDate", "$lte"]),
    startRange: path(["startDate", "$gte"]),
    technician: (...args) => techToPassIntoForm,
    market: prop("jobCopy.market"),
    partsOrdered: prop("jobCopy.partsInfo.partsOrdered"),
    status: prop("jobCopy.status"),
    type: prop("jobCopy.type"),
  })(filters);
  return (
    <div className="px-8 py-4 overflow-hidden">
      <div className="overflow-auto">
        <JobsTableFilter
          initialValues={filtersToValuesSpec}
          onSubmit={(values: JobsFilterState) => {
            updateJobsFilter({
              ...values,
              technician: pathOr(null, ["technician", "id"], values),
            });
          }}
        />
        <JobsTable filters={filters} endAdornments={undefined} />
      </div>
    </div>
  );
};

export default JobsPage;
