import React from "react";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { ContactColumns } from "../Contacts/ContactsTable";
import { normalizePhone } from "../../lib/normalizers";

export const ContactsSearchResults: React.FC<{ contacts: any[] }> = ({ contacts }) => {
  const data = contacts.map((c) => ({
    ...c,
    phoneNumber: normalizePhone(c.phoneNumber),
  }));
  return (
    <div className={"m-6"}>
      <h4>Contacts</h4>
      <SelectableEntityTable data={data} title="Contacts" columns={ContactColumns} numRecords={contacts.length} />
    </div>
  );
};
