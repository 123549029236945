import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { FilterSelectField } from "../FormFields/FilterSelectField";
import { Form, Formik } from "formik";
import { useMarkets } from "../../hooks/useMarkets";
import { DebouncedTextField as TextField } from "../FormFields/DebouncedTextField";
import { Button } from "../Buttons/Button";
import { defaultTo, pluck } from "ramda";
import { JOB_STATUS_MASTER_LIST } from "../../lib/constants";

export type ServiceRequestsFilterFormValues = {
  status: string;
  market: string;
  jobNumber: string;
  firstName: string;
  lastName: string;
};

export const ServiceRequestsTableFilter: React.FC<{
  initialValues: ServiceRequestsFilterFormValues;
  onSubmit: any;
}> = ({ initialValues, onSubmit }) => {
  const markets = useMarkets();
  return (
    <Formik<ServiceRequestsFilterFormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ resetForm, submitForm, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          submitForm();
        }, [submitForm, values]);
        return (
          <Form>
            <Box p={2} m={"auto"} display="flex" flexDirection="row">
              <div className={"grid grid-cols-3 gap-4"}>
                <Box>
                  <FilterSelectField label={"Status"} options={JOB_STATUS_MASTER_LIST} name={"status"} />
                </Box>
                <Box>
                  <FilterSelectField
                    label={"Market"}
                    options={defaultTo([], markets && pluck("name", markets))}
                    name={"market"}
                  />
                </Box>
                <Box className="flex items-center">
                  <TextField name={"jobNumber"} label={"Job Number"} />
                </Box>
                <Box className="flex items-center">
                  <TextField name={"firstName"} label={"First Name"} />
                </Box>
                <Box className="flex items-center">
                  <TextField name={"lastName"} label={"Last Name"} />
                </Box>
                <Button
                  onClick={() => {
                    resetForm({
                      touched: {
                        market: false,
                        status: false,
                      },
                      values: {
                        status: null,
                        market: null,
                        jobNumber: null,
                        firstName: null,
                        lastName: null,
                      },
                    });
                  }}
                >
                  Reset
                </Button>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
