import gql from "graphql-tag";

export const VALIDATE_PROMO_CODE = gql`
  mutation validatePromoCode($code: String!, $jobId: String) {
    validatePromoCode(code: $code, jobId: $jobId) {
      id
      code
      name
      status
      coupon {
        name
        valid
        discount {
          type
          amount
          reason
          total
        }
        totalRedemptions
        createdBy
        createdAt
      }
      startDate
      expiry
      combinable
      totalRedemptions
    }
  }
`;
