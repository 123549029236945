import gql from "graphql-tag";
import { jobListFields } from "./getJobsPaginatedV2";

export const GET_JOBS = gql`query getJobs($cursor: String, $limit: Int, $filter: JSON) {
    getJobs(cursor: $cursor, limit: $limit, filter: $filter) {
        edges {
            ${jobListFields}
        }
        pageInfo {
            hasNextPage
            offset
            numRecords
            endCursor
        }
    }
}`;
