import * as React from "react";
import { applySpec, prop, propOr } from "ramda";
import { useQuery } from "@apollo/client";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { JobsFilterState, useJobsFilter } from "../../contexts/jobs-filter-context";
import { ServiceRequestsTableFilter } from "../../components/ServiceRequests/ServiceRequestsTableFilter";
import { ServiceRequestsTable } from "../../components/ServiceRequests/ServiceRequestsTable";

const ServiceRequestsPage: React.FC<{}> = () => {
  const { state, updateJobsFilter } = useJobsFilter();

  const { data } = useQuery(GET_TECHNICIANS);
  if (!data) return null;
  const filters = {
    status: propOr(null, "status", state),
    market: propOr(null, "market", state),
    jobNumber: propOr(null, "jobNumber", state),
    "contactCopy.firstName": propOr(null, "firstName", state),
    "contactCopy.lastName": propOr(null, "lastName", state),
  };
  // @ts-ignore
  const filtersToValuesSpec: ServiceRequestsFilterFormValues = applySpec({
    market: prop("market"),
    status: prop("status"),
    jobNumber: prop("jobNumber"),
    firstName: prop("contactCopy.firstName"),
    lastName: prop("contactCopy.lastName"),
  })(filters);
  return (
    <div className="px-8 py-4 overflow-hidden">
      <div className="overflow-auto">
        <ServiceRequestsTableFilter
          initialValues={filtersToValuesSpec}
          onSubmit={(values: JobsFilterState) => {
            updateJobsFilter({
              ...values,
            });
          }}
        />
        <ServiceRequestsTable filters={filters} endAdornments={undefined} />
      </div>
    </div>
  );
};

export default ServiceRequestsPage;
