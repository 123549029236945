import React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Contact,
  Query,
  QuerySearchContactsArgs,
} from "../../generated/nest-graphql";
import { SEARCH_CONTACTS } from "../../graphql/queries/searchContacts";
import { useField, useFormikContext } from "formik";
import { map, path, pathOr, pipe, prop } from "ramda";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Tooltip from "@material-ui/core/Tooltip";
import { ErrorTextField } from "./ErrorTextField";
import { useToggle } from "../../hooks/useToggle";
import { useDebounce } from "../../hooks/useDebounce";

export const ContactsSelect: React.FC<{
  name: string;
  label: string;
  required?: boolean;
}> = ({ name, required, label }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  const result = field.value
    ? {
        label: `${field.value.firstName} ${field.value.lastName}`,
        value: field.value,
      }
    : field.value;
  const [contactInput, setContactInput] = useState(result ? result.label : "");
  const contactsToOptions = (contact: Contact) => {
    const { firstName, lastName } = contact;
    return {
      label: `${firstName} ${lastName}`,
      value: contact,
    };
  };

  const debouncedInput: string = useDebounce(contactInput);
  const { data, loading } = useQuery<Query, QuerySearchContactsArgs>(
    SEARCH_CONTACTS,
    {
      variables: { query: debouncedInput },
    }
  );

  const contactOptions = pipe(
    pathOr([], ["searchContacts"]),
    map(contactsToOptions)
  )(data);

  const [open, , toggle] = useToggle();
  const clearValues = () => {
    helpers.setValue(null);
    setFieldValue("email", "");
    setFieldValue("serviceLocation", "");
    setFieldValue("make", "");
    setFieldValue("model", "");
    setFieldValue("year", "");
    setFieldValue("extraInfo", "");
    setFieldValue("licensePlate", "");
    setFieldValue("vin", "");
    setFieldValue("symptoms", "");
  };

  const onChange = (_: object, value: any) => {
    if (!value) {
      clearValues();
      return;
    }
    // @ts-ignore
    const contact: Contact = prop("value", value);
    helpers.setValue(contact);
    setContactInput(prop("label", value));
    setFieldValue("email", path(["email"], contact));
    setFieldValue("serviceLocation", path(["lead", "address"], contact));
    setFieldValue("make", path(["lead", "vehicleInfo", "make"], contact));
    setFieldValue("model", path(["lead", "vehicleInfo", "model"], contact));
    setFieldValue("year", path(["lead", "vehicleInfo", "year"], contact));
    setFieldValue(
      "extraInfo",
      path(["lead", "vehicleInfo", "extraInfo"], contact)
    );
    setFieldValue(
      "licensePlate",
      path(["lead", "vehicleInfo", "licensePlate"], contact)
    );
    setFieldValue(
      "licensePlateState",
      path(["lead", "vehicleInfo", "licensePlateState"], contact)
    );
    setFieldValue("vin", path(["lead", "vehicleInfo", "vin"], contact));
    setFieldValue(
      "symptoms",
      path(["lead", "vehicleInfo", "symptoms"], contact)
    );
  };
  return (
    <Autocomplete
      open={open}
      onOpen={toggle}
      onClose={toggle}
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={prop("label")}
      value={{ label: contactInput }}
      options={contactOptions}
      onChange={onChange}
      onInputChange={(_, value) => setContactInput(value)}
      loading={loading}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {field.value && (
                  <Tooltip title="Edit Contact">
                    <InputAdornment style={{ marginTop: 0 }} position="start">
                      <Link to={`/contacts/${field.value.id}`}>
                        <AccountCircle />
                      </Link>
                    </InputAdornment>
                  </Tooltip>
                )}
              </>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          helperText={
            <>
              {meta.error && (
                <ErrorTextField displayOnSpan={true} fieldName={field.name} />
              )}
            </>
          }
        />
      )}
    />
  );
};
