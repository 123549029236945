import React, { Fragment } from "react";
import {
  ThemeProvider,
  Switch,
  createTheme,
  TextField,
  makeStyles,
  Card,
} from "@material-ui/core";
import { Field } from "formik";
import { pipe } from "fp-ts/lib/function";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { ProductSelectionOption } from "./ProductRow";
import { allPass, always, cond, T } from "ramda";
import { WARN_UNITS } from "../../lib/constants";
import { useShowWarning } from "../../redux/slices/snackbar";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";

type LaborTableProps = {
  serviceIdx: number;
  laborIdx: number;
  ejiType?: string;
  taxable: boolean;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
};

const useStyles = makeStyles({
  numberInput: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      track: {
        // unchecked coloring
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // checked coloring
          opacity: 1,
          backgroundColor: "#3CBC45",
        },
      },
    },
  },
});

const LaborTable = ({ serviceIdx, laborIdx, taxable, ejiType, calculatePossibleEJIPriceInfo }: LaborTableProps) => {
  const classes = useStyles();
  const showWarning = useShowWarning();
  return (
    <Card
      variant="outlined"
      className="flex justify-between gap-5 px-5 py-2 w-full max-w-1/3"
      style={{
        borderColor: "rgb(81, 81, 81)",
        background: "transparent",
      }}
    >
      <Field name={`services[${serviceIdx}].items[${laborIdx}].overriddenCustomerPrice`}>
        {({ field: { value }, form: { setFieldValue, values } }) => {
          const targetProduct = values?.services[serviceIdx]?.items[laborIdx];
          const markupText = cond([
            [
              allPass([
                () => targetProduct?.marketLaborRateUsed != null,
                () => !targetProduct?.overriddenCustomerPrice,
              ]),
              always(`${targetProduct?.marketLaborRateUsed}/hr`),
            ],
            [T, always("-")],
          ])();
          return (
            <div className="flex justify-between px-3">
              <div className="mt-1">
                <h3 className="m-0 mb-2 text-xl">Labor</h3>
                <span className="text-lg text-gray-500">{markupText}</span>
              </div>
              <ThemeProvider theme={theme}>
                <Switch
                  checked={!value}
                  color="default"
                  onChange={(val) => {
                    if (!val.target.checked) {
                      setFieldValue(
                        `services[${serviceIdx}].items[${laborIdx}].overriddenCustomerPrice`,
                        !val.target.checked
                      );
                    } else {
                      const servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      servicesCopy[serviceIdx].items[laborIdx].overriddenCustomerPrice = false;
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                            services: servicesToPossibleEjiServiceInput(servicesCopy),
                            discounts: discountsToEjiDiscountInput(values.discounts),
                            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                            marketName: values.market,
                            taxable: taxable,
                            calculateAllServices: ejiType === "INVOICE",
                          },
                        },
                      });
                    }
                  }}
                />
              </ThemeProvider>
            </div>
          );
        }}
      </Field>
      <div className="flex gap-5">
        <Field name={`services[${serviceIdx}].items[${laborIdx}].units`}>
          {({ field: { value }, meta: { error }, form: { values } }) => {
            return (
              <div className="flex flex-col gap-y-2 w-full">
                <label className="text-lg">Hours</label>
                <TextField
                  value={value}
                  fullWidth
                  required
                  variant="outlined"
                  onChange={(e) => {
                    const newUnits = Number(e.target.value);
                    let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                    servicesCopy[serviceIdx].items[laborIdx].units = newUnits;
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(servicesCopy),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    if (newUnits > WARN_UNITS) {
                      showWarning({ message: "Units is higher than normal" });
                    }
                  }}
                  error={error}
                  type="number"
                  className={classes.numberInput}
                  InputProps={{
                    inputProps: { min: 0, step: "any" },
                  }}
                />
              </div>
            );
          }}
        </Field>
        <Field name={`services[${serviceIdx}].items[${laborIdx}].customerPrice`}>
          {({ field: { value, name }, meta: { error }, form: { values, setFieldValue } }) => {
            const targetProduct = values?.services[serviceIdx]?.items[laborIdx];
            return (
              <Fragment>
                <div className="flex flex-col gap-y-2 w-full">
                  <label className="text-lg">Total Labor Price</label>
                  <DebouncedCurrencyInputV2
                    name={name}
                    value={value}
                    error={error}
                    disabled={!targetProduct?.overriddenCustomerPrice}
                    onChange={(newCustomerPrice, values) => {
                      setFieldValue(name, newCustomerPrice);
                      let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      servicesCopy[serviceIdx].items[laborIdx].customerPrice = newCustomerPrice;
                      if (newCustomerPrice !== null) {
                        servicesCopy[serviceIdx].items[laborIdx].overriddenCustomerPrice = true;
                      }
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                            services: servicesToPossibleEjiServiceInput(servicesCopy),
                            discounts: discountsToEjiDiscountInput(values.discounts),
                            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                            marketName: values.market,
                            taxable: taxable,
                            calculateAllServices: ejiType === "INVOICE",
                          },
                        },
                      });
                    }}
                    label=""
                  />
                </div>
              </Fragment>
            );
          }}
        </Field>
      </div>
    </Card>
  );
};

export default LaborTable;
