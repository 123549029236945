import React from "react";
import { useQuery } from "@apollo/client";
import { Contact, Payment, Query, QueryGetJobArgs, QueryGetPaymentsArgs } from "../../generated/nest-graphql";
import { acquireJobItemsTotal, isStripeReady } from "../../lib/functions";
import { find, isEmpty, path, pathOr, pipe, pluck, prop, propEq, subtract, sum } from "ramda";
import { GET_PAYMENTS } from "../../graphql/queries/getPayments";
import { GET_JOB } from "../../graphql/queries/getJob";
import Typography from "@material-ui/core/Typography";
import { JobStripeCardDetails, StripeCard } from "./JobStripeCardDetails";
import { JobStripeCardCapture } from "./JobStripeCardCapture";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";

export const JobStripeCard: React.FC<{ jobId: string }> = ({ jobId }) => {
  const { data: dataJob } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });
  const { data, loading, refetch } = useQuery<Query, QueryGetPaymentsArgs>(GET_PAYMENTS, {
    variables: {
      filter: {
        job: jobId,
        status: "on hold",
      },
    },
  });

  if (!dataJob && loading && !data) return null;

  const cardsOnHold: Payment[] = pathOr([], ["getPayments", "edges"])(data);
  const contact: Contact = path(["getJob", "contact"], dataJob);
  if (!isStripeReady(contact)) {
    return (
      <Typography variant="h6" display="block" gutterBottom>
        Contact does not have cards registered.
      </Typography>
    );
  }

  // Acquire total
  const serviceCatalogueUsed = pathOr(
    DEFAULT_SERVICE_CATALOGUE_USED,
    ["getJob", "invoice", "serviceCatalogueUsed"],
    dataJob
  ) as string;
  const amountDue =
    serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES
      ? pathOr(null, ["getJob", "invoice", "priceInfo", "amountDue"], dataJob)
      : pathOr(null, ["getJob", "invoice", "balanceDue"], dataJob);
  const total: number = amountDue
    ? Number(amountDue)
    : serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES
    ? pathOr(0, ["getJob", "priceInfo", "amountDue"], dataJob)
    : acquireJobItemsTotal(prop("getJob", dataJob), 0);

  if (cardsOnHold && isEmpty(cardsOnHold)) {
    return (
      <JobStripeCardCapture
        jobId={jobId}
        refetch={refetch}
        contactId={contact.id}
        total={total}
        hasAppointment={!!path(["getJob", "appointment"], dataJob)}
        paymentMethods={prop("stripePaymentMethods", contact)}
      />
    );
  }

  const uncapturedAmount: number = pipe(pluck("amount") as any, sum, subtract(total))(cardsOnHold);

  return (
    <>
      {cardsOnHold.map((cardOnHold: Payment, index: number) => {
        const card = pipe(
          prop("stripePaymentMethods"),
          // @ts-ignore
          find(propEq("id", prop("paymentMethod", cardOnHold))),
          // @ts-ignore
          prop("card")
          // @ts-ignore
        )(contact);

        return (
          <JobStripeCardDetails
            key={index}
            jobId={jobId}
            card={card as StripeCard}
            cardOnHold={cardOnHold}
            refetch={refetch}
          />
        );
      })}
      {uncapturedAmount > 0 && (
        <JobStripeCardCapture
          jobId={jobId}
          refetch={refetch}
          contactId={contact.id}
          total={uncapturedAmount}
          hasAppointment={!!path(["getJob", "appointment"], dataJob)}
          paymentMethods={prop("stripePaymentMethods", contact)}
          noHeldCards={false}
          title={"Hold card with uncaptured Amount"}
        />
      )}
    </>
  );
};
