import React from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetContactsPaginatedArgs } from "../../generated/nest-graphql";
import { applySpec, mergeDeepRight, pipe, prop } from "ramda";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { Column } from "react-table";
import { GET_CONTACTS_PAGINATED } from "../../graphql/queries/getContactsPaginated";
import { AddToolbarButton } from "../Table/AddToolbarButton";
import { ROW_LIMIT } from "../../lib/constants";
import { normalizePhone } from "../../lib/normalizers";

type ContactsTableType = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export const ContactColumns: Column<ContactsTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/contacts/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "First Name", accessor: "firstName" },
  { Header: "Last Name", accessor: "lastName" },
  { Header: "Phone Number", accessor: "phoneNumber" },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Created At", accessor: "createdAt" },
  { Header: "Updated At", accessor: "updatedAt" },
];

export const ContactsTable: React.FC<{}> = () => {
  const queryKey = "getContactsPaginated";
  const { data, fetchMore } = useQuery<Query, QueryGetContactsPaginatedArgs>(GET_CONTACTS_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        skip: 0,
        limit: ROW_LIMIT,
      },
    },
  });
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          skip: data.getContactsPaginated.pageInfo.offset + ROW_LIMIT,
          limit: ROW_LIMIT,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getContactsPaginated: {
            edges: [...prev.getContactsPaginated.edges, ...fetchMoreResult.getContactsPaginated.edges],
            pageInfo: mergeDeepRight(prev.getContactsPaginated.pageInfo, fetchMoreResult.getContactsPaginated.pageInfo),
            __typename: prev.getContactsPaginated.__typename,
          },
        };
      },
    });
  };
  if (data) {
    return (
      <SelectablePageableEntityTable<ContactsTableType>
        title={"Contacts"}
        queryKey={queryKey}
        loadMore={loadMore}
        queryResult={data}
        limit={ROW_LIMIT}
        spec={contactsToContactColumnSpec}
        columns={ContactColumns}
        numRecords={data.getContactsPaginated.pageInfo.numRecords}
        endAdornments={<AddToolbarButton link="/add/contacts" title="Contact" />}
      />
    );
  }
  return null;
};
export const contactsToContactColumnSpec = applySpec({
  id: prop("id"),
  firstName: prop("firstName"),
  lastName: prop("lastName"),
  phoneNumber: pipe(prop("phoneNumber"), normalizePhone),
  createdBy: prop("createdBy"),
  createdAt: pipe(prop("createdAt"), formatDateTime),
  updatedAt: pipe(prop("updatedAt"), formatDateTime),
});
