import { applySpec, prop } from "ramda";
import { Part } from "../../generated/nest-graphql";
import { ProductSelectionOption } from "../Services/ProductRow";

export const partSpec = (val: Part) => {
  return applySpec<ProductSelectionOption>({
    id: prop("id"),
    partId: prop("partId"),
    label: prop("label"),
    value: prop("productName"),
    vendorUnitCost: prop("vendorUnitCost"),
    type: prop("type"),
  })(val);
};
