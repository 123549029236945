import { createStyles, createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Themes
export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#f04e23",
    },
    secondary: {
      main: "#f44336",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: "#1a202c",
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
    MuiMenuItem: {
      gutters: {
        backgroundColor: "#202020",
      },
      root: {
        "&$selected": {
          backgroundColor: "blue",
        },
        "&$selected:hover": {
          backgroundColor: "blue",
        },
        "&:hover": {
          backgroundColor: "blue",
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#000",
      },
    },
  },
});

// Styles
export const labelStyles = makeStyles({
  root: { fontWeight: "bold", color: "white" },
});
export const errorLabelStyles = makeStyles({ root: { color: "#ff0000" } });
export const filterDropDownStyles = makeStyles((theme) =>
  createStyles({ root: { margin: theme.spacing(1), minWidth: 250 } })
);
export const detailsViewTitleStyles = makeStyles({
  root: { backgroundColor: "#202020" },
});
export const jobDetailsFormStyles = makeStyles({
  receiptItemPaper: {
    backgroundColor: "#202020",
    marginTop: 18,
  },
});
//Removes the bottom border from all direct children, in ServiceRow, lets ProductRows look grouped with the service
export const serviceRowStyles = makeStyles({
  serviceRowBottomBorders: {
    "& > *": {
      borderBottom: "none",
    },
  },
});
