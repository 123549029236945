import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { debounce } from "lodash";

interface NumberFormatCustomProps {
  // @ts-ignore
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}

const DebouncedCurrencyInput: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  postOnChange?: (value: string) => void;
}> = ({ name, label, postOnChange = (val) => val, required = false }) => {
  const [{ value }, meta, { setValue }] = useField(name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(setValue, 500), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postOnChangeHandler = useCallback(debounce(postOnChange, 500), []);
  const [textVal, setTextVal] = useState(value);
  useEffect(() => {
    setTextVal(value);
  }, [value]);
  return (
    <TextField
      fullWidth
      error={!!meta.error}
      variant={"outlined"}
      name={name}
      label={label}
      onChange={(e) => {
        const value = e.target.value;
        setTextVal(value);
        handler(value);
        postOnChangeHandler(value);
      }}
      required={required}
      helperText={<ErrorTextField fieldName={name} displayError={!!meta.error} />}
      value={textVal}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

export default DebouncedCurrencyInput;
