import * as React from "react";
import currency from "currency.js";
import { useState } from "react";
import { Query, QueryGetInvoiceArgs, QueryGetPaymentsPaginatedArgs } from "../../generated/nest-graphql";
import { useQuery } from "@apollo/client";
import { Container } from "../Container";
import { PaymentColumns, paymentsToPaymentColumns } from "../Payments/PaymentsTable";
import { GET_INVOICE } from "../../graphql/queries/getInvoice";
import { mergeDeepRight, path, prop, propOr } from "ramda";
import { Button } from "../Buttons/Button";
import { AddPaymentForm } from "../Payments/AddPaymentForm";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { DEFAULT_SERVICE_CATALOGUE_USED, ROW_LIMIT, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { pipe } from "fp-ts/lib/function";
import { GET_PAYMENTS_PAGINATED } from "../../graphql/queries/getPaymentsPaginated";

const getPaymentInput = (serviceCatalogueUsed, invoiceResult) =>
  serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES
    ? {
        subTotal: pipe(path(["priceInfo", "subTotal"], invoiceResult.data.getInvoice), currency),
        partsTotal: pipe(path(["priceInfo", "partsTotal"], invoiceResult.data.getInvoice), currency),
        laborTotal: pipe(path(["priceInfo", "laborTotal"], invoiceResult.data.getInvoice), currency),
        feesTotal: pipe(path(["priceInfo", "feesTotal"], invoiceResult.data.getInvoice), currency),
        discountTotal: pipe(path(["priceInfo", "combinedDiscountTotal"], invoiceResult.data.getInvoice), currency),
        finalPartsTotal: pipe(path(["priceInfo", "finalPartsTotal"], invoiceResult.data.getInvoice), currency),
        finalLaborTotal: pipe(path(["priceInfo", "finalLaborTotal"], invoiceResult.data.getInvoice), currency),
        finalFeesTotal: pipe(path(["priceInfo", "finalFeesTotal"], invoiceResult.data.getInvoice), currency),
        finalSubTotal: pipe(path(["priceInfo", "finalSubTotal"], invoiceResult.data.getInvoice), currency),
        partsTax: pipe(path(["priceInfo", "partsTax"], invoiceResult.data.getInvoice), currency),
        laborTax: pipe(path(["priceInfo", "laborTax"], invoiceResult.data.getInvoice), currency),
        totalTax: pipe(path(["priceInfo", "totalTax"], invoiceResult.data.getInvoice), currency),
        laborCost: pipe(path(["priceInfo", "finalLaborTotal"], invoiceResult.data.getInvoice), currency),
        partsCost: pipe(path(["priceInfo", "finalPartsTotal"], invoiceResult.data.getInvoice), currency),
        amount: pipe(path(["priceInfo", "amountDue"], invoiceResult.data.getInvoice), currency),
      }
    : {
        laborCost: pipe(prop("laborCost", invoiceResult.data.getInvoice), currency),
        partsCost: pipe(prop("partsCost", invoiceResult.data.getInvoice), currency),
        partsTax: pipe(prop("partsTax", invoiceResult.data.getInvoice), currency),
        laborTax: pipe(prop("laborTax", invoiceResult.data.getInvoice), currency),
        subTotal: pipe(prop("subTotal", invoiceResult.data.getInvoice), currency),
        totalTax: pipe(prop("totalTax", invoiceResult.data.getInvoice), currency),
        partsTotal: pipe(prop("partsCost", invoiceResult.data.getInvoice), currency),
        laborTotal: pipe(prop("laborCost", invoiceResult.data.getInvoice), currency),
        feesTotal: currency(0),
        discountTotal: currency(0),
        finalPartsTotal: pipe(prop("partsCost", invoiceResult.data.getInvoice), currency),
        finalLaborTotal: pipe(prop("laborCost", invoiceResult.data.getInvoice), currency),
        finalFeesTotal: currency(0),
        finalSubTotal: pipe(prop("subTotal", invoiceResult.data.getInvoice), currency),
        amount: pipe(prop("balanceDue", invoiceResult.data.getInvoice), currency),
      };

export const InvoicesPayments: React.FC<{ invoiceId: string }> = ({ invoiceId }) => {
  const [addPayment, setAddPayment] = useState(false);
  const togglePayment = () => {
    setAddPayment(!addPayment);
  };
  const invoiceResult = useQuery<Query, QueryGetInvoiceArgs>(GET_INVOICE, {
    variables: {
      id: invoiceId,
    },
  });
  const { data, fetchMore } = useQuery<Query, QueryGetPaymentsPaginatedArgs>(GET_PAYMENTS_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          invoice: invoiceId,
        },
        limit: ROW_LIMIT,
        skip: 0,
      },
    },
  });

  if (!data || !invoiceResult.data) return null;
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          filter: {
            invoice: invoiceId,
          },
          limit: ROW_LIMIT,
          skip: data.getPaymentsPaginated.pageInfo.offset + ROW_LIMIT,
        },
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getPaymentsPaginated: {
            edges: [...prev.getPaymentsPaginated.edges, ...fetchMoreResult.getPaymentsPaginated.edges],
            pageInfo: mergeDeepRight(prev.getPaymentsPaginated.pageInfo, fetchMoreResult.getPaymentsPaginated.pageInfo),
            __typename: prev.getPaymentsPaginated.__typename,
          },
        };
      },
    });
  };
  const payments = data.getPaymentsPaginated;
  const numRecords = path(["pageInfo", "numRecords"], payments);
  const amountPaid = prop("amountPaid", invoiceResult.data.getInvoice);
  const firstName = path(["contact", "firstName"], invoiceResult.data.getInvoice);
  const contactId: string = path(["contact", "id"], invoiceResult.data.getInvoice);
  const lastName = path(["contact", "lastName"], invoiceResult.data.getInvoice);
  const payer = `${firstName} ${lastName}`;
  const serviceCatalogueUsed = propOr(
    DEFAULT_SERVICE_CATALOGUE_USED,
    "serviceCatalogueUsed",
    invoiceResult.data.getInvoice
  );
  const addPaymentInput = getPaymentInput(serviceCatalogueUsed, invoiceResult);
  return (
    <Container>
      <div className="grid gap-4">
        <div className="flex flex-row justify-between">
          <h3>Amount Paid: ${amountPaid}</h3>
          <h3>Balance Due ${addPaymentInput.amount.toString()}</h3>
        </div>
        {addPaymentInput.amount.value > 0 && (
          <div>
            <Button onClick={togglePayment}>Receive Payment</Button>
          </div>
        )}
        {addPayment && (
          <AddPaymentForm
            laborCost={addPaymentInput.laborCost.toString()}
            partsCost={addPaymentInput.partsCost.toString()}
            partsTax={addPaymentInput.partsTax.toString()}
            laborTax={addPaymentInput.laborTax.toString()}
            subTotal={addPaymentInput.subTotal.toString()}
            totalTax={addPaymentInput.totalTax.toString()}
            partsTotal={addPaymentInput.partsTotal.toString()}
            laborTotal={addPaymentInput.laborTotal.toString()}
            feesTotal={addPaymentInput.feesTotal.toString()}
            discountTotal={addPaymentInput.discountTotal.toString()}
            finalPartsTotal={addPaymentInput.finalPartsTotal.toString()}
            finalLaborTotal={addPaymentInput.finalLaborTotal.toString()}
            finalFeesTotal={addPaymentInput.finalFeesTotal.toString()}
            finalSubTotal={addPaymentInput.finalSubTotal.toString()}
            amount={addPaymentInput.amount.toString()}
            invoiceId={invoiceId}
            contactId={contactId}
            defaultPayer={payer}
          />
        )}
        {numRecords > 0 && !addPayment && (
          <SelectablePageableEntityTable
            title={"Payments"}
            queryResult={data}
            columns={PaymentColumns}
            queryKey={"getPaymentsPaginated"}
            numRecords={data.getPaymentsPaginated.pageInfo.numRecords}
            loadMore={loadMore}
            limit={ROW_LIMIT}
            spec={paymentsToPaymentColumns}
          />
        )}
      </div>
    </Container>
  );
};
