import React, { useEffect, useState } from "react";
import { Technician } from "../../generated/nest-graphql";
import { always, equals, includes, length } from "ramda";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6) + 1,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    toolbar: theme.mixins.toolbar,
    checkbox: { paddingTop: 2, paddingBottom: 2 },
  })
);

export type TechniciansFilterSectionFormValues = {
  technicians: string[];
};
export const AppointmentFilterSectionInternals: React.FC<{
  includeIsActive: boolean | null;
  setIncludeIsActive: (val: boolean) => void;
  setTechniciansToInclude: any;
  technicians: Technician[];
  allTechnicianIds: string[];
  techniciansToInclude: string[];
}> = ({
  technicians,
  allTechnicianIds: allTechnicians,
  setTechniciansToInclude,
  includeIsActive,
  setIncludeIsActive,
  techniciansToInclude,
}) => {
  const [allFilterChecked, setAllFilterChecked] = useState(length(techniciansToInclude) === length(allTechnicians));

  useEffect(() => {
    const allSelected = () => length(techniciansToInclude) === length(allTechnicians);
    if (allSelected()) {
      setAllFilterChecked(true);
    } else {
      setAllFilterChecked(false);
    }
  }, [techniciansToInclude, allTechnicians, setAllFilterChecked]);

  const technicianPostOnClick = (technician: string) => {
    if (techniciansToInclude.includes(technician)) {
      const newIncluded = techniciansToInclude.filter((t) => !equals(t, technician));
      setTechniciansToInclude(newIncluded);
    } else {
      const newIncluded = [...techniciansToInclude, technician];
      setTechniciansToInclude(newIncluded);
    }
  };

  const toggleAllFilters = (_: object) => {
    !allFilterChecked ? setTechniciansToInclude(allTechnicians) : setTechniciansToInclude([]);
  };

  const classes = useStyles();
  const [open, , toggleDrawer] = useToggle(true);
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        anchor={"right"}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} />
        <div>
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="end">
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        {open && (
          <Box paddingX={1}>
            <Box marginTop={2}>
              <FormControlLabel
                control={<Checkbox checked={allFilterChecked} onChange={toggleAllFilters} />}
                label={<Typography variant="body2">Select All</Typography>}
              />
            </Box>
            <Divider />
            <Box marginTop={1} marginBottom={1}>
              <Typography variant="body1">Select Technicians To Display</Typography>
              <Box marginTop={1} display="flex" flexDirection="column">
                {technicians.map((technician, index) => {
                  return (
                    <TechnicianRow
                      technician={technician}
                      key={index}
                      postOnClick={technicianPostOnClick}
                      opts={techniciansToInclude}
                    />
                  );
                })}
              </Box>
            </Box>
            <Divider />
            <Box marginTop={1} marginBottom={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeIsActive}
                    indeterminate={includeIsActive === null}
                    onChange={() => setIncludeIsActive(!includeIsActive)}
                  />
                }
                label={<Typography variant="body2">Include Active Technicians</Typography>}
              />
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};
const TechnicianRow: React.FC<{
  technician: Technician;
  postOnClick?: (id: string) => void;
  opts?: string[];
}> = ({ technician: { id, firstName, lastName }, postOnClick, opts }) => {
  const classes = useStyles();
  const inValues = includes(id, opts);
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          className={classes.checkbox}
          style={{ paddingTop: 2, paddingBottom: 2 }}
          checked={inValues as any}
          size="small"
          onClick={() => {
            postOnClick && postOnClick(id);
          }}
          onChange={always("")}
        />
      }
      label={<Typography variant="body2">{`${firstName} ${lastName}`}</Typography>}
    />
  );
};
