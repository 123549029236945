import { useEffect, useState } from "react";

export const useStateWithSessionStorage = (localStorageKey: string, initialVal: any) => {
  const [value, setValue] = useState<any>(JSON.parse(sessionStorage.getItem(localStorageKey)) || initialVal);

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};
