import React, { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import { default as CoreTextField } from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { debounce } from "lodash";

// default text field to debounced for updating state... Crazy right?
export const DebouncedTextField: React.FC<{
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string;
}> = ({ name, label, required, multiline, rows, helperText }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const [textVal, setTextVal] = useState(value);
  useEffect(() => {
    setTextVal(value === null ? "" : value);
  }, [value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(helpers.setValue, 500), []);
  return (
    <CoreTextField
      fullWidth
      error={!!meta.error}
      variant={"outlined"}
      multiline={multiline}
      rows={rows}
      label={label}
      value={textVal || ""}
      required={required}
      onChange={(e) => {
        setTextVal(e.target.value);
        handler(e.target.value);
      }}
      helperText={
        (!!meta.error || !!helperText) &&
       ( <>
          {helperText}
          <ErrorTextField fieldName={field.name} displayError={!!meta.error} />
        </>)
      }
    />
  );
};
