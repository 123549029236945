import React from "react";
import { useField } from "formik";
import { default as CoreTextField, TextFieldProps } from "@material-ui/core/TextField";
import { OutlinedInputProps } from "@material-ui/core";
import { ErrorTextField } from "./ErrorTextField";

export const TextField: React.FC<{
  name: string;
  label?: string;
  type?: TextFieldProps["type"];
  placeholder?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  helperText?: string | JSX.Element;
  style?: React.CSSProperties;
  inputProps?: Partial<OutlinedInputProps>;
  onChangeText?: (text: string) => void;
  disabled?: boolean;
}> = ({ name, label, type, required, multiline, rows, helperText, style, inputProps, onChangeText, disabled }) => {
  const [field, meta] = useField(name);
  const { value, onChange, ...fieldRest } = field;
  const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeText) {
      onChangeText(e?.target?.value || "");
    }
    return onChange(e);
  };
  return (
    <CoreTextField
      fullWidth
      type={type}
      error={!!meta.error}
      variant={"outlined"}
      multiline={multiline}
      rows={rows}
      label={label}
      onChange={onChangeField}
      required={required}
      value={value ? value : ""}
      style={style}
      InputProps={inputProps}
      disabled={disabled}
      helperText={
        <>
          {helperText}
          <br />
          <ErrorTextField fieldName={field.name} displayError={!!meta.error} />
        </>
      }
      {...fieldRest}
    />
  );
};
