import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetContactArgs, QueryGetInvoicesPaginatedArgs } from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { isEmpty, mergeDeepRight, path } from "ramda";
import { Button } from "../Buttons/Button";
import { AddInvoice } from "../Invoices/AddInvoice";
import { InvoiceColumns, invoicesToInvoiceColumns } from "../Invoices/InvoicesTable";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { DEFAULT_SERVICE_CATALOGUE_USED, ROW_LIMIT } from "../../lib/constants";
import { getSymptoms, vehicleSymptomToVehicleSymptomFormValue } from "./contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";

export const ContactsInvoices: React.FC<{ contactId: string }> = ({ contactId }) => {
  const [enableAddInvoice, setEnableAddInvoice] = useState(false);
  const { data, fetchMore } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          contact: contactId,
        },
        limit: ROW_LIMIT,
        skip: 0,
      },
    },
  });

  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  if (!data || !path(["data", "getContact"], contactResult)) return null;
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          filter: {
            contact: contactId,
          },
          limit: ROW_LIMIT,
          skip: data.getInvoicesPaginated.pageInfo.offset + ROW_LIMIT,
        },
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getInvoicesPaginated: {
            edges: [...prev.getInvoicesPaginated.edges, ...fetchMoreResult.getInvoicesPaginated.edges],
            pageInfo: mergeDeepRight(prev.getInvoicesPaginated.pageInfo, fetchMoreResult.getInvoicesPaginated.pageInfo),
            __typename: prev.getInvoicesPaginated.__typename,
          },
        };
      },
    });
  };
  const contactData = contactResult.data.getContact;
  const vehicleInfo = path(["lead", "vehicleInfo"], contactData);
  const serviceLocation = path(["lead", "address"], contactData);
  const market = path(["lead", "market"], contactData);
  return (
    <div className={"px-4"}>
      {enableAddInvoice ? (
        <AddInvoice
          initialValues={{
            status: "Draft",
            taxable: !exists(contactData?.taxExemptEin) ?? true,
            contact: contactData,
            serviceLocation,
            market,
            items: [],
            serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
            discounts: [],
            issuedDate: new Date().toISOString(),
            dueDate: new Date().toISOString(),
            vehicleSymptoms: contactData?.lead?.vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
            requestedServices: contactData?.lead?.requestedServices ?? null,
            // @ts-ignore
            ...vehicleInfo,
            symptoms: isEmpty(getSymptoms(contactData)) ? null : getSymptoms(contactData),
          }}
        />
      ) : (
        <>
          <div className="flex flex-row justify-end">
            <div className="my-4 mr-0">
              <Button onClick={() => setEnableAddInvoice(true)}>+ Add Invoice</Button>
            </div>
          </div>
          <SelectablePageableEntityTable
            title={"Invoices"}
            queryResult={data}
            columns={InvoiceColumns}
            queryKey={"getInvoicesPaginated"}
            loadMore={loadMore}
            numRecords={data.getInvoicesPaginated.pageInfo.numRecords}
            limit={ROW_LIMIT}
            spec={invoicesToInvoiceColumns}
          />
        </>
      )}
    </div>
  );
};
