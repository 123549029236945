import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "../FormFields/TextField";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { useField, useFormikContext } from "formik";
import { Button } from "../Buttons/Button";
import { CircularProgress, IconButton, InputAdornment, TextField as MUITextField, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import { NuModal } from "../NuModal";
import { useVinDecoder } from "../../hooks/useVinDecoder";
import { useMutation, useQuery } from "@apollo/client";
import { GET_VEHICLE_SERVICE_EXCEPTION } from "../../graphql/queries/getVehicleServiceException";
import { Mutation, MutationGetVinFromLicensePlateArgs, MutationUpsertVehicleArgs, Query, QueryGetVehicleArgs } from "../../generated/nest-graphql";
import { Alert } from "../Alert";
import { VehicleDetailsFormValues } from "../Forms/VehicleDetailsForm";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { SelectField } from "../FormFields/SelectField";
import { US_STATES } from "../../lib/constants";
import { GET_VIN_FROM_LICENSE_PLATE } from "../../graphql/mutations/getVinFromLicensePlate";

export type VehicleInfoValues = {
  make?: string;
  model?: string;
  year?: string;
  vin?: string;
};

interface VehicleData {
  make: string;
  model: string;
  year: string;
}

export const VehicleInfoBasicFormSection: React.FC<{
  defaultExpanded?: boolean;
  sectionTitle?: string;
  className?: string;
}> = ({ defaultExpanded = true, sectionTitle = "Vehicle Info", className }) => {
  const { setFieldValue: setFieldValue, values: jobValues } = useFormikContext<VehicleDetailsFormValues>();

  const vehicleId = jobValues["vehicleId"];

  const { data, loading } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
  });

  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);

  const values = !loading && data?.getVehicle ? data?.getVehicle : {};

  const [vin, setVin] = useState<string>(values.vin || '');
  const [field, meta, helper] = useField("vin");

  const saveVinInVehicle = async () => {
    await upsertVehicle({
      variables: {
        vehicleInputData: {
          id: values.id,
          contactId: values.contactId,
          make: values.make,
          model: values.model,
          year: values.year,
          vin: vin,
          ...(jobValues["licensePlate"] && jobValues["licensePlateState"] ? {
            licensePlate: jobValues["licensePlate"],
            licensePlateState: jobValues["licensePlateState"]
          } : {}),
        }
      }
    })
  }

  const [isVinWarningOpen, setIsVinWarningOpen] = useState(false);

  const {
    vinInfo,
    loading: loadingVinInfo,
    errorMessage: errorMessageVin,
    setErrorMessage: setErrorVin,
    fetchVinInfo,
    setVinInfo,
  } = useVinDecoder();

  const hasVehicleData = !!values.year && !!values.make && !!values.model;
  const { data: vehicleExceptionData } = useQuery<Query>(GET_VEHICLE_SERVICE_EXCEPTION, {
    variables: {
      getVehicleServiceExceptionInput: {
        year: Number(values.year),
        make: values.make,
        model: values.model,
      },
    },
    skip: !hasVehicleData,
  });
  const isExceptionVehicle =
    hasVehicleData && !!vehicleExceptionData?.getVehicleServiceException?.id;

  const onChangeVin = (vin: string) => {
    if (vin.length === 17) {
      fetchVinInfo({ vin });
      return;
    }
    setVinInfo(null);
    setErrorVin(null);
  };
  const onChangeVinDebounced = debounce(onChangeVin, 500);

  const doesVinInfoMatchesVehicleData = (vinInfo: VehicleData, dataToCompare: VehicleData) => {
    return (
      vinInfo.make.toLowerCase() === dataToCompare.make.toLowerCase() &&
      vinInfo.model.toLowerCase() === dataToCompare.model.toLowerCase() &&
      vinInfo.year === dataToCompare.year
    );
  };

  useEffect(() => {
    if (field.value !== vin && !!vin) {
      helper.setValue(vin);
    }

  }, [vin, field, helper]);

  useEffect(() => {
    if (!vinInfo || !values.make || !values.model || !values.year) return;
    if (
      !doesVinInfoMatchesVehicleData(vinInfo, {
        make: values.make || "",
        model: values.model || "",
        year: values.year || "",
      })
    ) {
      setIsVinWarningOpen(true);
    }
  }, [vinInfo]);

  const parentName = 'vehicle';
  const parent = parentName ? `${parentName}.` : '';
  const [getVinFromLicensePlate] = useMutation<Mutation, MutationGetVinFromLicensePlateArgs>(GET_VIN_FROM_LICENSE_PLATE);

  const getQuickVinData = async (licensePlate: string, stateCode: string) => {
    const { data } = await getVinFromLicensePlate({
      variables: {
        licensePlate,
        stateCode
      },
    });
    return data?.getVinFromLicensePlate;
  }

  return (
    <DetailViewContainer title={sectionTitle} defaultExpanded={defaultExpanded} className={className}>
      {isExceptionVehicle && (
        <Alert className="mb-6" severity="warning">
          This is an exception vehicle.
        </Alert>
      )}
      <div className="grid grid-cols-3 gap-4">
        <MUITextField
          disabled={true}
          value={values.year}
          label={"year"}
          variant="outlined"
        />
        <MUITextField
          disabled={true}
          value={values.make}
          label={"make"}
          variant="outlined"
        />
        <MUITextField
          disabled={true}
          value={values.model}
          label={"model"}
          variant="outlined"
        />
        <div className="col-span-3 grid grid-cols-3 gap-4">
          <TextField
            name={"vin"}
            disabled={loadingVinInfo}
            label={"VIN"}
            onChangeText={(e) => {
              onChangeVinDebounced(String(e));
              setVin(e);
            }}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Search for VIN">
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={() => {
                        fetchVinInfo({ vin: values.vin });
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {!vin && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-1">
                  <TextField
                    name={`licensePlate`}
                    label={"License Plate"}
                    onChangeText={(e) => {
                      setFieldValue('licensePlate',String(e));
                    }}
                  />
                </div>
                <div className="col-span-1">
                  <SelectField
                    name={`licensePlateState`}
                    options={US_STATES}
                    label={"State"} 
                    postOnChange={(e) => {
                      setFieldValue('licensePlateState',String(e));
                    }}
                    />
                </div>
              </div>
              <div className="grid gap-4">
                <div className="col-span-1">
                  <Button
                    className="w-full"
                    type='button'
                    onClick={async () => {
                      const stateCode = US_STATES.find(s => s.value === jobValues?.licensePlateState)?.label;
                      const vin = await getQuickVinData(jobValues?.licensePlate, stateCode);
                      if (vin) {
                        setVin(vin);
                        fetchVinInfo({ vin });
                        //setFieldValue(`vin`, vin);
                        //onChangeVinDebounced(vin);
                        //fetchVinInfo({ vin });
                      }
                    }}>
                    Find VIN
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className="col-span-2 flex flex-row pb-6 items-center">
            {loadingVinInfo && <CircularProgress size={20} />}
            {errorMessageVin && !loadingVinInfo && <div className="text-red-500">{errorMessageVin}</div>}
            {vinInfo && !loadingVinInfo && !errorMessageVin && (
              <Tooltip title="Vehicle data from NHTSA public VIN decoder">
                <div className="flex flex-row items-center">
                  <strong>Year:&nbsp;</strong>
                  {vinInfo.year}&nbsp;&nbsp;
                  <strong>Make:&nbsp;</strong>
                  {vinInfo.make}&nbsp;&nbsp;
                  <strong>Model:&nbsp;</strong>
                  {vinInfo.model}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <NuModal isOpen={isVinWarningOpen} maxWidth="md" title="VIN does not match Vehicle">
        <p>The VIN you entered does not seem to match the vehicle selected. Please verify the VIN and vehicle.</p>
        <br />
        <p>Vehicle:</p>
        <p>
          <strong>Year:&nbsp;</strong>
          {values?.year}&nbsp;&nbsp;
          <strong>Make:&nbsp;</strong>
          {values?.make}&nbsp;&nbsp;
          <strong>Model:&nbsp;</strong>
          {values?.model}
        </p>
        <br />
        <p>VIN corresponds to:</p>
        <p>
          <strong>Year:&nbsp;</strong>
          {vinInfo?.year}&nbsp;&nbsp;
          <strong>Make:&nbsp;</strong>
          {vinInfo?.make}&nbsp;&nbsp;
          <strong>Model:&nbsp;</strong>
          {vinInfo?.model}
        </p>
        <div className="flex flex-row mt-8 justify-between">
          <Button
            onClick={() => {
              saveVinInVehicle()
              setIsVinWarningOpen(false);
            }}
          >
            OK
          </Button>
        </div>
      </NuModal>
    </DetailViewContainer>
  );
};
