import React, { createContext, useContext, useState } from "react";

export type JobsFilterState = {
  startRange: Date;
  endRange: Date;
  type: string;
  status: string;
  technician: string;
  market: string;
  partsOrdered: boolean | null;
  jobNumber: string;
  firstName: string;
  lastName: string;
};
const initialState: JobsFilterState = {
  type: null,
  status: null,
  technician: null,
  market: null,
  startRange: null,
  endRange: null,
  partsOrdered: null,
  jobNumber: null,
  firstName: null,
  lastName: null,
};

const JobsFilterStateContext = createContext<{
  state: JobsFilterState;
  updateJobsFilter: (jobsFilterValues: JobsFilterState) => void;
}>({
  state: initialState,
  updateJobsFilter: (jobsFilterValues) => {},
});

export const JobsFilterProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const updateJobsFilter = (jobsFilterValues) => {
    setState({
      ...jobsFilterValues,
    });
  };
  return (
    <JobsFilterStateContext.Provider value={{ state, updateJobsFilter }}>{children}</JobsFilterStateContext.Provider>
  );
};

export const useJobsFilter = () => useContext(JobsFilterStateContext);
