import gql from "graphql-tag";

export const OPS_ADMIN_CREATE_INQUIRY_REQUEST = gql`
  mutation opsAdminCreateInquiryRequest($createInquiryRequestInput: CreateInquiryRequestInput!) {
    opsAdminCreateInquiryRequest(createInquiryRequestInput: $createInquiryRequestInput) {
      inquiryRequest {
        id
      }
      contact {
        id
      }
      serviceRequest {
        id
      }
    }
  }
`;
