import gql from "graphql-tag";
import { itemsFields } from "./getEstimate";
import { partsStoreDetailsFields } from "../queries/getPartsStore";
import { ejiPriceInfoFields, partsCalcsFields } from "./calculatePossibleEJIPriceInfo";
import { partDetailFields } from "./getPartCatalogue";
import { vehicleSymptomFields } from "./getContactDetails";
import { viocStoreFields } from "./getAllViocStores";

export const activityFeedFields = `activityFeed {
            actor
            id
            objectId
            verb
            createdAt
        }`;

export const preJobCheckListItemField = `{
    partCondition
    additionalInfo
} `;

export const brakePadsListItemField = `{
    padThickness {
        thickness
        unavailable
    }
    condition
}`;

export const oilLifeListItemField = `{
    nextOilChangeMileage {
        mileage
        unavailable
    }
    nextOilChangeDate {
        date
        unavailable
    }
    lastOilChangeDate {
        date
        unavailable
    }
}`;

export const tireTreadListItemField = `{
    frontRight {
        depth
        unavailable
    }
    frontLeft {
        depth
        unavailable
    }
    rearRight {
        depth
        unavailable
    }
    rearLeft {
        depth
        unavailable
    }
}`;

export const checkListV2BrakeFluidField = `{
  ppm
  level
}`;

export const checkListV2PadsField = `{
  innerPadThickness
  outerPadThickness
  padsThickness
  condition
}`;

export const checkListV2RotorsField = `{
  thicknessInches
  condition
}`;

export const checkListV2ShoesField = `{
  colorGrade
  condition
}`;

export const checkListV2DrumsField = `{
  colorGrade
  condition
}`;

export const checkListV2InspectedServiceItemField = `{
  included
  colorGrade
  notes
}`;

export const checkListV2InspectedServicesField = `{
  frontLeftCaliper ${checkListV2InspectedServiceItemField}
  frontRightCaliper ${checkListV2InspectedServiceItemField}
  rearLeftCaliper ${checkListV2InspectedServiceItemField}
  rearRightCaliper ${checkListV2InspectedServiceItemField}
  frontLeftHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  frontRightHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  rearLeftHubAssemblyBearing ${checkListV2InspectedServiceItemField}
  rearRightHubAssemblyBearing ${checkListV2InspectedServiceItemField}
}`

export const preJobCheckListV2Field = `
 preJobCheckListV2 {
  odometer
  brakeFluid ${checkListV2BrakeFluidField}
  frontPads ${checkListV2PadsField}
  frontRotors ${checkListV2RotorsField}
  rearPads ${checkListV2PadsField}
  rearRotors ${checkListV2RotorsField}
  usesRearShoesAndDrums
  rearShoes ${checkListV2ShoesField}
  rearDrums ${checkListV2DrumsField}
  privateNotes
  inspectedServices ${checkListV2InspectedServicesField}
}`;

export const postJobChecklistField = `
 postJobChecklist {
    additionalDetails
    dashboardLights
    estimatedTimeline
    finalRecomendation
    lugnuts
    needOutstandingRepair
    notes
    otherConcerns
    recommendedServices
    testDriveExpirience
    wheelStuds
}`



export const servicesField = `
services {
    id
    service
    name
    inEstimate
    customerPrice
    technicianPay
    partsCalcs {
        ${partsCalcsFields}
    }
    items {
        id
        serviceProductId
        productSelection {
            name
            part {
                ${partDetailFields}
            }
        }
        type
        units
        overriddenCustomerPrice
        customerPrice
        vendorUnitCost
        behavior
        category
        notes
        orderItem {
            partsStore {
                ${partsStoreDetailsFields}
            }
            isOrdered
            partNumber
        }
        marketVendorUnitCostMultiplierUsed
        marketLaborRateUsed
    }
    updatedAt
    createdAt
}`

export const preJobChecklistField = `
preJobCheckList {
    frontPads ${preJobCheckListItemField}
    frontPadsV2 ${brakePadsListItemField}
    frontRotors ${preJobCheckListItemField}
    rearPads ${preJobCheckListItemField}
    rearPadsV2 ${brakePadsListItemField}
    rearRotors ${preJobCheckListItemField}
    brakeFluid ${preJobCheckListItemField}
    brakeShoes ${preJobCheckListItemField}
    brakeDrums ${preJobCheckListItemField}
    calipers ${preJobCheckListItemField}
    shocksStruts ${preJobCheckListItemField}
    controlArms ${preJobCheckListItemField}
    wheelHubs ${preJobCheckListItemField}
    studs ${preJobCheckListItemField}
    lugnuts ${preJobCheckListItemField}    
    sparkPlugs ${preJobCheckListItemField}
    carBattery ${preJobCheckListItemField}
    alternator ${preJobCheckListItemField}
    engineAirFilter ${preJobCheckListItemField}
    cabinAirFilter ${preJobCheckListItemField}    
    otherBrakeParts ${preJobCheckListItemField}
    dashboardLights ${preJobCheckListItemField}
    odometerReading ${preJobCheckListItemField}
    oilLife ${oilLifeListItemField}
    tireTread ${tireTreadListItemField}
}`

export const jobDetailsFields = `
        ${activityFeedFields}
        selfBookingIdentifier
        earlyNotice
        locationType
        vehicleInfo {
            frontPadLife
            licensePlate
            licensePlateState
            make
            model
            odometer
            frontRotorsDiscardThicknessInches
            rearRotorsDiscardThicknessInches
            rearPadLife
            vin
            year
            symptoms
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            extraInfo
            wheelTorque
        }
        vehicleId
        zendeskTags
        vehicleSymptoms {
            ${vehicleSymptomFields}
        }
        requestedServices
        invoice {
            id
            balanceDue
        }
        appointment {
            id
            isFixed
            allDay
            endDate
            startDate
            timeZone
            timeWindow {
                startTimeWindow
                endTimeWindow
            }
            subject
            technician {
                id
                firstName
                lastName
                market
                is1099
                homePartsStore {
                    id
                    storeNumber
                    name
                    vendor
                }
            }
            ${activityFeedFields}
        }
        diagnosticsQuestionsAnswers {
          question
          answer
          relatedSymptoms
        }
        contact {
            id
            firstName
            lastName
            taxExemptEin
            fleet {
                name
            }
            lead {
                id
                address
                hearAboutUs
                market
                source
                vehicleInfo {
                  frontPadLife
                    licensePlate
                    licensePlateState
                    make
                    model
                    odometer
                    frontRotorsDiscardThicknessInches
                    rearRotorsDiscardThicknessInches
                    rearPadLife
                    vin
                    year
                    extraInfo
                    symptoms
                    customerExpectation
                    additionalNotes
                    frontBrakeSymptoms
                    rearBrakeSymptoms
                }
                vehicleSymptoms {
                    ${vehicleSymptomFields}
                }
                requestedServices
                zip
            }
            email
            timeZone
            stripeCustomer
            stripePaymentMethods
        }
        feedback {
            rating
            doneBetter
            additionalComments
        }
        description
        id
        jobNumber
        ${itemsFields}
        jobName
        type
        contact {
            firstName
            lastName
        }
        serviceLocation
        serviceLocationGeocode
        partsInfo {
            partsLocation
            partsOrderNumber
            partsOrdered
            partsOrderedTimestamp
            cancellationInitiated
            cancellationTimestamp
            partsNotes
            partsOrderingIssue
            callForPartsTicketNumber
        }
        estimate {
            id
        }
        partsStore {
            id
            name
            storeNumber
        }
        withdrawReason 
        withdrawExtraInfo
        withdrawnAt
        mHelpId
        jobCausedWarrantyCheck {
            id
        }
        jobCausedDiagnostic {
          id
        }
        jobViocBrakeRepair {
          id
        }
        jobViocInspection {
          id
        }
        warrantyCheckReason
        warrantyCheckReasonExtraInfo
        warrantyCheckTechReason
        warrantyCheckTechReasonExtraInfo
        serviceCallReason
        serviceCallReasonExtraInfo
        market
        taxable
        privateNotes
        serviceLocationNotes
        ${preJobChecklistField}
        ${preJobCheckListV2Field}
        createdAt
        updatedAt
        status
        serviceCatalogueUsed
        ${servicesField}
        partsLeadTimeInDays
        pipedriveDealId
        priceInfo {
            ${ejiPriceInfoFields}
        }
        estimateSentAt
        symptomDiagnosisCategory
        serviceLocationZip
        viocStore {
          ${viocStoreFields}
        }
        ${postJobChecklistField}
        `;

export const GET_JOB = gql`query getJob($id: ID!) {
    getJob(id: $id) {
        ${jobDetailsFields}
    }
}`;
