import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetInvoicesPaginatedArgs, QueryGetJobArgs, Job } from "../../generated/nest-graphql";
import { GET_JOB } from "../../graphql/queries/getJob";
import { cond, head, path, pathOr, T } from "ramda";
import { AddInvoice } from "../Invoices/AddInvoice";
import { Button } from "../Buttons/Button";
import { InvoiceDetails } from "../Invoices/InvoiceDetails";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { DEFAULT_SERVICE_CATALOGUE_USED, ROW_LIMIT, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { vehicleSymptomToVehicleSymptomFormValue } from "../Contacts/contactDetailsFormHelpers";
import { getSymptoms } from "../specs/jobDetailsSpec";
import { exists } from "../../commonFunctions";

export const JobInvoices: React.FC<{ jobId: string }> = ({ jobId }) => {
  const [enableAddInvoice, setEnableAddInvoice] = useState(false);
  const { data, loading } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          job: jobId,
        },
        limit: ROW_LIMIT,
        skip: 0,
      },
    },
  });
  const jobResults = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
    fetchPolicy: "cache-first",
  });
  if (loading || !data || !jobResults?.data?.getJob) return null;
  if (!path(["getInvoicesPaginated", "edges", "length"], data)) {
    const results: Job = path(["data", "getJob"], jobResults);
    const estimate: string = path(["estimate", "id"], results);
    const {
      taxable,
      contact,
      market,
      items,
      serviceLocation,
      vehicleInfo,
      privateNotes,
      services,
      serviceCatalogueUsed = DEFAULT_SERVICE_CATALOGUE_USED,
      priceInfo,
      vehicleSymptoms,
      requestedServices,
    } = results;
    return (
      <div>
        {enableAddInvoice ? (
          <AddInvoice
            initialValues={{
              status: "Draft",
              taxable: !exists(results?.contact?.taxExemptEin) ?? taxable ?? true,
              estimate,
              contact,
              serviceLocation,
              technician: pathOr(null, ["data", "getJob", "appointment", "technician"], jobResults),
              items,
              market,
              issuedDate: new Date(),
              dueDate: new Date(),
              ...vehicleInfo,
              symptoms: getSymptoms(results) ?? "",
              privateNotes,
              job: jobId,
              serviceCatalogueUsed,
              services: cond([
                [
                  (_val) => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && services?.length > 0,
                  () => services,
                ],
                [T, () => []],
              ])(null),
              discounts: priceInfo?.discounts ?? [],
              promoCodes: priceInfo?.promoCodes ?? [],
              priceInfo,
              vehicleSymptoms: vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
              requestedServices: requestedServices ?? null,
            }}
          />
        ) : (
          <Button onClick={() => setEnableAddInvoice(true)}>Add Invoice</Button>
        )}
      </div>
    );
  }
  const invoice = head(data.getInvoicesPaginated.edges);
  return <InvoiceDetails invoiceId={invoice.id} />;
};
