import gql from "graphql-tag";

export const CREATE_AVAILABILITY_SNAPSHOT = gql`
  mutation createAvailabilitySnapshot($createAvailabilitySnapshotInput: CreateAvailabilitySnapshotInput!) {
    createAvailabilitySnapshot(createAvailabilitySnapshotInput: $createAvailabilitySnapshotInput) {
      availabilitySnapshotId
      error
    }
  }
`;
