import React, { useState } from "react";
import { TextField } from "../FormFields/TextField";
import { TechnicianSelect } from "../FormFields/TechnicianSelect";
import { AutoCompleteSelectField } from "../FormFields/AutoCompleteSelectField";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { DateTimeInput } from "../FormFields/DateTimeInput";
import { useFormikContext } from "formik";
import { add, isAfter, isBefore, sub } from "date-fns/fp";
import { AppointmentFormValues } from "../Forms/AppointmentForm";
import { GET_APPOINTMENTS_CONFLICTS } from "../../graphql/queries/getAppointmentConflicts";
import { defaultTo, isNil, pathOr, propOr, propEq, reject, any, prop } from "ramda";
import { Query, QueryGetAppointmentsArgs } from "../../generated/nest-graphql";
import { isEqual } from "date-fns";
import { useQuery } from "@apollo/client";
import Typography from "@material-ui/core/Typography";
import {
  formatDateTime,
  getEndTimeWindowOffsetForMarket,
  getStartTimeWindowOffsetForMarket,
  isValidDate,
} from "../../lib/functions";
import { errorLabelStyles } from "../../material-ui-styles";
import { ReadOnlyItem } from "../FormFields/ReadOnlyItem";
import { flow } from "fp-ts/lib/function";
import { RESCHEDULE_REASONS } from "../../lib/constants";

export const AppointmentFormFields: React.FC<{
  market: string | undefined;
  outsideServiceArea: boolean;
}> = ({ outsideServiceArea, market }) => {
  const startDateFieldName = `startDate`;
  const endDateFieldName = `endDate`;
  const startTimeWindowName = "startTimeWindow";
  const endTimeWindowName = "endTimeWindow";
  const { values, setFieldValue } = useFormikContext<AppointmentFormValues>();
  const id = values.id;
  const startDate = prop(startDateFieldName, values);
  const endDate = prop(endDateFieldName, values);
  const [originalStartDate] = useState<Date>(startDate);
  const startTimeWindow = pathOr(null, [startTimeWindowName], values);
  const startDatePostChange = (date: Date) => {
    if (!endDate || isAfter(endDate, date)) {
      setFieldValue(endDateFieldName, add({ hours: 1 }, date));
    }
    setFieldValue("startDateChanged", id && !isEqual(originalStartDate as Date, date));

    setFieldValue(startTimeWindowName, add({ minutes: getStartTimeWindowOffsetForMarket() }, date));
    setFieldValue(endTimeWindowName, add({ minutes: getEndTimeWindowOffsetForMarket() }, date));
  };
  const endDatePostChange = (date: Date) => {
    if (!startDate || isBefore(startDate, date)) {
      setFieldValue(startDateFieldName, sub({ hours: 1 }, date));
    }
  };
  const endTimeWindowPostChange = (date: Date) => {
    if (!startTimeWindow || isBefore(startTimeWindow, date)) {
      setFieldValue(startDateFieldName, sub({ minutes: 1 }, date));
    }
  };

  const technician = defaultTo(null, values.technician);
  const filter = isNil(technician)
    ? {}
    : {
        technician: technician.id,
        "jobCopy.status": { $not: { $regex: "^Withdra" } },
      };

  const data = useQuery<Query, QueryGetAppointmentsArgs>(GET_APPOINTMENTS_CONFLICTS, {
    variables: {
      startRange: add({ minutes: 1 }, startDate),
      endRange: sub({ minutes: 1 }, endDate),
      filter,
    },
    skip: any(isNil, [technician, startDate, endDate]) || !isValidDate(startDate) || !isValidDate(endDate),
    fetchPolicy: "network-only",
  });
  const conflictAppointments = isNil(id)
    ? pathOr([], ["data", "getAppointmentConflicts"], data)
    : flow(pathOr([], ["data", "getAppointmentConflicts"]), reject(propEq("id", id)))(data);

  return (
    <div className="grid grid-cols-3 gap-4">
      <TextField name={`subject`} label={"Subject"} required={true} />
      <DateTimeInput name={startDateFieldName} label={"Start Date"} required={true} postChange={startDatePostChange} />
      <DateTimeInput name={endDateFieldName} label={"End Date"} required={true} postChange={endDatePostChange} />
      <TechnicianSelect name={`technician`} label={"Technician"} required={true} market={market} />
      <ReadOnlyItem label={"Time Zone"} value={propOr("", "timeZone", values)} removeOnValueEmpty />
      <CheckBoxField name={`allDay`} label={"All Day?"} />
      {conflictAppointments.length > 0 && (
        <div className="col-span-3">
          <AppointmentConflictErrorText conflicts={conflictAppointments} />
        </div>
      )}
      <DateTimeInput name={startTimeWindowName} label={"Start Time Window"} readOnly={true} required={true} />
      <DateTimeInput
        name={endTimeWindowName}
        label={"End Time Window"}
        required={true}
        postChange={endTimeWindowPostChange}
      />
      {id && !isEqual(originalStartDate, startDate) && (
        <div className="col-span-3">
          <AutoCompleteSelectField
            name={"rescheduleReason"}
            options={RESCHEDULE_REASONS}
            label={"Reschedule Reason"}
            required={true}
          />
        </div>
      )}
      {outsideServiceArea && (
        <div className="col-span-3">
          <OutsideServiceAreaErrorText market={market} />
        </div>
      )}
    </div>
  );
};

const AppointmentConflictErrorText: React.FC<{
  conflicts: AppointmentFormValues[];
}> = ({ conflicts }) => {
  const classes = errorLabelStyles();
  return (
    <>
      <Typography className={classes.root} variant="caption">
        This appointment conflicts with technician's current appointments:
      </Typography>
      <ul>
        <div className="pl-4">
          {conflicts.map((val: AppointmentFormValues, i) => (
            <li key={i}>
              <Typography className={classes.root} variant="caption">
                {formatDateTime(val.startDate)} - {formatDateTime(val.endDate)}
              </Typography>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};

const OutsideServiceAreaErrorText: React.FC<{
  market: string | undefined;
}> = ({ market }) => {
  const classes = errorLabelStyles();
  return (
    <>
      <Typography className={classes.root} variant="caption">
        The current service location is outside the active service area for {market}.
      </Typography>
      <CheckBoxField
        name={"overrideOutsideServiceZone"}
        label={"I Acknowledge this appointment is outside of our service area."}
      />
    </>
  );
};
