import React, { Fragment } from "react";
import { useMarkets } from "../../hooks/useMarkets";
import { Field, useFormikContext } from "formik";
import classNames from "classnames";
import { useToggle } from "../../hooks/useToggle";
import { NuModal } from "../NuModal";
import DiscountModal from "./DiscountModal";
import { useShowSuccess } from "../../redux/slices/snackbar";
import currency from "currency.js";
import { EJI_DISCOUNT_TYPE_FLAT, EJI_DISCOUNT_TYPE_PERCENT } from "../../lib/constants";

import { default as ProductErrorIcon } from "@material-ui/icons/NewReleases";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  servicesToPossibleEjiServiceInput,
  promoCodesToPriceInfoInput,
} from "../specs/servicesSpec";

import { PromoCodeSection } from "../PromoCodes/PromoCodeSection";

export const defaultZero = currency(0, { symbol: "" }).format();

export const ServicesReceiptSection: React.FC<{
  receiptValues: any;
  calculatePossibleEJIPriceInfo: any;
  amountPaid?: string;
  taxable: boolean;
  balanceDue?: string;
  ejiType?: string;
  jobId?: string;
}> = ({ receiptValues, amountPaid, balanceDue, calculatePossibleEJIPriceInfo, ejiType, taxable, jobId }) => {
  const markets: any = useMarkets();
  const { values } = useFormikContext();
  if (!markets) {
    return null;
  }
  const jobServices = values['services'].filter(s=>s.inEstimate);
  const technicianPayList = jobServices.map(s=>s.technicianPay);
  const totalTechnicianPay = technicianPayList.length? technicianPayList.reduce((prev, next)=>Number(prev)+Number(next)) : 0;
  const vendorUnitCostList = jobServices.map(s=> (s.items).map(i=> Number(i.vendorUnitCost) * Number(i.units)).reduce((prev, next)=>Number(prev)+Number(next)));

  const totalVendorPartsCost = vendorUnitCostList.length? vendorUnitCostList.reduce((prev, next)=>Number(prev)+Number(next)) : 0;
  const preTaxTotal = Number(receiptValues.finalSubTotal);

  //Gross Profit = Pre-Tax Total - total technician pay - total vendor parts cost
  const grossProfit = preTaxTotal - totalTechnicianPay - totalVendorPartsCost;
  const grossMargin = grossProfit > 0? grossProfit/preTaxTotal : 0;

  return (
    <>
      <div className="border rounded mt-1 mb-8 bg-black-900 px-4 py-2">
        <ReceiptItem
          text={"Subtotal"}
          amount={receiptValues.subTotal ?? "--"}
          bold
          error={
            !receiptValues.subTotal && (
              <div className="flex">
                <ProductErrorIcon color={"primary"} />
                <div className="pl-2 text-primary">Required pricing data is missing</div>
              </div>
            )
          }
        />
        <ReceiptItem text={"Parts Total"} amount={receiptValues.partsTotal ?? "--"} subItem />
        <ReceiptItem text={"Labor Total"} amount={receiptValues.laborTotal ?? "--"} subItem />
        {!!receiptValues.feesTotal && receiptValues.feesTotal !== defaultZero && (
          <ReceiptItem text={"Fee Total"} amount={receiptValues.feesTotal} subItem />
        )}

        <Field name={`promoCodes`}>
          {({ field: { value = [] } }) => {
            return (
              <Fragment>
                {value.map((_, discountIdx) => (
                  <ExistingPromos
                    taxable={taxable}
                    key={`existing-promo-${discountIdx}`}
                    discountIdx={discountIdx}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    ejiType={ejiType}
                  />
                ))}
              </Fragment>
            );
          }}
        </Field>
        <Field name={`discounts`}>
          {({ field: { value = [] } }) => {
            return (
              <Fragment>
                {value.map((_, discountIdx) => (
                  <ExistingDiscount
                    taxable={taxable}
                    key={`existing-discount-${discountIdx}`}
                    discountIdx={discountIdx}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    ejiType={ejiType}
                  />
                ))}
                <AddDiscount
                  taxable={taxable}
                  calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                  ejiType={ejiType}
                />
              </Fragment>
            );
          }}
        </Field>
        <ReceiptItem text={"Pre-Tax Total"} amount={receiptValues.finalSubTotal ?? "--"} />
        <ReceiptItem text={"Total Tax"} amount={receiptValues.totalTax ?? "--"} />
        <ReceiptItem text={"Total"} amount={receiptValues.amountDue ?? "--"} bold />
        {amountPaid && <ReceiptItem text={"Amount Paid"} amount={amountPaid} />}
        {balanceDue && <ReceiptItem text={"Balance Due"} amount={balanceDue} />}
      </div>

      <PromoCodeSection 
        jobId={jobId} 
        calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo} 
        ejiType={ejiType}                 
        grossMargin={grossMargin}
        grossProfit={grossProfit} />
    </>
  );
};

export const ReceiptItem: React.FC<{
  text: string;
  amount: string;
  subItem?: boolean;
  bold?: boolean;
  error?: any;
}> = ({ text, amount, subItem, bold, error }) => {
  const spacingClass = classNames("flex", "flex-row", "justify-between", "m-4", { "pl-6": subItem });
  const textClass = classNames({ "font-bold": bold, "text-xs": !bold });
  return (
    <div className={spacingClass}>
      <div className={textClass}>{text}</div>
      {error}
      <div className={textClass}>${amount}</div>
    </div>
  );
};

const ExistingDiscount: React.FC<{
  discountIdx: number;
  calculatePossibleEJIPriceInfo: any;
  ejiType?: string;
  taxable: boolean;
}> = ({ discountIdx, calculatePossibleEJIPriceInfo, taxable, ejiType }) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const show = useShowSuccess();
  const spacingClass = classNames("text-xs", "flex", "flex-row", "m-4");
  const textClass = classNames("flex", "flex-row, w-1/4");

  console.log("discountIdx", discountIdx)

  return (
    <Field name={`discounts[${discountIdx}]`}>
      {({ field: { value }, form: { values } }) => {
        const onSubmit = (discount) => {
          const discountEdited = [...(values.discounts ?? [])];
          discountEdited.splice(discountIdx, 1, discount);
          calculatePossibleEJIPriceInfo({
            variables: {
              calculatePossibleEJIPriceInfoInput: {
                pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                services: servicesToPossibleEjiServiceInput(values.services),
                discounts: discountsToEjiDiscountInput(discountEdited),
                promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                marketName: values.market,
                taxable: taxable,
                calculateAllServices: ejiType === "INVOICE",
              },
            },
          });
          toggleDiscountModal();
          show({ message: "Discount edited successfully" });
        };

        return (
          <Fragment>
            <div key={`existings-discount-id-${discountIdx}`} className={spacingClass}>
              <div className={textClass}>
                Discount
                {value && value.type && value.type === EJI_DISCOUNT_TYPE_PERCENT && `(${value.amount}%) `}
                <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer mx-2">
                  Edit
                </div>
                <div
                  onClick={() => {
                    //Looks like this is not needed to remove discounts
                    //const discountRemoved = [...(values.discounts ?? [])];
                    console.log("[Remove] - Discount")

                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(values.services),
                          discounts: discountsToEjiDiscountInput([]),
                          marketName: values.market,
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  className="ml-1 underline text-blue-600 cursor-pointer"
                >
                  Remove
                </div>
              </div>
              <div className="flex flex-row justify-between flex-grow">
                <div>
                  {value.reason}{" "}
                  <span>
                    {value.type === EJI_DISCOUNT_TYPE_FLAT ? `$${value.amount * 0.01}` : `%${value.amount}`} Off
                  </span>
                </div>
                <div>- ${value.total ?? "--"}</div>
              </div>
            </div>
            <NuModal isOpen={discountModalOpen} title="Edit Discount">
              <DiscountModal
                existingDiscount={value}
                discountIdx={discountIdx}
                closeModal={toggleDiscountModal}
                onSubmit={onSubmit}
              />
            </NuModal>
          </Fragment>
        );
      }}
    </Field>
  );
};

const ExistingPromos: React.FC<{
  discountIdx: number;
  calculatePossibleEJIPriceInfo: any;
  ejiType?: string;
  taxable: boolean;
}> = ({ discountIdx, calculatePossibleEJIPriceInfo, taxable, ejiType }) => {
  const spacingClass = classNames("text-xs", "flex", "flex-row", "m-4");
  const textClass = classNames("flex", "flex-row, w-1/4");

  console.log("discountIdx", discountIdx)

  return (
    <Field name={`promoCodes[${discountIdx}]`}>
      {({ field: { value }, form: { values } }) => {
        const codeDiscount = value?.coupon?.discount;
        return (
          <Fragment>
            <div key={`existings-promoCodes-id-${discountIdx}`} className={spacingClass}>
              <div className={textClass}>
                {value?.code.toUpperCase() || "Promo Code"}
                {value &&
                  codeDiscount.type &&
                  codeDiscount.type === EJI_DISCOUNT_TYPE_PERCENT &&
                  ` (-${codeDiscount.amount}%) `}
                <div
                  onClick={() => {
                    //const promoCodeRemoved = [...(values.promoCodes ?? [])];

                    //console.log("[Remove] - promoCodes", promoCodeRemoved)

                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput(values.services),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          marketName: values.market,
                          promoCodes: promoCodesToPriceInfoInput([]),
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                  }}
                  className="ml-1 underline text-blue-600 cursor-pointer"
                >
                  Remove
                </div>
              </div>
              <div className="flex flex-row justify-between flex-grow">
                <div>
                  {codeDiscount.reason}{" "}
                  <span>
                    {codeDiscount.type === EJI_DISCOUNT_TYPE_FLAT
                      ? `$${codeDiscount.amount * 0.01}`
                      : `%${codeDiscount.amount}`}{" "}
                    Off
                  </span>
                </div>
                <div>- ${codeDiscount.total ?? "--"}</div>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Field>
  );
};

export const AddDiscount: React.FC<{ taxable: boolean; calculatePossibleEJIPriceInfo: any; ejiType?: string }> = ({
  calculatePossibleEJIPriceInfo,
  taxable,
  ejiType,
}) => {
  const [discountModalOpen, , toggleDiscountModal] = useToggle();
  const spacingClass = classNames("flex", "flex-row", "justify-between", "m-4");
  const textClass = classNames("text-xs", "flex", "flex-row");
  const show = useShowSuccess();
  return (
    <div className={spacingClass}>
      <div className={textClass}>
        Discount (
        <div onClick={toggleDiscountModal} className="underline text-blue-600 cursor-pointer">
          Add
        </div>
        )
      </div>
      <Field name="discounts">
        {({ form: { values } }) => {
          const onSubmit = (discount) => {
            const discountAdded = [...(values.discounts ?? [])];
            discountAdded.push(discount);
            const promosAdded = [...(values.promoCodes ?? [])];
            calculatePossibleEJIPriceInfo({
              variables: {
                calculatePossibleEJIPriceInfoInput: {
                  pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                  services: servicesToPossibleEjiServiceInput(values.services),
                  discounts: discountsToEjiDiscountInput(discountAdded),
                  promoCodes: promoCodesToPriceInfoInput(promosAdded),
                  marketName: values.market,
                  taxable: taxable,
                  calculateAllServices: ejiType === "INVOICE",
                },
              },
            });
            toggleDiscountModal();
            show({ message: "Discount added successfully" });
          };
          return (
            <NuModal isOpen={discountModalOpen} title="Add Discount">
              <DiscountModal closeModal={toggleDiscountModal} onSubmit={onSubmit} />
            </NuModal>
          );
        }}
      </Field>
    </div>
  );
};
