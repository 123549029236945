import React from "react";
import { PartsStoresTable } from "../../components/PartsStores/PartsStoresTable";
import { useMarkets } from "../../hooks/useMarkets";
import { defaultTo, pluck } from "ramda";

const PartsStoresPage = () => {
  const markets = useMarkets();

  return (
    <>
      <div className="px-8 py-4">
        <div>
          <PartsStoresTable options={defaultTo([], markets && pluck("name", markets))} />
        </div>
      </div>
    </>
  );
};
export default PartsStoresPage;
