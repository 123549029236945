import { flow } from "fp-ts/lib/function";
import { applySpec, prop, path, hasPath, cond, T, propOr } from "ramda";
import {
  PossibleEjiService,
  PossibleEjiItem,
  PossibleEjiServiceInput,
  PossibleEjiItemInput,
  EjiDiscount,
  EjiDiscountInput,
  EjiPricingConfig,
  EjiPricingConfigInput,
  VendorUnitCostMultiplier,
  VendorUnitCostMultiplierInput,
  PromoCode,
  PromoCodeInput,
  CouponInput,
  PartsCalcsInput,
  PartsCalcs,
} from "../../generated/nest-graphql";

export const servicesToPossibleEjiServiceSpec = (services: any[]) =>
  services?.map(
    applySpec<PossibleEjiService>({
      service: prop("service"),
      name: prop("name"),
      inEstimate: prop("inEstimate"),
      customerPrice: prop("customerPrice"),
      technicianPay: prop("technicianPay"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcs),
      items: flow(prop("items"), productsToPossibleEjiItemSpec),
    })
  );

export const productsToPossibleEjiItemSpec = (products: any[]) =>
  products?.map(
    applySpec<PossibleEjiItem>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: cond([
          [hasPath(["productSelection", "part", "id"]), (val) => path(["productSelection", "part", "id"], val)],
          [T, (val) => path(["productSelection", "part"], val)],
        ]),
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: prop("overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: cond([
          [hasPath(["orderItem", "partsStore", "id"]), (val) => path(["orderItem", "partsStore", "id"], val)],
          [T, (val) => path(["orderItem", "partsStore"], val)],
        ]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
    })
  );

export const servicesToPossibleEjiServiceInput = (services: any[]) =>
  services?.map(
    applySpec<PossibleEjiServiceInput>({
      service: prop("service"),
      name: prop("name"),
      partsCalcs: flow(prop("partsCalcs"), partsCalcsInput),
      inEstimate: prop("inEstimate"),
      technicianPay: prop("technicianPay"),
      items: flow(prop("items"), productsToPossibleEjiItemInput),
    })
  );

export const partsCalcs = (partCalcs): PartsCalcs => applySpec<PartsCalcs>({
    marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
    overriddenCustomerPrice: prop("overriddenCustomerPrice"),
    totalPrice: prop("totalPrice"),
    vendorCost: prop("vendorCost"),
  })(partCalcs)

export const partsCalcsInput = (partCalcs): PartsCalcsInput => applySpec<PartsCalcsInput>({
  marketVendorUnitCostMultiplierUsed: prop("marketVendorUnitCostMultiplierUsed"),
  overriddenCustomerPrice: prop("overriddenCustomerPrice"),
  totalPrice: prop("totalPrice"),
  vendorCost: prop("vendorCost"),
})(partCalcs)

export const productsToPossibleEjiItemInput = (products: any[]) =>
  products?.map(
    applySpec<PossibleEjiItemInput>({
      serviceProductId: prop("serviceProductId"),
      productSelection: {
        name: path(["productSelection", "name"]),
        part: cond([
          [hasPath(["productSelection", "part", "id"]), (val) => path(["productSelection", "part", "id"], val)],
          [T, (val) => path(["productSelection", "part"], val)],
        ]),
      },
      type: prop("type"),
      units: prop("units"),
      overriddenCustomerPrice: prop("overriddenCustomerPrice"),
      customerPrice: prop("customerPrice"),
      vendorUnitCost: prop("vendorUnitCost"),
      behavior: prop("behavior"),
      category: prop("category"),
      notes: prop("notes"),
      orderItem: {
        partsStore: cond([
          [hasPath(["orderItem", "partsStore", "id"]), (val) => path(["orderItem", "partsStore", "id"], val)],
          [T, (val) => path(["orderItem", "partsStore"], val)],
        ]),
        isOrdered: path(["orderItem", "isOrdered"]),
        partNumber: path(["orderItem", "partNumber"]),
      },
    })
  );

export const discountsToEjiDiscountInput = (discounts: EjiDiscount[]) =>
  discounts?.map(
    applySpec<EjiDiscountInput>({
      type: prop("type"),
      amount: prop("amount"),
      reason: prop("reason"),
    })
  );

export const promoCodesToPriceInfoInput = (promoCodes: PromoCode[]): PromoCodeInput[] =>
  promoCodes?.map(
    applySpec<PromoCodeInput>({
      code: prop("code"),
      name: prop("name"),
      status: prop("status"),
      coupon: flow(prop("coupon"), couponToPromoCodeInput),
      startDate: prop("startDate"),
      expiry: propOr(null, "expiry"),
      restrictions: propOr(null, "restrictions"),
      totalRedemptions: prop("totalRedemptions"),
      combinable: prop("combinable"),
    })
  );

export const couponDiscountToEJIDiscountInput = (discount: EjiDiscount) =>
  applySpec<EjiDiscountInput>({
    type: prop("type"),
    amount: prop("amount"),
    reason: prop("reason"),
  })(discount);

export const couponToPromoCodeInput = (coupon: CouponInput) =>
  applySpec<PromoCodeInput>({
    name: prop("name"),
    valid: prop("valid"),
    discount: flow(prop("discount"), couponDiscountToEJIDiscountInput),
    createdBy: prop("createdBy"),
    totalRedemptions: prop("totalRedemptions"),
  })(coupon);

export const pricingConfigToEjiPricingConfigInput = (pricingConfig: EjiPricingConfig) =>
  applySpec<EjiPricingConfigInput>({
    laborRate: prop("laborRate"),
    vendorUnitCostMultipliers: flow(
      prop("vendorUnitCostMultipliers"),
      vendorUnitCostMultipliersToVendorUnitCostMultiplierInputs
    ),
  })(pricingConfig);

export const vendorUnitCostMultipliersToVendorUnitCostMultiplierInputs = (
  vendorUnitCostMultipliers?: VendorUnitCostMultiplier[]
) =>
  vendorUnitCostMultipliers?.map(
    applySpec<VendorUnitCostMultiplierInput>({
      startRangePrice: prop("startRangePrice"),
      endRangePrice: prop("endRangePrice"),
      multiplier: prop("multiplier"),
    })
  );
