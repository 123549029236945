import React, { useEffect, useState } from "react";
import { Technician } from "../../generated/nest-graphql";
import { always, concat, equals, includes, pluck, without } from "ramda";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { useToggle } from "../../hooks/useToggle";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(6) + 1,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    toolbar: theme.mixins.toolbar,
    checkbox: { paddingTop: 2, paddingBottom: 2 },
  })
);

export const DriveTimeDistanceFilterSectionInternals: React.FC<{
  includeIsActive: boolean | null;
  setIncludeIsActive: (val: boolean) => void;
  setTechniciansToInclude: any;
  setMarketsToInclude: any;
  techniciansToInclude: string[];
  technicians: Technician[];
  markets: string[];
  allTechnicianIds: string[];
  allMarkets?: string[];
}> = ({
  includeIsActive,
  setIncludeIsActive,
  setTechniciansToInclude,
  setMarketsToInclude,
  techniciansToInclude,
  technicians,
  markets,
  allTechnicianIds,
  allMarkets,
}) => {
  const [allFilterChecked, setAllFilterChecked] = useState(true);
  const toggleAllFilters = (_: object) => {
    if (allFilterChecked) {
      setTechniciansToInclude(() => {
        allMarkets.forEach((market) => {
          sessionStorage.setItem(`${market}-technicians`, JSON.stringify([]));
        });
        return [];
      });

      setMarketsToInclude([]); // market sessionStorage updates automagically
    } else {
      setTechniciansToInclude(() => {
        allMarkets.forEach((market) => {
          const marketTechs = technicians.filter((tech) => tech.market === market);

          sessionStorage.setItem(`${market}-technicians`, JSON.stringify(pluck("id", marketTechs)));
        });
        return allTechnicianIds;
      });
      setMarketsToInclude(allMarkets);
    }
  };

  useEffect(() => {
    if (techniciansToInclude.length === allTechnicianIds.length && allMarkets.length === markets.length) {
      setAllFilterChecked(true);
    } else {
      setAllFilterChecked(false);
    }
  }, [techniciansToInclude, allTechnicianIds, markets, allMarkets]);

  const marketPostOnClick = (market: string) => {
    const marketTechs = technicians.filter((tech) => tech.market === market);
    const marketTechIds = pluck("id", marketTechs);
    if (markets.includes(market)) {
      sessionStorage.setItem(`${market}-technicians`, JSON.stringify([]));
      setTechniciansToInclude((t) => {
        return without(marketTechIds, t);
      });
      setMarketsToInclude(without([market], markets));
    } else {
      sessionStorage.setItem(`${market}-technicians`, JSON.stringify(pluck("id", marketTechs)));
      setTechniciansToInclude((t) => {
        return concat(t, marketTechIds);
      });
      setMarketsToInclude(concat(markets, [market]));
    }
  };

  const technicianPostOnClick = (technician: string) => {
    // check if tech market is included in markets array, if not add it
    const market = technicians.find((t) => t.id === technician)?.market;
    if (market && !includes(market, markets)) {
      setMarketsToInclude([...markets, market]);
    }

    // find tech market and add/remove tech from array, set session storage, then rerun getAllTechnicians
    const allTechsInMarket = technicians.filter((t) => t.market === market);
    const allTechIdsInMarket = pluck("id", allTechsInMarket);
    const selectedTechsInMarket = allTechIdsInMarket.filter((t) => techniciansToInclude.includes(t));
    if (includes(technician, selectedTechsInMarket)) {
      sessionStorage.setItem(
        `${market}-technicians`,
        JSON.stringify(selectedTechsInMarket.filter((t) => t !== technician))
      );

      const newIncluded = techniciansToInclude.filter((t) => !equals(t, technician));
      setTechniciansToInclude(newIncluded);
    } else {
      const newSelected = [...selectedTechsInMarket, technician];
      sessionStorage.setItem(`${market}-technicians`, JSON.stringify(newSelected));

      const newIncluded = [...techniciansToInclude, technician];
      setTechniciansToInclude(newIncluded);
    }
  };

  const determineMarketIndeterminate = (market: string) => {
    const allTechsInMarketChecked = technicians
      .filter((t) => t.market === market)
      .every((t) => techniciansToInclude.includes(t.id));

    // market checkbox is indeterminate if not all its technicians are checked
    return !allTechsInMarketChecked;
  };

  const determineSelectAllIndeterminate = () => {
    const marketIndeterminate = markets.length > 0 && markets.length !== allMarkets.length;
    const techsIndeterminate =
      techniciansToInclude.length > 0 && techniciansToInclude.length !== allTechnicianIds.length;
    // market checkbox is indeterminate if not all its technicians are checked
    return marketIndeterminate || techsIndeterminate;
  };

  const classes = useStyles();
  const [open, , toggleDrawer] = useToggle(true);
  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        anchor={"right"}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} />
        <div>
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="end">
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />

        {open && (
          <Box paddingX={1}>
            <Box marginTop={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allFilterChecked}
                    indeterminate={determineSelectAllIndeterminate()}
                    onChange={toggleAllFilters}
                  />
                }
                label={<Typography variant="body2">Select All</Typography>}
              />
            </Box>
            <Box marginBottom={1}>
              <Typography variant="body1">Select Markets To Display</Typography>
              <Box marginTop={1} display="flex" flexDirection="column">
                {allMarkets.map((market, index) => {
                  const indeterminate = determineMarketIndeterminate(market);
                  return (
                    <MarketRow
                      market={market}
                      key={index}
                      postOnClick={marketPostOnClick}
                      opts={markets}
                      indeterminate={indeterminate}
                    />
                  );
                })}
              </Box>
            </Box>
            <Divider />
            <Box marginTop={1} marginBottom={1}>
              <Typography variant="body1">Select Technicians To Display</Typography>
              <Box marginTop={1} display="flex" flexDirection="column">
                {technicians.map((technician, index) => {
                  return (
                    <TechnicianRow
                      technician={technician}
                      key={index}
                      postOnClick={technicianPostOnClick}
                      opts={techniciansToInclude}
                    />
                  );
                })}
              </Box>
            </Box>
            <Divider />
            <Box marginTop={1} marginBottom={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeIsActive}
                    indeterminate={includeIsActive === null}
                    onChange={() => setIncludeIsActive(!includeIsActive)}
                  />
                }
                label={<Typography variant="body2">Include Active Technicians</Typography>}
              />
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
};
const TechnicianRow: React.FC<{
  technician: Technician;
  postOnClick?: (id: string) => void;
  opts?: string[];
}> = ({ technician: { id, firstName, lastName }, postOnClick, opts }) => {
  const classes = useStyles();
  const inValues = includes(id, opts);
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          className={classes.checkbox}
          style={{ paddingTop: 2, paddingBottom: 2 }}
          checked={inValues as any}
          size="small"
          onClick={() => {
            postOnClick && postOnClick(id);
          }}
          onChange={always("")}
        />
      }
      label={<Typography variant="body2">{`${firstName} ${lastName}`}</Typography>}
    />
  );
};
const MarketRow: React.FC<{
  market: string;
  postOnClick: any;
  indeterminate?: boolean;
  opts?: string[];
}> = ({ market, postOnClick, indeterminate, opts }) => {
  const classes = useStyles();
  const inValues = includes(market, opts);

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          className={classes.checkbox}
          style={{ paddingTop: 2, paddingBottom: 2 }}
          checked={inValues as any}
          indeterminate={indeterminate}
          size="small"
          onClick={() => {
            postOnClick && postOnClick(market);
          }}
          onChange={always("")}
        />
      }
      label={<Typography variant="body2">{`${market}`}</Typography>}
    />
  );
};
