import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetEstimateArgs, QueryGetInvoicesPaginatedArgs } from "../../generated/nest-graphql";
import { cond, head, path, T } from "ramda";
import { AddInvoice } from "../Invoices/AddInvoice";
import { Button } from "../Buttons/Button";
import { InvoiceDetails } from "../Invoices/InvoiceDetails";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { GET_INVOICES_PAGINATED } from "../../graphql/queries/getInvoicesPaginated";
import { DEFAULT_SERVICE_CATALOGUE_USED, ROW_LIMIT, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { vehicleSymptomToVehicleSymptomFormValue } from "../Contacts/contactDetailsFormHelpers";
import { getSymptoms } from "../specs/estimateDetailSpec";
import { exists } from "../../commonFunctions";

export const EstimateInvoice: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const [enableAddInvoice, setEnableAddInvoice] = useState(false);
  const estimateResults = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
    fetchPolicy: "cache-first",
  });
  const { data } = useQuery<Query, QueryGetInvoicesPaginatedArgs>(GET_INVOICES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        filter: {
          estimate: estimateId,
        },
        limit: ROW_LIMIT,
        skip: 0,
      },
    },
  });
  if (!path(["getInvoicesPaginated"], data) && !estimateResults?.data?.getEstimate) return null;
  if (!path(["getInvoicesPaginated", "edges", "length"], data)) {
    const {
      market,
      contact,
      items,
      taxable,
      serviceLocation,
      vehicleInfo,
      privateNotes,
      services,
      priceInfo,
      serviceCatalogueUsed,
      vehicleSymptoms,
      requestedServices,
    } = estimateResults?.data?.getEstimate;
    return (
      <div>
        {enableAddInvoice ? (
          <AddInvoice
            initialValues={{
              status: "Draft",
              contact,
              taxable: !exists(contact?.taxExemptEin) ?? taxable ?? true,
              serviceLocation,
              market,
              items,
              technician: null,
              issuedDate: new Date(),
              dueDate: new Date(),
              ...vehicleInfo,
              symptoms: getSymptoms(estimateResults.data.getEstimate) ?? "",
              privateNotes,
              estimate: estimateId,
              serviceCatalogueUsed: serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
              services: cond([
                [
                  (_val) => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && services?.length > 0,
                  () => services,
                ],
                [T, () => []],
              ])(null),
              discounts: priceInfo?.discounts ?? [],
              promoCodes: priceInfo?.promoCodes ?? [],
              priceInfo,
              vehicleSymptoms: vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
              requestedServices: requestedServices ?? null,
            }}
          />
        ) : (
          <Button onClick={() => setEnableAddInvoice(true)}>Add Invoice</Button>
        )}
      </div>
    );
  }
  const invoice = head(data.getInvoicesPaginated.edges);
  return <InvoiceDetails invoiceId={invoice.id} />;
};
