import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationPerformActionArgs, Query, QueryGetActionsArgs } from "../../generated/nest-graphql";
import { GET_ACTIONS } from "../../graphql/queries/getActions";
import { always, cond, path, propOr, T } from "ramda";
import { ButtonThin } from "../Buttons/ButtonThin";
import { PERFORM_ACTION } from "../../graphql/mutations/performAction";
import { useShowError, useShowSuccess } from "../../redux/slices/snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export const ActionsSelect: React.FC<{
  entityName: string;
  entityId: string;
  selfBookingIdentifier?: string;
  hasTechnician?: boolean;
  hasServices?: boolean;
}> = ({ entityName, entityId, hasTechnician, hasServices, selfBookingIdentifier }) => {
  const isJob = entityName === "Job";

  const [isLoading, setIsLoading] = useState(false);
  const showSuccess = useShowSuccess();
  const showError = useShowError();
  const { data } = useQuery<Query, QueryGetActionsArgs>(GET_ACTIONS, {
    variables: {
      entityName,
    },
  });
  const [performAction] = useMutation<Mutation, MutationPerformActionArgs>(PERFORM_ACTION);
  const onPerformAction = async () => {
    if(!value || value === "") return
    if(value === "copyEstimateLinkUrl") {https://app.nubrakes.com/book-now/estimate?landing_page=estimate&jid={{event.job_id}}&utm_medium=sms&identifier={{event.self_booking_identifier}}&utm_source=self-scheduling
      navigator.clipboard.writeText(
        `https://app.nubrakes.com/book-now/estimate?landing_page=estimate&uiv=2&&jid=${entityId}&utm_medium=sms&identifier=${selfBookingIdentifier}&utm_source=self-scheduling`
      )
      return
    }
    if (value && value !== "") {
      setIsLoading(true);
      try {
        await performAction({
          variables: {
            performActionInput: {
              actionName: value,
              entityId: entityId,
              entityName,
            },
          },
        });
        setIsLoading(false);
        showSuccess({ message: "Action Successfully Run" });
      } catch {
        setIsLoading(false);
        showError({ message: "Something Went Wrong" });
      }
    }
  };
  const [value, setValue] = useState("");
  const onChange = (event: object) => {
    const value = path<string>(["target", "value"], event);
    setValue(value);
  };

  if (!data) return null;

  const options = [
    ...data.getActions.map((action) => {
      const actionName = action?.name;
      const valLabels = {
        sendOnWay: "Send On Way",
        technicianSendOnWay: "Technician Send On Way",
        startJob: "Start Job",
        sendBookingEmail: "Re-send Booking Confirmation Email",
        sendRescheduleBookingEmail: "Re-send Job Rescheduled Email ",
        sendUpdateEstimateBookingEmail: "Send Estimate Updated Email",
        sendInEmail: "Send In Email",
        sendReceipt: "Send Receipt",
        sendSelfBookingText: "Send Self-Scheduling SMS",
      };

      const label = propOr("Default Label", action.name, valLabels);

      const isDisabled = cond<any, any>([
        [() => !hasServices && !hasTechnician, always(true)],
        [(label) => hasTechnician && label === valLabels.sendSelfBookingText, always(true)],
        [(label) => !hasTechnician && label !== valLabels.sendSelfBookingText, always(true)],
        [T, always(false)],
      ]);
      const disabled = isDisabled(label) && isJob;

      return {
        value: actionName,
        disabled,
        label,
      };
    }),
  ];

  if(entityName === 'Job') options.push({
    value: "copyEstimateLinkUrl",
    disabled: false,
    label: "Copy Estimate Link URL"
  })

  return (
    <div className="flex flex-row items-center mb-3">
      <div className={"flex-1 mr-2"}>
        <FormControl fullWidth variant="outlined">
          <InputLabel> Actions </InputLabel>
          <Select label={"Actions"} onChange={onChange} value={value}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {options.map((val: any, i) => (
              <MenuItem key={i} value={val.value} disabled={val.disabled}>
                {val.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-0">
        <ButtonThin onClick={onPerformAction} isSubmitting={isLoading} type={"button"}>
          Do Action
        </ButtonThin>
      </div>
    </div>
  );
};
