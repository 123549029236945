import React, { Fragment, useState } from "react";
import { PossibleEjiItem, PossibleEjiService } from "../../generated/nest-graphql";
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Box } from "@material-ui/core";
import { Button } from "../Buttons/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import ProductRow, { ProductSelectionOption } from "./ProductRow";
import { pipe } from "fp-ts/lib/function";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import PartsTable from "./PartsTable";
import LaborTable from "./LaborTable";

type ProductsExpansionProps = {
  serviceIdx: number;
  taxable: boolean;
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
  serviceList: PossibleEjiService[];
};

type AddProductProps = {
  serviceIdx: number;
  serviceName: string;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  taxable: boolean;
  partsStore: any;
  serviceList: PossibleEjiService[];
};

const ProductsExpansionSection = ({
  serviceIdx,
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
  parts,
  taxable,
  serviceList,
}: ProductsExpansionProps) => {
  return (
    <Box margin={1} padding={2} className="bg-black-900">
      <Field name={`services[${serviceIdx}].items`}>
        {({ field: { value: products = [] } }) => {
          const laborIdx = products?.findIndex((item) => item.category === "Labor");
          return (
            <Fragment>
              <div className="flex gap-10">
                <PartsTable
                  serviceIdx={serviceIdx}
                  taxable={taxable}
                  calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                />
                {laborIdx >= 0 && (
                  <LaborTable
                    serviceIdx={serviceIdx}
                    laborIdx={laborIdx}
                    taxable={taxable}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    parts={parts}
                  />
                )}
              </div>
              <div className="flex justify-between items-center mt-5">
                <h3 className="m-0">Input Parts Selection</h3>
                <Field name={`services[${serviceIdx}].name`}>
                  {({ field: { value }, form: { values } }) => (
                    <AddProduct
                      taxable={taxable}
                      serviceIdx={serviceIdx}
                      serviceName={value}
                      ejiType={ejiType}
                      calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                      partsStore={!values.partsOrdered ? values?.technician?.homePartsStore : null}
                      serviceList={serviceList}
                    />
                  )}
                </Field>
              </div>
              <Table
                size="small"
                padding="none"
                className="border"
                style={{
                  borderColor: "rgb(81, 81, 81)",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width:"10%", fontSize: "12px", padding: "10px 5px" }}>
                      Product Type
                    </TableCell>
                    <TableCell className={"w-1/6"} style={{ fontSize: "12px", padding: "10px 5px" }}>Selection</TableCell>
                    <TableCell style={{ fontSize: "12px", width: "7%", padding: "10px 5px" }}>Units</TableCell>
                    <TableCell style={{ fontSize: "12px", width: "10%", padding: "10px 5px" }}>Product Price</TableCell>
                    <TableCell style={{ fontSize: "12px", padding: "10px 5px" }}>Vendor Unit Cost</TableCell>
                    <TableCell style={{ fontSize: "12px", width: "9%", padding: "10px 5px" }}>Part #</TableCell>
                    <TableCell className={"w-1/6"} style={{ fontSize: "12px", padding: "10px 5px" }}>Parts Store</TableCell>
                    <TableCell style={{ fontSize: "12px", padding: "10px 5px" }}>Private Notes</TableCell>
                    <TableCell style={{ fontSize: "12px", width: "3%", padding: "10px 5px" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product, prodIdx) => {
                    if (prodIdx === laborIdx) return null;
                    return (
                      <ProductRow
                        taxable={taxable}
                        product={product}
                        prodIdx={prodIdx}
                        serviceIdx={serviceIdx}
                        partsStores={partsStores}
                        ejiType={ejiType}
                        calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                        parts={parts}
                        withoutSwitchCell
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Fragment>
          );
        }}
      </Field>
    </Box>
  );
};

type ProductOption = {
  label: string;
  value: PossibleEjiItem;
};

const AddProduct = ({
  serviceIdx,
  serviceName,
  ejiType,
  calculatePossibleEJIPriceInfo,
  taxable,
  partsStore,
  serviceList,
}: AddProductProps) => {
  const [selectedProduct, setSelectedProduct] = useState<ProductOption | null>(null);
  const productList: PossibleEjiItem[] = serviceList
    .find(({ name }) => name === serviceName)
    ?.items.map((item) => ({
      ...item,
      orderItem: {
        ...item.orderItem,
        partsStore: partsStore ?? null,
      },
    }));
  const productsToOptions = (productList = []) => {
    return productList.map((product) => {
      return {
        label: product.type,
        value: product,
      };
    });
  };

  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedProduct}
        onChange={(_, selectedOption: ProductOption) => {
          setSelectedProduct(selectedOption);
        }}
        options={productsToOptions(productList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={`services[${serviceIdx}].items`}>
          {({ form: { values } }) => (
            <Button
              onClick={() => {
                if (!!selectedProduct) {
                  const partsStore = values.partsOrdered ? null : values?.technician?.homePartsStore;
                  let servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                  const ejiProduct: ProductOption["value"] = {
                    ...selectedProduct.value,
                    orderItem: { ...selectedProduct.value.orderItem, partsStore },
                  };
                  servicesCopy[serviceIdx].items.push(ejiProduct);
                  calculatePossibleEJIPriceInfo({
                    variables: {
                      calculatePossibleEJIPriceInfoInput: {
                        pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                        services: servicesToPossibleEjiServiceInput(servicesCopy),
                        discounts: discountsToEjiDiscountInput(values.discounts),
                        promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                        marketName: values.market,
                        taxable: taxable,
                        calculateAllServices: ejiType === "INVOICE",
                      },
                    },
                  });
                  setSelectedProduct(null);
                }
              }}
              type={"button"}
            >
              + Add Product
            </Button>
          )}
        </Field>
      </Box>
    </div>
  );
};

export default ProductsExpansionSection;
