import gql from "graphql-tag";
import { partDetailFields } from "./getPartCatalogue";
import { partsStoreDetailsFields } from "./getPartsStore";

export const ejiPriceInfoFields = `
    pricingConfig {
        laborRate
        vendorUnitCostMultipliers {
            startRangePrice
            endRangePrice
            multiplier
        }
    }
    partsTotal
    laborTotal
    feesTotal
    subTotal
    discounts {
        type
        amount
        reason
        total
    }
    promoCodes { 
        code
        name
        status
        coupon {
            name
            valid
            discount {
                type
                amount
                reason
                total
            }
        }
        startDate
        expiry
        totalRedemptions
        combinable
    }
    discountTotal
    promoCodeDiscountTotal
    combinedDiscountTotal
    finalPartsTotal
    finalLaborTotal
    finalFeesTotal
    finalSubTotal
    partsTax
    laborTax
    totalTax
    amountDue
    `;
export const partsCalcsFields = `
    marketVendorUnitCostMultiplierUsed
    overriddenCustomerPrice
    vendorCost
    totalPrice
`
export const possibleEJIServiceFields = `
    service
    name
    inEstimate
    customerPrice
    technicianPay
    partsCalcs {
        ${partsCalcsFields}
    }
    items {
        serviceProductId
        productSelection {
                name
                part {
                    ${partDetailFields}
                }
        }
        overriddenCustomerPrice
        type
        units
        customerPrice
        vendorUnitCost
        behavior
        category
        notes
        orderItem {
            partsStore {
                ${partsStoreDetailsFields}
            }
            isOrdered
            partNumber
        }
        marketVendorUnitCostMultiplierUsed
        marketLaborRateUsed
    }`;

export const calculationReturnFields = `
            ejiPriceInfo {
                ${ejiPriceInfoFields}
            }
            ejiServices {
                ${possibleEJIServiceFields}
            }
        `;

export const CALCULATE_POSSIBLE_EJI_PRICE_INFO = gql`query calculatePossibleEJIPriceInfo($calculatePossibleEJIPriceInfoInput: CalculatePossibleEJIPriceInfoInput!) {
    calculatePossibleEJIPriceInfo(calculatePossibleEJIPriceInfoInput: $calculatePossibleEJIPriceInfoInput) {
        ${calculationReturnFields}
    }
}`;
