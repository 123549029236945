import * as React from "react";
import { useEffect, useRef, useState, useMemo } from "react";
import { VehicleInfoFormSection } from "../VehicleInfo/VehicleInfoFormSection";
import {
  Appointment,
  EjiItem,
  EjiService,
  FleetInfo,
  PartsStore,
  PossibleEjiItem,
  PossibleEjiService,
  Query,
  QueryGetTechniciansArgs,
  Reschedule,
} from "../../generated/nest-graphql";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { FormikErrors, useField } from "formik";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { calcTimeWindowForAppointment, formatDateTime, handleNoDecimal, isPresent } from "../../lib/functions";
import {
  allPass,
  and,
  anyPass,
  append,
  equals,
  find,
  isEmpty,
  isNil,
  join,
  keys,
  omit,
  path,
  pathOr,
  pluck,
  prop,
  propEq,
  propOr,
  remove,
  startsWith,
  uniq,
  either,
} from "ramda";
import { add } from "date-fns/fp";
import { SelectField } from "../FormFields/SelectField";
import { ActionsSelect } from "../FormFields/ActionsSelect";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { TextField } from "../FormFields/TextField";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { AppointmentFormWithModal } from "./AppointmentFormWithModal";
import { AppointmentFormSection } from "./AppointmentFormSection";
import Box from "@material-ui/core/Box";
import { Button } from "../Buttons/Button";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { SaveCancelRow } from "../SaveCancelRow";
import { CopyJobButton } from "../Buttons/CopyJobButton";
import { NuModal } from "../NuModal";
import { ItemForm, ItemFormValues } from "./ItemForm";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { AddItemSetButton } from "../Buttons/AddItemSetButton";
import { Space } from "../Space";
import { Chip, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { JobPartsStoreSelectField } from "../FormFields/JobPartsStoreSelectField";
import { flow, pipe } from "fp-ts/lib/function";
import { JobDetailsFormValues } from "./JobDetailsForm";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { usePartsStores } from "../../hooks/usePartsStores";
import { JOB_STATUS_MASTER_LIST, WITHDRAW_REASONS } from "../../lib/constants";
import ServicesSection from "../Services/ServicesSection";
import { partSpec } from "../specs/partSpec";
import { GET_PART_CATALOGUE } from "../../graphql/queries/getPartCatalogue";
import { exists, rejectBlank } from "../../commonFunctions";
import { InitialJobValues, JobTouchedValues, JobValues } from "../Jobs/JobFormTypes";
import { useMarkets } from "../../hooks/useMarkets";
import { TagsSelectField } from "../FormFields/TagSelectField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CoreTextField from "@material-ui/core/TextField";
import { useShowInfo } from "../../redux/slices/snackbar";
import { CancelButton } from "../Buttons/CancelButton";
import { GET_JOB } from "../../graphql/queries/getJob";
import { GENERATE_JOB_DIAGNOSTICS_SUMMARY } from "../../graphql/queries/generateJobDiagnosticsSummary";
import { GET_VEHICLE_SERVICE_EXCEPTION } from "../../graphql/queries/getVehicleServiceException";
import { symptomDiagnosisCategories, symptomDiagnosisData } from "../../data/diagnosisAndRecommendation";
import { getPlaceGeocode } from "../FormFields/location/functions";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { JobViocStoreSelectField } from "../FormFields/JobViocStoreSelectField";
import classNames from "classnames";

export const JobDetailsInternalForm: React.FC<{
  reschedules?: Reschedule[];
  values: JobValues;
  touched: JobTouchedValues;
  setValues: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  jobNumber?: string;
  jobId?: string;
  appointment: Appointment;
  showAddAppointment: boolean;
  toggleItemModal: () => void;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  validateForm: (values?: any) => Promise<FormikErrors<JobDetailsFormValues>>;
  resetForm: any;
  initialValues: InitialJobValues;
  isSubmitting: boolean;
  isValid: boolean;
  itemModalIsOpen: boolean;
  hasReschedules: boolean;
  isRepeatCustomer: boolean;
  fleet?: FleetInfo;
  onVehicleUpdated?: (vehicleId: string) => void;
  setIsExceptionVehicle?: (isExceptionVehicle: boolean) => void;
}> = ({
  isValid,
  isSubmitting,
  setValues,
  setFieldValue,
  initialValues,
  isRepeatCustomer,
  jobId,
  submitForm,
  values,
  jobNumber,
  appointment,
  showAddAppointment,
  itemModalIsOpen,
  validateForm,
  resetForm,
  toggleItemModal,
  touched,
  hasReschedules,
  fleet,
  setIsExceptionVehicle,
  reschedules,
}) => {
    const markets = useMarkets();
    const [timeZone, setTimeZone] = useState("America/Chicago");
    const [creatingNewVehicle, setCreatingNewVehicle] = useState(false);

    const [rescheduleColor, setRescheduleColor] = useState()

    const warrantyCheckReasonOptions = useMemo(() => {
      let options = [
        "Workmanship",
        "Squeaky/Noisy Brakes",
        "Pulsation/Vibration Concerns",
        "Software or Sensor Malfunction",
        "Pedal Pressure Issue",
        "Customer Satisfaction",
        "Inoperable Vehicle",
        "Other",
      ];
      if (!options.includes(values["warrantyCheckReason"])) {
        options.push(values["warrantyCheckReason"]);
      }
      return options;
    }, [values]);

    useEffect(() => {
      if (exists(values.market) && exists(markets)) {
        const getTimeZone = find(propEq("name", values.market))(markets);
        setTimeZone(propOr("America/Chicago", "timeZone", getTimeZone));
      }
    }, [markets, values.market]);

    useEffect(() => {
      let newColor
      const actualReschedule = reschedules[0]
      if (!hasReschedules || actualReschedule.rescheduleReason === 'HQ: Route optimization') { }
      else if (actualReschedule.rescheduleReason.startsWith('Customer')) {
        newColor = 'green'
      } else if (actualReschedule.rescheduleReason.startsWith('HQ')) {
        if (reschedules.length === 1) {
          newColor = 'yellow'
        } else {
          newColor = 'red'
        }
      }
      setRescheduleColor(newColor)
    }, [hasReschedules, reschedules])

    const { user } = useAuth0();
    const isUserISAManager =
      user && ["matthew@nubrakes.com", "cole@nubrakes.com", "will@nubrakes.com"].includes(user.email);

    const [currentAddressGeocode, setCurrentAddressGeocode] = useState<{ lat: number; lng: number } | null>(null);

    const { data, loading } = useQuery<Query>(GET_PRODUCTS);

    const setAddressGeocodeFromJobData = (data: Query) => {
      const zip = data?.getJob?.serviceLocationZip || data?.getJob?.contact?.lead?.zip || null;
      const { lat, lng } = data?.getJob?.serviceLocationGeocode || {};
      if (lat && lng) {
        setCurrentAddressGeocode({
          lat: typeof lat === "string" ? parseFloat(lat) : lat,
          lng: typeof lng === "string" ? parseFloat(lng) : lng,
        });
      } else if (zip) {
        getPlaceGeocode({
          address: zip,
        }).then((geocodeResponse) => {
          if (geocodeResponse) {
            setCurrentAddressGeocode(geocodeResponse);
          } else {
            setCurrentAddressGeocode(null);
          }
        });
      } else {
        setCurrentAddressGeocode(null);
      }
    };
    const { data: jobData } = useQuery<Query>(GET_JOB, {
      variables: {
        id: jobId,
      },
      skip: !jobId,
      onCompleted: (data) => {
        setAddressGeocodeFromJobData(data);
      },
    });

    const jobTechnician = jobData?.getJob?.appointment?.technician;
    const is1099 = jobTechnician?.is1099;

    const { data: partsData, loading: partsLoading } = useQuery<Query>(GET_PART_CATALOGUE);
    const partsStoresData = usePartsStores();
    const currentRef = useRef();
    const { width, left } = useContainerDimensions(currentRef);
    const { pipedriveDealId } = initialValues;
    /*useEffect(() => {
      const { make, model, year } = values;
      const computedJobName = join(" ", rejectBlank([year!, make!, model!]));
      if (!touched.jobName) {
        if (computedJobName.trim() && values.jobName !== computedJobName) {
          setFieldValue("jobName", computedJobName);
        }
      }
    }, [values.type, values.make, values.model, values.year, values, touched.jobName, setFieldValue]);*/

    const market = values.market;
    const partsStores = anyPass([isNil, isEmpty])(market)
      ? partsStoresData
      : partsStoresData.filter((x) => equals(path(["market", "name"], x)!, market!));
    const defaultStartTime = new Date();
    const defaultEndTime = add({ hours: 1 }, defaultStartTime);
    const onDeleteItem = (idx: number) => () => {
      const result = remove(idx, 1, values.items);
      setFieldValue("items", result, true);
    };
    const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
      const selectedValue: any = prop("value", value);
      const valueKeys = pipe(selectedValue, omit(["id"]), keys);
      valueKeys.forEach((key: any) => {
        const val = selectedValue[key];
        const keyWithPrefix = `${prefix}.${key}`;
        setFieldValue(keyWithPrefix, val ? val : "");
      });
    };
    const partsOrdered = prop("partsOrdered", values);
    const cancellationInitiated = prop("cancellationInitiated", values);
    const cancellationTimestamp = prop("cancellationTimestamp", values);
    const partsOrderedTimestamp = prop("partsOrderedTimestamp", values);
    const [field] = useField("appointmentInfo");
    const { value: appointmentValue } = field;
    const { status } = values;
    const parts = partsLoading ? [] : partsData.getPartCatalogue?.map(partSpec);
    const shouldShowTechsNamesOnSuggestions = values?.type === "Warranty Check" || !!values?.callForPartsTicketNumber;

    const apolloClient = useApolloClient();

    const hasDiagnosticsQuestions = !either(isNil, isEmpty)(jobData?.getJob?.diagnosticsQuestionsAnswers);
    const [loadingDiagnosticsSummary, setLoadingDiagnosticsSummary] = useState(false);
    const handleGenerateDescription = async () => {
      if (!hasDiagnosticsQuestions || loadingDiagnosticsSummary || !jobId) return;
      setLoadingDiagnosticsSummary(true);
      try {
        const response = await apolloClient.query({
          query: GENERATE_JOB_DIAGNOSTICS_SUMMARY,
          variables: {
            generateJobDiagnosticsSummaryInput: {
              jobId: jobId,
            },
          },
        });

        if (response?.data?.generateJobDiagnosticsSummary) {
          setFieldValue("description", response.data.generateJobDiagnosticsSummary);
        }
      } finally {
        setLoadingDiagnosticsSummary(false);
      }
    };

    const hasVehicleData = !!values?.year && !!values?.make && !!values?.model;
    const { data: vehicleExceptionData } = useQuery<Query>(GET_VEHICLE_SERVICE_EXCEPTION, {
      variables: {
        getVehicleServiceExceptionInput: {
          year: Number(values.year),
          make: values.make,
          model: values.model,
        },
      },
      skip: !hasVehicleData,
    });
    const isExceptionVehicle =
      hasVehicleData && !!vehicleExceptionData?.getVehicleServiceException?.id;

    useEffect(() => {
      if (setIsExceptionVehicle) {
        setIsExceptionVehicle(isExceptionVehicle);
      }
    }, [isExceptionVehicle, setIsExceptionVehicle]);

    // Pre-caching technicians
    const marketFilter = React.useMemo(() => {
      return equals("", market) ? { $exists: true } : market;
    }, [market]);
    useQuery<Query, QueryGetTechniciansArgs>(GET_TECHNICIANS, {
      variables: {
        filter: {
          isActive: true,
          market: marketFilter,
        },
      },
    });

    return (
      <div ref={currentRef as any}>
        <div className="mb-4 flex items-center">
          {jobNumber && `Job Number: ${jobNumber}`}{" "}
          {rescheduleColor && <div className={classNames([
            "ml-2 rounded-full text-xs flex items-center justify-center p-2",
            {
              'bg-red-700': rescheduleColor === 'red',
              'bg-green-700': rescheduleColor === 'green',
              'bg-yellow-700': rescheduleColor === 'yellow'
            }
          ])}>RESCHEDULED</div>}
          {isRepeatCustomer && <Chip className="ml-2" color="secondary" label="REPEAT CUSTOMER" />}
          {is1099 && <Chip className="ml-2" color="secondary" label="1099 Contractor" />}
          {fleet?.name && <Chip className="ml-2" color="secondary" label="FLEET" />}
        </div>
        <div className="mb-4">
          {exists(pipedriveDealId) && (
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                // link to production only if in production
                href={`${window.location.hostname === "ops.nubrakes.com"
                    ? "https://nubrakes-1f1d7d.pipedrive.com/deal/"
                    : "https://nubrakes-sandbox.pipedrive.com/deal/"
                  }${pipedriveDealId}`}
              >
                Pipedrive Deal : {pipedriveDealId}
              </a>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <SelectField name={"status"} options={uniq([status, ...JOB_STATUS_MASTER_LIST])} label={"Status"} />
          {jobId && (
            <ActionsSelect
              entityName={"Job"}
              entityId={jobId}
              selfBookingIdentifier={jobData?.getJob?.selfBookingIdentifier}
              hasTechnician={isPresent(path(["technician"], appointment))}
              hasServices={isPresent(path(["services"], values))}
            />
          )}
          <TagsSelectField name={"zendeskTags"} label="Zendesk Tags" options={["Dealership", "Hide Service Call"]} />
        </div>
        <div className="grid grid-cols-1 gap-4 mb-4">
          {flow(pathOr("", ["status"]), startsWith("Withdrawn"))(values) && (
            <SelectField
              name={"withdrawReason"}
              label={"Withdraw Reason"}
              options={WITHDRAW_REASONS}
              helperText={
                prop("withdrawnAt", values) ? `Withdrawn at ${formatDateTime(prop("withdrawnAt", values))}` : undefined
              }
            />
          )}
          {and(
            startsWith("Withdrawn", pathOr("", ["status"], values)),
            startsWith("Other", pathOr("", ["withdrawReason"], values))
          ) && <TextField name={"withdrawExtraInfo"} label={"Additional Withdraw Details"} />}
        </div>
        <DetailViewContainer title={"Contact info"}>
          <div className="grid grid-cols-3 gap-x-4">
            <ContactsSelect name={"contact"} label={"Contact"} required={true} />
            <TextField name={"email"} label={"Contact Email"} type={"email"} helperText={"Updates contact email"} />
            <div className="row-span-1">
              <TextField name={"serviceLocationNotes"} label={"Service Location Notes"} multiline={true} rows={3} />
            </div>
            <MarketFieldSelect name={"market"} required={true} />
            <LocationSearchInput
              name={"serviceLocation"}
              label={"Service Location"}
              postOnChange={(newValue) => {
                if (newValue) {
                  getPlaceGeocode({
                    placeId: newValue.place_id,
                  }).then((geocode) => {
                    if (geocode) {
                      setCurrentAddressGeocode(geocode);
                    }
                  });
                } else {
                  setAddressGeocodeFromJobData(jobData);
                }
              }}
            />
            <SelectField
              name={"locationType"}
              options={[
                "Driveway",
                "Street",
                "Outdoor Lot",
                "Parking Garage",
              ]}
              label={"Location Type"}
            />
          </div>
        </DetailViewContainer>
        <DetailViewContainer title={"Job Details"}>
          <div className="grid grid-cols-2 gap-4">
            <SelectField
              name={"type"}
              required={true}
              options={[
                "Brake Repair Service",
                "Diagnostic",
                "Pre-Job Diagnostic",
                "Service Call",
                "Warranty Check",
                "Vioc Inspection",
              ]}
              label={"Type"}
            />
            <TextField name={"jobName"} label={"Job Name"} required={true} />
            <div className="flex-col">
              <TextField
                name={"description"}
                label={"Description"}
                multiline={true}
                rows={4}
                inputProps={{
                  endAdornment: loadingDiagnosticsSummary ? <CircularProgress size={20} /> : undefined,
                }}
              />
              {hasDiagnosticsQuestions && (
                <Tooltip title="Powered by Open AI ChatGPT">
                  <Button onClick={handleGenerateDescription} type={"button"} disabled={loadingDiagnosticsSummary}>
                    {loadingDiagnosticsSummary ? "Generating..." : "Generate from Diagnostics (AI)"}
                  </Button>
                </Tooltip>
              )}
            </div>
            <TextField
              name={"privateNotes"}
              label={"Private Notes"}
              multiline={true}
              rows={4}
              helperText="Internal notes used by ops and technicians."
            />
            {isUserISAManager && <TextField name={"estimate"} label={"Estimate ID"} />}
            {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
              <TextField required={true} name={"jobCausedWarrantyCheck"} label={"Job Prior to Warranty Check"} />
            )}
            {flow(pathOr("", ["type"]), startsWith("Pre-Job Diagnostic"))(values) && (
              <TextField required={true} name={"jobCausedDiagnostic"} label={"Diagnostic Parent Job"} />
            )}
            {flow(pathOr("", ["type"]), startsWith("Brake Repair"))(values) && (
              <TextField required={false} name={"jobViocInspection"} label={"Related Vioc Inspection"} />
            )}
            {flow(pathOr("", ["type"]), startsWith("Vioc Inspection"))(values) && (
              <TextField required={false} name={"jobViocBrakeRepair"} label={"Related Vioc Brake Repair"} />
            )}
            {(flow(pathOr("", ["type"]), startsWith("Vioc Inspection"))(values) ||
              exists(jobData?.getJob?.jobViocInspection)) && (
                <JobViocStoreSelectField label="Vioc Store" name="viocStore" />
              )}

            {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
              <div style={{ gridColumn: 1 }}>
                <SelectField
                  name={"warrantyCheckReason"}
                  label={"Warranty Check Reason (HQ)"}
                  options={warrantyCheckReasonOptions}
                />
              </div>
            )}
            {allPass([
              flow(pathOr("", ["type"]), startsWith("Warranty")),
              flow(pathOr("", ["warrantyCheckReason"]), startsWith("Other")),
            ])(values) && (
                <TextField
                  style={{ gridColumn: 2 }}
                  name={"warrantyCheckReasonExtraInfo"}
                  label={"Additional Warranty Check Details"}
                />
              )}

            {flow(pathOr("", ["type"]), startsWith("Warranty"))(values) && (
              <div style={{ gridColumn: 1 }}>
                <SelectField
                  name={"warrantyCheckTechReason"}
                  label={"Warranty Check Reason (Technician app)"}
                  options={[
                    "Workmanship",
                    "Squeaking/Noisy Brakes",
                    "Pulsation/Vibration Concerns",
                    "Software or Sensor Malfunction",
                    "Recall-Related",
                    "Customer Satisfaction",
                    "Unusual Driving Conditions",
                    "Other",
                  ]}
                />
              </div>
            )}
            {allPass([
              flow(pathOr("", ["type"]), startsWith("Warranty")),
              flow(pathOr("", ["warrantyCheckTechReason"]), startsWith("Other")),
            ])(values) && (
                <TextField
                  style={{ gridColumn: 2 }}
                  name={"warrantyCheckTechReasonExtraInfo"}
                  label={"Additional Warranty Check Details (Technician app)"}
                />
              )}

            {flow(pathOr("", ["type"]), startsWith("Service Call"))(values) && (
              <div style={{ gridColumn: 1 }}>
                <SelectField
                  name={"serviceCallReason"}
                  label={"Service Call Reason (Technician app)"}
                  options={[
                    "Error with initial booking diagnosis",
                    "No repair necessary",
                    "Unavailable parts",
                    "Cannot perform the necessary repair",
                    "Customer refused service after on-site diagnosis",
                    "Could not access vehicle",
                    "Customer no show or no response",
                    "Wheel Lock Issue(s)",
                    "Other",
                  ]}
                />
              </div>
            )}
            {allPass([
              flow(pathOr("", ["type"]), startsWith("Service Call")),
              flow(pathOr("", ["serviceCallReason"]), startsWith("Other")),
            ])(values) && (
                <TextField
                  style={{
                    gridColumn: 2,
                  }}
                  name={"serviceCallReasonExtraInfo"}
                  label={"Additional Service Call Details (Technician app)"}
                />
              )}
          </div>
        </DetailViewContainer>
        <PartsInfoSection
          appointment={appointment}
          partsOrdered={partsOrdered}
          partsOrderedTimestamp={partsOrderedTimestamp}
          cancellationInitiated={cancellationInitiated}
          cancellationTimestamp={cancellationTimestamp}
          setFieldValue={setFieldValue}
          services={values?.services}
          serviceLocationLat={currentAddressGeocode?.lat}
          serviceLocationLng={currentAddressGeocode?.lng}
          market={market}
        />
        {showAddAppointment && (
          <DetailViewContainer title={"Appointment Info"}>
            <AppointmentFormWithModal
              jobCustomerType={fleet?.name ? "Fleet" : "Consumer"}
              appointment={appointmentValue}
              itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
              services={values?.services}
              partsOrdered={values.partsOrdered}
              jobId={jobId}
              jobType={values.type}
              parentValidateForm={validateForm}
              parentSetFieldValue={setFieldValue}
              market={market}
              serviceLocation={values.serviceLocation}
              timeZone={timeZone}
              contactId={values.contact?.id}
              estimateId={values.estimate}
              initialValues={{
                isFixed: false,
                allDay: false,
                endDate: defaultEndTime,
                subject: values.jobName,
                startDate: defaultStartTime,
                technician: null,
                timeZone,
                overrideOutsideServiceZone: false,
                ...calcTimeWindowForAppointment(false, defaultStartTime, timeZone),
              }}
            />
          </DetailViewContainer>
        )}
        {jobId && (
          <DetailViewContainer title={"Assign & Schedule"}>
            <AppointmentFormSection
              jobCustomerType={fleet?.name ? "Fleet" : "Consumer"}
              shouldShowTechniciansNames={shouldShowTechsNamesOnSuggestions}
              isValid={isValid}
              services={values?.services}
              parentValidateForm={validateForm}
              partsOrdered={values.partsOrdered!}
              itemPartsStores={pluck("partsStore", values.items) as PartsStore[]}
              jobNumber={jobNumber!}
              market={market}
              parentSubmit={submitForm}
              parentSetFieldValue={setFieldValue}
              appointment={appointment}
              jobName={values.jobName}
              jobId={jobId}
              jobType={values.type}
              jobVehicleId={values.vehicleId}
              timeZone={timeZone}
              contactId={values.contact?.id}
              estimateId={values.estimate}
              serviceLocation={values.serviceLocation}
            />
          </DetailViewContainer>
        )}
        {values?.serviceCatalogueUsed === "Services" ? (
          <DetailViewContainer title={"Services"}>
            {!market || market === "ZipUnknown" || market === "" ? (
              "Please select a market first."
            ) : (
              <ServicesSection
                taxable={values.taxable}
                partsStores={partsStores}
                values={values}
                setValues={setValues}
                parts={parts}
                jobId={jobId}
                parentSubmit={submitForm}
              />
            )}
          </DetailViewContainer>
        ) : (
          <DetailViewContainer title={"Items"}>
            <div className="flex flex-row justify-start mb-4">
              <Box mr={2}>
                <Button onClick={toggleItemModal} type={"button"}>
                  + Add Item
                </Button>
              </Box>
              <AddItemSetButton
                name={"items"}
                defaultPartsStore={
                  !values.partsOrdered
                    ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                    : null
                }
              />
            </div>
            {!loading && data?.getProducts && (
              <ItemsTable
                products={data.getProducts}
                items={values.items}
                onDeleteItem={onDeleteItem}
                postItemSelect={postSelectProduct}
                partsStores={partsStores}
              />
            )}
            <ItemsReceiptSection
              items={prop("items", values) as ItemFormValues[]}
              market={values.market}
              taxable={values.taxable}
            />
          </DetailViewContainer>
        )}
        <VehicleInfoFormSection
          defaultExpanded={true}
          onNew={() => { setCreatingNewVehicle(true) }}
          onCancel={() => { setCreatingNewVehicle(false) }}
        />
        {jobId && (
          <DetailViewContainer title={"Diagnosis and Recommendation"} defaultExpanded={false}>
            <SelectField
              name={"symptomDiagnosisCategory"}
              options={symptomDiagnosisCategories}
              label={"Sypmtom Diagnosis Category"}
              noneValue={false}
              hideNone={true}
              required={false}
            />

            {values?.symptomDiagnosisCategory && symptomDiagnosisData[values.symptomDiagnosisCategory] ? (
              <>
                <p className="mt-8">This is what the customer will see on the digital estimate:</p>
                <CoreTextField
                  style={{ marginTop: 24 }}
                  fullWidth
                  label="Symptom Description"
                  contentEditable={false}
                  disabled={true}
                  variant="outlined"
                  multiline={true}
                  value={symptomDiagnosisData[values.symptomDiagnosisCategory]?.description}
                />

                <CoreTextField
                  style={{ marginTop: 24 }}
                  fullWidth
                  label="Recommendation Popup Symptom Description"
                  contentEditable={false}
                  disabled={true}
                  variant="outlined"
                  multiline={true}
                  value={symptomDiagnosisData[values.symptomDiagnosisCategory]?.symptoms?.description}
                  helperText={
                    either(isEmpty, isNil)(symptomDiagnosisData[values.symptomDiagnosisCategory]?.symptoms?.pills)
                      ? null
                      : "Pills: " +
                      symptomDiagnosisData[values.symptomDiagnosisCategory].symptoms.pills
                        .map((p) => p.label)
                        .join(", ")
                  }
                />

                <CoreTextField
                  style={{ marginTop: 24 }}
                  fullWidth
                  label="Diagnosis"
                  contentEditable={false}
                  disabled={true}
                  variant="outlined"
                  multiline={true}
                  value={symptomDiagnosisData[values.symptomDiagnosisCategory]?.diagnosis?.description}
                  helperText={
                    either(isEmpty, isNil)(symptomDiagnosisData[values.symptomDiagnosisCategory]?.diagnosis?.pills)
                      ? null
                      : "Pills: " +
                      symptomDiagnosisData[values.symptomDiagnosisCategory]?.diagnosis?.pills
                        .map((p) => p.label)
                        .join(", ")
                  }
                />
              </>
            ) : null}
          </DetailViewContainer>
        )}
        <Space />
        <Space />
        <SaveCancelRow
          width={width}
          offsetLeft={left}
          isValid={isValid && !creatingNewVehicle}
          isSubmitting={isSubmitting}
          onCancel={() => resetForm(initialValues)}
          midExtra={<>{jobId && <CopyJobButton values={values} />}</>}
        />
        <NuModal isOpen={itemModalIsOpen} title="Add Item">
          {!loading && data?.getProducts && (
            <ItemForm
              defaultPartsStore={
                !values.partsOrdered
                  ? values?.appointmentInfo?.technician?.homePartsStore ?? appointment?.technician?.homePartsStore!
                  : null
              }
              products={data.getProducts}
              onSubmit={async (itemFormValues: ItemFormValues) => {
                const oldItems: ItemsTableType[] = values.items;
                const { product, name, description, partNumber, partsCost, laborCost, amount, isInEstimate, partsStore } =
                  itemFormValues;
                const cleanedItemFormValues: ItemFormValues = {
                  amount: handleNoDecimal(amount),
                  laborCost: handleNoDecimal(laborCost),
                  partsCost: handleNoDecimal(partsCost),
                  isInEstimate,
                  partNumber,
                  description,
                  name,
                  product,
                  partsStore,
                };
                const result = append(cleanedItemFormValues, oldItems as any[]);
                setFieldValue("items", result, false);
                toggleItemModal();
              }}
              onCancel={toggleItemModal}
            />
          )}
        </NuModal>
      </div>
    );
  };

const getPartsItemsWithDifferentPartsStore = (services: JobValues["services"], currentPartsStoreId: string) => {
  let items: {
    itemData: EjiItem | PossibleEjiItem;
    serviceData: EjiService | PossibleEjiService;
    serviceIndex: number;
    productIndex: number;
  }[] = [];
  services?.forEach((service: EjiService | PossibleEjiService, serviceIdx: number) => {
    service.items?.forEach((item: EjiItem | PossibleEjiItem, productIdx: number) => {
      if (item.category === "Part" && item?.orderItem?.partsStore?.id !== currentPartsStoreId) {
        items.push({
          itemData: item,
          serviceData: service,
          serviceIndex: serviceIdx,
          productIndex: productIdx,
        });
      }
    });
  });
  return items;
};

const PartsInfoSection = ({
  appointment,
  partsOrdered,
  partsOrderedTimestamp,
  cancellationInitiated,
  cancellationTimestamp,
  setFieldValue,
  services,
  serviceLocationLat,
  serviceLocationLng,
  market,
}: {
  appointment: Appointment;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  partsOrdered: boolean;
  partsOrderedTimestamp: Date;
  cancellationInitiated: boolean;
  cancellationTimestamp: Date;
  services: JobValues["services"];
  serviceLocationLat?: number;
  serviceLocationLng?: number;
  market?: string;
}) => {
  const [partsStoreForChangeAllModal, setPartsStoreForChangeAllModal] = useState<PartsStore | null>(null);
  return (
    <DetailViewContainer title={"Parts Info"}>
      <div className="w-full">
        <JobPartsStoreSelectField
          homeStore={pathOr(null, ["technician", "homePartsStore"], appointment)!}
          name={"partsStore"}
          label={"Parts Store"}
          postOnChange={(partsStore) => {
            setFieldValue("partsLocation", propOr(null, "address", partsStore));
            const partsItems = getPartsItemsWithDifferentPartsStore(services, partsStore?.id);
            if (partsItems?.length > 0) {
              setPartsStoreForChangeAllModal(partsStore);
            }
            return true;
          }}
          latitude={serviceLocationLat}
          longitude={serviceLocationLng}
          market={market}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="grid col-span-1 gap-2">
          <TextField name={"partsOrderNumber"} label={"Parts Order Number"} />
          <SelectField
            name={"partsLeadTimeInDays"}
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            label={"Parts Lead Time in Days"}
            noneValue={0}
          />
          <CheckBoxField
            name={"partsOrdered"}
            label={"Parts Ordered"}
            postOnChange={(value: boolean) => {
              value ? setFieldValue("partsOrderedTimestamp", new Date()) : setFieldValue("partsOrderedTimestamp", null);
            }}
          />
          {partsOrdered && <Typography>{formatDateTime(partsOrderedTimestamp)}</Typography>}
          <CheckBoxField
            name={"cancellationInitiated"}
            label={"Cancellation Initiated "}
            postOnChange={(value: boolean) => {
              value ? setFieldValue("cancellationTimestamp", new Date()) : setFieldValue("cancellationTimestamp", null);
            }}
          />
          {cancellationInitiated && <Typography>{formatDateTime(cancellationTimestamp)}</Typography>}
        </div>
        <div className="grid col-span-1 gap-2 ">
          <LocationSearchInput name={"partsLocation"} label={"Parts Location"} />
          <TextField name={"partsNotes"} label={"Parts Notes"} multiline={true} rows={4} />
        </div>

        <div className="grid col-span-1 gap-2">
          <SelectField
            name={"partsOrderingIssue"}
            required={false}
            options={[
              "Wrong parts ordered by us",
              "Some parts not ordered",
              "Store never received order",
              "Wrong parts ordered by store",
              "Unnecessary parts ordered",
              "Parts misboxed",
              "Order placed too late",
              "Parts not pulled in time",
              "AA/OR substituted parts",
            ]}
            label={"Parts Ordering Issue"}
            noneValue={"none"}
          />
          <TextField name={"callForPartsTicketNumber"} label={"Call For Parts Ticket #"} />
          {!!partsStoreForChangeAllModal && (
            <PartsAffectedModal
              partsStore={partsStoreForChangeAllModal}
              currentServices={services}
              setFieldValue={setFieldValue}
              onClose={() => {
                setPartsStoreForChangeAllModal(null);
              }}
            />
          )}
        </div>
      </div>
    </DetailViewContainer>
  );
};

const PartsAffectedModal = ({
  partsStore,
  currentServices,
  setFieldValue,
  onClose,
}: {
  partsStore: PartsStore | null;
  currentServices: JobValues["services"];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onClose: () => void;
}) => {
  const partsItems = getPartsItemsWithDifferentPartsStore(currentServices, partsStore?.id);
  const showInfo = useShowInfo();
  return (
    <NuModal isOpen={!!partsStore} maxWidth="md" title="Parts Store Update">
      <p className="mb-4">Do you also want to update the parts store field of all parts to "{partsStore?.name}"?</p>
      <p className="mb-4">These are the parts that are going to be affected: </p>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Part Type</strong>
              </TableCell>
              <TableCell>
                <strong>Product Selection</strong>
              </TableCell>
              <TableCell>
                <strong>Current Parts Store</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partsItems.map((item) => {
              return (
                <TableRow key={`${item?.serviceIndex}-${item?.productIndex}`}>
                  <TableCell>{item.itemData?.type}</TableCell>
                  <TableCell>{item.itemData?.productSelection?.name || "-"}</TableCell>
                  <TableCell>{item.itemData?.orderItem?.partsStore?.name || "-"}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex flex-row mt-8 justify-between">
        <CancelButton type={"button"} onClick={onClose}>
          No, thanks
        </CancelButton>
        <Button
          onClick={() => {
            partsItems.forEach((item) => {
              setFieldValue(
                `services[${item.serviceIndex}].items[${item.productIndex}].orderItem.partsStore`,
                partsStore
              );
            });
            showInfo({
              message: "Parts stores updated. Don't forget to save your changes.",
              autoHideDuration: 6000,
            });
            onClose();
          }}
        >
          Yes, update it for all parts
        </Button>
      </div>
    </NuModal>
  );
};
