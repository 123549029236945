import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "./Alert";
import React from "react";
import { snackbarClose } from "../redux/slices/snackbar";
import { useAppDispatch, useAppSelector } from "../redux/util";

export const GlobalSnackBarV2 = () => {
  const { showSnackBar, snackBarMessage, snackBarType, snackBarAutoHideDuration } = useAppSelector(
    (state) => state.snackbar
  );
  const dispatch = useAppDispatch();
  const handleClose = (event: any, reason?: any) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackbarClose());
  };
  return (
    <>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={(event, reason) => {
          handleClose(event, reason);
        }}
        style={{ whiteSpace: "pre-line" }}
      >
        <Alert onClose={handleClose} severity={snackBarType}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
