export const authorizedEmails = [
  "chris@nubrakes.com",
  "nafe@nubrakes.com",
  "patrick.haley@nubrakes.com",
  "meredith.land@nubrakes.com",
  "matthew@nubrakes.com",
  "cole@nubrakes.com",
  "will@nubrakes.com",
  "leon@nubrakes.com",
  "walker@nubrakes.com",
  "catherine@nubrakes.com",
  "philip.ferreira@nubrakes.com",
  "collin@nubrakes.com",
  "clay@nubrakes.com",
  "jim.crawford@nubrakes.com",
];
