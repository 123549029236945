import React, { ReactNode } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetJobsArgs } from "../../generated/nest-graphql";
import { applySpec, isEmpty, isNil, mergeDeepRight, path, pathOr, pipe, prop, reject } from "ramda";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import LinearProgress from "@material-ui/core/LinearProgress";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { ROW_LIMIT } from "../../lib/constants";

type JobsTableType = {
  id: string;
  jobNumber: string;
  type: string;
  jobName: string;
  contact: {
    id: string;
    firstName: string;
    lastName: string;
  };
  market: string;
  updateAt: string;
  status: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export const JobColumns: Column<JobsTableType>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/jobs/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Job Number", accessor: "jobNumber" },
  {
    Header: "Contact",
    accessor: "contact",
    Cell: (props) => {
      const { firstName, lastName, id } = props.cell.value;
      return (
        <Link className={"text-primary w-full block text-center"} to={`/contacts/${id}`}>
          {firstName} {lastName}
        </Link>
      );
    },
  },
  { Header: "Job Name", accessor: "jobName" },
  { Header: "Market", accessor: "market" },
  { Header: "Status", accessor: "status" },
  { Header: "Created By", accessor: "createdBy" },
  { Header: "Created", accessor: "createdAt" },
  { Header: "Updated", accessor: "updatedAt" },
];

export const ServiceRequestsTable: React.FC<{
  filters: any;
  endAdornments: ReactNode;
}> = ({ filters, endAdornments }) => {
  const filtersToSet = reject((val) => isNil(val) || isEmpty(val))(filters);
  const { data, loading, fetchMore } = useQuery<Query, QueryGetJobsArgs>(GET_JOBS, {
    variables: {
      limit: ROW_LIMIT,
      filter: { ...filtersToSet, appointment: null },
    },
  });
  const loadMore = async (cursor) => {
    await fetchMore({
      variables: {
        filter: { ...filtersToSet, appointment: null },
        limit: ROW_LIMIT,
        cursor,
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getJobs: {
            edges: [...prev.getJobs.edges, ...fetchMoreResult.getJobs.edges],
            pageInfo: mergeDeepRight(prev.getJobs.pageInfo, fetchMoreResult.getJobs.pageInfo),
            __typename: prev.getJobs.__typename,
          },
        };
      },
    });
  };

  return (
    <>
      {loading && <LinearProgress />}
      <SelectablePageableEntityTable
        title={"Service Requests"}
        queryResult={data}
        columns={JobColumns}
        queryKey={"getJobs"}
        numRecords={pathOr(0, ["getJobs", "pageInfo", "numRecords"], data)}
        loadMore={() => loadMore(data.getJobs.pageInfo.endCursor)}
        limit={ROW_LIMIT}
        spec={jobsToJobColumns}
        endAdornments={endAdornments}
      />
    </>
  );
};
export const jobsToJobColumns = applySpec({
  id: prop("id"),
  jobName: prop("jobName"),
  jobNumber: prop("jobNumber"),
  contact: {
    id: path(["contact", "id"]),
    firstName: path(["contact", "firstName"]),
    lastName: path(["contact", "lastName"]),
  },
  market: prop("market"),
  createdBy: prop("createdBy"),
  createdAt: pipe(prop("createdAt"), formatDateTime),
  updatedAt: pipe(prop("updatedAt"), formatDateTime),
  status: prop("status"),
});
