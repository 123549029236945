import React, { useState } from "react";
import { File, Mutation, MutationDeleteFileArgs } from "../../generated/nest-graphql";
import { Column } from "react-table";
import { EntityTable } from "../TableViewsPages/EntityTable";
import { applySpec, map, pipe, prop } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { useMutation } from "@apollo/client";
import { DeleteFile } from "../../graphql/mutations/deleteFile";
import Modal from "react-modal";
import { CancelButton } from "../Buttons/CancelButton";
import { formatDateTime } from "../../lib/functions";
import { Button } from "../Buttons/Button";
import { NuModal } from "../NuModal";
import { useToggle } from "../../hooks/useToggle";

export const FilesTable: React.FC<{ files: File[]; contactId: string }> = ({ files, contactId }) => {
  Modal.setAppElement("#root");
  const [deleteFile] = useMutation<Mutation, MutationDeleteFileArgs>(DeleteFile);
  const [deleteModalOpen, , toggleModal] = useToggle();
  const [fileName, setFileName] = useState(null);
  const FileColumns: Column[] = [
    {
      Header: "Filename",
      accessor: "fileName",
      Cell: (props) => {
        return (
          <a href={props.cell.value.signedUrl} rel="noopener noreferrer" target={"_blank"} className={"text-primary"}>
            {props.cell.value.fileName}
          </a>
        );
      },
    },
    { Header: "Caption", accessor: "caption" },
    { Header: "Created At", accessor: "createdAt" },
    { Header: "Updated At", accessor: "updatedAt" },
    {
      id: "delete",
      Header: <div>Delete</div>,
      Cell: (props) => {
        return (
          <div
            className="text-center cursor-pointer"
            onClick={async () => {
              const index = props.row.index;
              const value = files[index];
              const fileName = value.fileName;
              setFileName(fileName);
              toggleModal();
            }}
          >
            <FontAwesomeIcon icon={faTrash as any} />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <EntityTable
        title={"Files"}
        data={fileToFileColumns(files)}
        columns={FileColumns}
        numRecords={files.length}
        pluginHooks={(hooks) => hooks}
      />
      <NuModal isOpen={deleteModalOpen}>
        <div>
          <div>Are You Sure You Want to Delete {fileName}?</div>
          <div className="flex flex-row justify-between">
            <div>
              <CancelButton type={"button"} onClick={() => toggleModal()}>
                Cancel
              </CancelButton>
            </div>
            <div>
              <Button
                onClick={async () => {
                  await deleteFile({
                    variables: {
                      contact: contactId,
                      fileName: fileName,
                    },
                  });
                  window.location.reload();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </NuModal>
    </>
  );
};

export const fileToFileColumns = (files: File[]) =>
  map(
    applySpec({
      fileName: {
        fileName: prop("fileName"),
        signedUrl: prop("signedUrl"),
      },
      caption: prop("caption"),
      createdAt: pipe(prop("createdAt"), formatDateTime),
      updatedAt: pipe(prop("updatedAt"), formatDateTime),
    })
  )(files);
