import React from "react";
import { pluck } from "ramda";
import { DriveTimeDistanceFilterSectionInternals } from "./DriveTimeDistanceFilterSectionInternals";
import { Technician } from "../../generated/nest-graphql";

export const DriveTimeDistanceFilterSection: React.FC<{
  initialTechnicians: string[];
  initialMarkets: string[];
  includeIsActive: boolean | null;
  setIncludeIsActive: (val: boolean) => void;
  setTechniciansToInclude;
  setMarketsToInclude;
  allTechnicians: Technician[];
  allMarkets: string[];
}> = ({
  setTechniciansToInclude,
  setMarketsToInclude,
  initialMarkets,
  initialTechnicians,
  includeIsActive,
  setIncludeIsActive,
  allTechnicians,
  allMarkets,
}) => {
  const allTechIds = pluck("id", allTechnicians);
  return (
    <DriveTimeDistanceFilterSectionInternals
      setTechniciansToInclude={setTechniciansToInclude}
      setMarketsToInclude={setMarketsToInclude}
      includeIsActive={includeIsActive}
      setIncludeIsActive={setIncludeIsActive}
      techniciansToInclude={initialTechnicians}
      technicians={allTechnicians}
      markets={initialMarkets}
      allTechnicianIds={allTechIds}
      allMarkets={allMarkets}
    />
  );
};
