import React from "react";
import { Column, useRowSelect, useTable } from "react-table";
import { HeaderRenderer } from "./HeaderRenderer";
import { CellRenderer } from "./CellRenderer";
import { Button } from "../Buttons/Button";
import { BaseTableUI } from "./BaseTableUI";
import { ROW_LIMIT } from "../../lib/constants";

interface SelectableEntityTableProps<T extends object> {
  data: any[];
  columns: Array<Column<T>>;
  numRecords: number;
  loadMore?: () => void;
  title: string;
  endAdornments?: React.ReactNode;
}

export const SelectableEntityTable = <T extends object>({
  title,
  data,
  columns,
  numRecords,
  loadMore,
  endAdornments,
}: SelectableEntityTableProps<T>) => {
  const useTableProps = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: ROW_LIMIT,
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: HeaderRenderer,
          Cell: CellRenderer,
        },
        ...columns,
      ]);
    }
  );
  const { getTableProps, headerGroups, rows, getTableBodyProps, prepareRow, selectedFlatRows } = useTableProps;
  return (
    <>
      <BaseTableUI
        title={title}
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        numSelected={selectedFlatRows.length}
        endAdornments={endAdornments}
      />
      {loadMore && (
        <div className={"pt-5 flex flex-col items-center"}>
          <div className={"mx-4"}>
            <Button onClick={loadMore}>Load More</Button>
          </div>
        </div>
      )}
    </>
  );
};
