import gql from "graphql-tag";

const rescheduleDetailsFields = `
            rescheduleReason
        `;

export const GET_JOB_RESCHEDULES = gql`query getJobReschedules($jobNumber: String!) {
    getJobReschedules(jobNumber: $jobNumber) {
        ${rescheduleDetailsFields}
    }
}`;
