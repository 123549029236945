import gql from "graphql-tag";
import { calculationReturnFields } from "./calculatePossibleEJIPriceInfo";

export const eventFields = `
  event
  error
  data
`;

export const generateServicesFields = `
  priceResult {
    ${calculationReturnFields}
  }
  events {
    ${eventFields}
  }
`;

export const GENERATE_SERVICES = gql`query generateServices($generateServicesInput: GenerateServicesInput!) {
    generateServices(generateServicesInput: $generateServicesInput) {
      ${generateServicesFields}
    }
}`;
