import React from "react";
import { ThemeProvider, Switch, createTheme, Card, Typography } from "@material-ui/core";
import { Field } from "formik";
import { pipe } from "fp-ts/lib/function";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { allPass, always, cond, T } from "ramda";
import DebouncedCurrencyInputV2 from "../FormFields/DebouncedCurrencyInputV2";

type PartsTableProps = {
  serviceIdx: number;
  taxable: boolean;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
};

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      track: {
        // unchecked coloring
        opacity: 0.2,
        backgroundColor: "#fff",
        "$checked$checked + &": {
          // checked coloring
          opacity: 1,
          backgroundColor: "#3CBC45",
        },
      },
    },
  },
});

const PartsTable = ({ serviceIdx, taxable, ejiType, calculatePossibleEJIPriceInfo }: PartsTableProps) => {
  return (
    <Card
      variant="outlined"
      className="flex gap-5 px-5 py-2 w-full max-w-1/3 h-[100px]"
      style={{
        borderColor: "rgb(81, 81, 81)",
        background: "transparent",
      }}
    >
      <Field name={`services[${serviceIdx}].partsCalcs.overriddenCustomerPrice`}>
        {({ field: { value }, form: { setFieldValue, values } }) => {
          const targetProduct = values?.services[serviceIdx].partsCalcs;
          const markupText = cond([
            [
              allPass([
                () => targetProduct?.marketVendorUnitCostMultiplierUsed != null,
                () => !targetProduct?.overriddenCustomerPrice,
              ]),
              always(`${targetProduct?.marketVendorUnitCostMultiplierUsed}x`),
            ],
            [T, always("-")],
          ])();
          return (
            <div className="flex">
              <div className="mt-1">
                <h3 className="m-0 mb-2 text-xl">Parts</h3>
                <span className="text-lg text-gray-500">{markupText}</span>
              </div>
              <ThemeProvider theme={theme}>
                <Switch
                  className="mx-5"
                  checked={!value}
                  color="default"
                  onChange={(val) => {
                    if (!val.target.checked) {
                      setFieldValue(
                        `services[${serviceIdx}].partsCalcs.overriddenCustomerPrice`,
                        !val.target.checked
                      );
                    } else {
                      const servicesCopy = pipe(values.services, JSON.stringify, JSON.parse);
                      if (!servicesCopy[serviceIdx].partsCalcs) {
                        servicesCopy[serviceIdx].partsCalcs = {};
                      }
                      servicesCopy[serviceIdx].partsCalcs.overriddenCustomerPrice = !val.target.checked;
                      calculatePossibleEJIPriceInfo({
                        variables: {
                          calculatePossibleEJIPriceInfoInput: {
                            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                            services: servicesToPossibleEjiServiceInput(servicesCopy),
                            discounts: discountsToEjiDiscountInput(values.discounts),
                            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                            marketName: values.market,
                            taxable: taxable,
                            calculateAllServices: ejiType === "INVOICE",
                          },
                        },
                      });
                    }
                  }}
                  size="medium"
                />
              </ThemeProvider>
            </div>
          );
        }}
      </Field>
      <Field name={`services[${serviceIdx}].partsCalcs.vendorCost`}>
        {({ field: { value, name }, meta: { error } }) => {
          return (
            <div className="flex flex-col gap-y-2 w-1/2">
              <label className="text-sm">Total Vendor Parts Cost</label>
              <Typography className="text-gray-500">${value}</Typography>
            </div>
          );
        }}
      </Field>
      <Field name={`services[${serviceIdx}].partsCalcs.totalPrice`}>
        {({ field: { value, name }, meta: { error } }) => {
          return (
            <div className="flex flex-col gap-y-2 w-1/2">
              <label className="text-sm">Total Parts Price</label>
              <Typography className="text-gray-500">${value}</Typography>
            </div>
          );
        }}
      </Field>
    </Card>
  );
};

export default PartsTable;
