import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { filterDropDownStyles } from "../../material-ui-styles";
import { useField } from "formik";

export const FilterSelectField: React.FC<{
  name: string;
  options: string[];
  label: string;
}> = ({ label, options, name }) => {
  const classes = filterDropDownStyles();
  const [{ value, onChange }] = useField(name);

  return (
    <FormControl className={classes.root} variant="filled">
      <InputLabel>{label}</InputLabel>
      <Select label={label} name={name} value={value || ""} onChange={onChange}>
        <MenuItem value={null}>
          <em>None</em>
        </MenuItem>
        {options.map((val, i) => (
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
