import React from "react";
import { useField } from "formik";
import { ErrorTextField } from "./ErrorTextField";
import { normalizePhone } from "../../lib/normalizers";
import TextField from "@material-ui/core/TextField";

export const PhoneNumberField: React.FC<{
  name: string;
  required?: boolean;
  label?: string;
}> = ({ required, label, name, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { value, ...fieldRest } = field;
  return (
    <TextField
      fullWidth
      error={!!meta.error}
      variant={"outlined"}
      label={label}
      required={required}
      value={value ? normalizePhone(value) : ""}
      helperText={<ErrorTextField fieldName={field.name} displayError={!!meta.error} />}
      {...fieldRest}
      onChange={(event: object) => {
        // @ts-ignore
        const val = normalizePhone(event.target.value);
        helpers.setValue(val);
      }}
    />
  );
};
