import React from "react";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../Buttons/SubmitButton";
import { TextField } from "../FormFields/TextField";
import * as Yup from "yup";
import CurrencyInput from "../FormFields/CurrencyInput";
import { CancelButton } from "../Buttons/CancelButton";
import Box from "@material-ui/core/Box";
import { ItemFormProductSelect } from "../FormFields/ItemFromProductSelect";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { omit, prop } from "ramda";
import { PartsStore, Product } from "../../generated/nest-graphql";
import { PartsStoreSelectField } from "../FormFields/PartsStoreSelectField";
import { usePartsStores } from "../../hooks/usePartsStores";

export type ItemFormValues = {
  product?: string | null;
  name: string;
  amount: string;
  laborCost: string;
  partsCost: string;
  isInEstimate: boolean;
  partNumber?: string;
  description?: string | null;
  partsStore: PartsStore;
};
const ItemFormValidationScheme = Yup.object().shape({
  name: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  laborCost: Yup.string().required("Required"),
  partsCost: Yup.string().required("Required"),
  partNumber: Yup.string().nullable(),
  description: Yup.string().nullable(),
  partsStore: Yup.object().nullable(),
});
export const ItemForm: React.FC<{
  products: Product[];
  onSubmit: (values: any, helper: any) => void;
  initialValues?: ItemFormValues;
  onCancel: () => void;
  defaultPartsStore?: PartsStore;
}> = ({ defaultPartsStore, onSubmit, initialValues, onCancel, products }) => {
  const partsStores = usePartsStores();
  return (
    <Formik<ItemFormValues>
      validationSchema={ItemFormValidationScheme}
      initialValues={
        initialValues
          ? initialValues
          : {
              product: null,
              name: "",
              amount: "",
              isInEstimate: false,
              description: "",
              laborCost: "",
              partNumber: "",
              partsCost: "",
              partsStore: defaultPartsStore,
            }
      }
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, setValues, values }) => {
        const postOnChange = (value: ProductOptionType) => {
          const selectedValue = prop("value", value);
          // const valueKeys = pipe(omit(['id']), keys)(selectedValue);
          const updates = omit(["id"], selectedValue);
          setValues({
            ...values,
            product: prop("id", selectedValue),
            ...updates,
            isInEstimate: false,
          });
        };
        return (
          <Form>
            <Box padding={2}>
              <div className="grid gap-2">
                <ItemFormProductSelect
                  products={products}
                  postSelect={postOnChange}
                  name={"product"}
                  required={true}
                  label={"Product"}
                />
                <TextField name={"name"} label={"Name"} required={true} />
                <CurrencyInput name={"amount"} label={"Amount"} required={true} />
                <CurrencyInput name={"laborCost"} label={"Technician Pay"} required={true} />
                <CurrencyInput name={"partsCost"} label={"Customer Parts Price"} required={true} />
                <TextField name={"partNumber"} label={"Part Number"} />
                <TextField name={"description"} label={"Description"} />
                <Field name={`partsStore`}>
                  {({ field, meta, form }: any) => (
                    <PartsStoreSelectField
                      name={`partsStore`}
                      label={""}
                      value={field.value}
                      required={false}
                      onChange={(_: any, newValue: any) => {
                        form.setFieldValue(`partsStore`, prop("value", newValue));
                      }}
                      error={meta.error}
                      options={partsStores}
                    />
                  )}
                </Field>
              </div>
              <Box display="flex" marginTop={2}>
                <Box justifyContent="flex-start" flexGrow={1}>
                  <CancelButton type={"button"} onClick={onCancel}>
                    Cancel
                  </CancelButton>
                </Box>
                <Box justifyContent="flex-end">
                  <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                    Submit
                  </SubmitButton>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
