import gql from "graphql-tag";

export const GET_VEHICLE_SERVICE_EXCEPTION = gql`
  query getVehicleServiceException($getVehicleServiceExceptionInput: GetVehicleServiceExceptionInput!) {
    getVehicleServiceException(getVehicleServiceExceptionInput: $getVehicleServiceExceptionInput) {
      id
      yearRangeStart
      yearRangeEnd
      vehicleModel
      vehicleMake
      vehicleSubmodel
      VehicleServiceExceptionRuleId
    }
  }
`;
