import * as React from "react";
import { useState } from "react";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { defaultTo, propOr } from "ramda";

type BasicOption = {
  label: string;
  value: string;
};

type AutoCompleteSelectFieldProps = {
  value: BasicOption;
  error: any;
  rest?: any;
  loading?: boolean;
  required?: boolean;
  options: BasicOption[];
  onChange: any;
};

const filter = createFilterOptions<BasicOption>();

export const BasicAutoCompleteField = ({
  value,
  error,
  rest,
  onChange,
  required,
  options,
  loading = false,
}: AutoCompleteSelectFieldProps) => {
  const [inputText, setInputText] = useState(value.value);
  return (
    <Autocomplete<BasicOption, boolean, false, true>
      {...rest}
      value={value}
      handleHomeEndKeys
      freeSolo
      selectOnFocus
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") return option;
        if (typeof option.value === "string") {
          return propOr("", "label", option);
        }
        return propOr("", "label", option);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            label: `Add "${params.inputValue}"`,
            value: params.inputValue,
          });
        }
        return filtered;
      }}
      onChange={onChange}
      onClose={(_, reason) => {
        if (reason === "blur" && inputText !== value.value) {
          onChange(_, inputText);
        }
      }}
      onInputChange={(e, v, r) => {
        setInputText(v);
      }}
      renderInput={(params) => <TextField {...params} required={required} variant="outlined" error={!!error} />}
      helperText={defaultTo("", !!error && error)}
      loading={loading}
      options={options}
    />
  );
};
