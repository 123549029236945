import React from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetAppointmentsArgs, QueryGetTechniciansArgs, Technician } from "../../generated/nest-graphql";
import {
  __,
  ascend,
  defaultTo,
  filter,
  includes,
  isNil,
  map,
  not,
  path,
  pathEq,
  pipe,
  pluck,
  prop,
  propEq,
  sort,
} from "ramda";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { GET_APPOINTMENTS } from "../../graphql/queries/getAppointments";
import { useScheduling } from "../../hooks/useScheduling";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createStyles, LinearProgress, Theme } from "@material-ui/core";
import { DriveTimeDistanceFilterSection } from "../../components/DriveTimeDistance/DriveTimeDistanceFilterSection";
import { DriveTimeDistanceTable } from "../../components/DriveTimeDistance/DriveTimeDistanceTable";
import { useStateWithSessionStorage } from "../../hooks/useStateWithSessionStorage";
import { DateInputNonFormik as DateInput } from "../../components/FormFields/DateInput";
import { isPresent } from "../../lib/functions";
import { endOfDay, startOfDay } from "date-fns";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      display: "grid",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    selectDate: {
      width: 200,
    },
  })
);

export const getTechsFromMarket = (market: string) => {
  const techs = sessionStorage.getItem(`${market}-technicians`);
  if (techs) {
    return JSON.parse(techs);
  }
  return [];
};

const DriveTimeDistancesPage = () => {
  const classes = useStyles();
  const [dateToFocusOn, setDateToFocusOn] = useStateWithSessionStorage("dateToFocus", new Date());

  const { setMarketsToInclude, marketsToInclude, includeIsActive, setIncludeIsActive } = useScheduling();

  const getAllSelectedTechs = () => {
    const allSelected = marketsToInclude.map(getTechsFromMarket);
    return allSelected.reduce((acc, curr) => acc.concat(curr), []);
  };

  const [techniciansToInclude, setTechniciansToInclude] = React.useState(getAllSelectedTechs());

  const { data, loading } = useQuery<Query, QueryGetAppointmentsArgs>(GET_APPOINTMENTS, {
    variables: {
      startRange: startOfDay(new Date(dateToFocusOn)),
      endRange: endOfDay(new Date(dateToFocusOn)),
    },
  });

  const appointmentsFiltered = pipe(
    filter((appointment: any) => {
      return isPresent(prop("job", appointment));
    }),
    filter((appointment: any) => {
      const {
        technician: { id },
      } = appointment;
      return techniciansToInclude.includes(id);
    }),
    filter((appointment: any) => {
      const {
        technician: { market },
      } = appointment;
      return marketsToInclude.includes(market);
    }),
    filter((appointment: any) => {
      if (isNil(includeIsActive)) return true;
      return path(["technician", "isActive"], appointment);
    }),
    filter((appointment: any) => {
      return pipe(
        path(["job", "status"]),
        // @ts-ignore
        includes(__, ["Closed", "Withdrawn", "Withdrawn: Rescheduled"]),
        not
        // @ts-ignore
      )(appointment);
    })
  )(defaultTo([], prop("getAppointments", data)));

  const { data: dataTechnicians, loading: loadingTechnicians } = useQuery<Query, QueryGetTechniciansArgs>(
    GET_TECHNICIANS,
    {
      onCompleted: (data) => {
        const storedTechs = getAllSelectedTechs();
        if (storedTechs && storedTechs.length > 0) {
          setTechniciansToInclude(storedTechs);
        } else {
          const filteredTechs = filter((technician: Technician) => {
            if (isNil(includeIsActive)) return true;
            // @ts-ignore
            return propEq("isActive", includeIsActive, technician);
          }, data.getTechnicians);
          const allTechnicians = pluck("id", filteredTechs);
          setTechniciansToInclude(allTechnicians);
        }
      },
    }
  );

  const technicians: Technician[] = defaultTo([], prop("getTechnicians", dataTechnicians));

  const filteredTechs = React.useMemo(
    () =>
      filter((technician: Technician) => {
        if (isNil(includeIsActive)) return true;
        // @ts-ignore
        return propEq("isActive", includeIsActive, technician);
      }, technicians),
    [technicians, includeIsActive]
  );

  const allMarkets = React.useMemo(
    () =>
      technicians
        .filter((technician: Technician) => {
          if (isNil(includeIsActive)) return true;
          return propEq("isActive", includeIsActive, technician as any);
        })
        .reduce((acc, curr) => {
          const market = curr.market || "";
          if (!acc.includes(market)) {
            acc.push(market);
          }
          return acc;
        }, [] as string[]),
    [technicians, includeIsActive]
  );

  const techniciansToShowInCalendar = pipe(
    filter((technician: Technician) => {
      return techniciansToInclude.includes(prop("id", technician));
    }),
    // @ts-ignore
    filter((technician: Technician) => {
      if (isNil(includeIsActive)) return true;
      return propEq("isActive", includeIsActive, technician as any);
    }),
    map((technician: Technician) => {
      const techAppointments = pipe(
        filter(pathEq(["technician", "id"], prop("id", technician))),
        sort(ascend(prop("startDate") as any))
      )(appointmentsFiltered);
      return {
        ...technician,
        appointments: techAppointments,
      };
    })
    // @ts-ignore
  )(technicians);
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.selectDate}>
          <DateInput
            value={dateToFocusOn}
            label=""
            postChange={(date: Date) => {
              setDateToFocusOn(date);
            }}
          />
        </div>
        <div style={{ height: 10 }}>{(loading || loadingTechnicians) && <LinearProgress />}</div>
        <DriveTimeDistanceTable technicians={techniciansToShowInCalendar as any} />
      </main>
      <DriveTimeDistanceFilterSection
        initialMarkets={marketsToInclude}
        initialTechnicians={techniciansToInclude}
        includeIsActive={includeIsActive}
        allTechnicians={filteredTechs}
        setIncludeIsActive={setIncludeIsActive}
        setTechniciansToInclude={setTechniciansToInclude}
        setMarketsToInclude={setMarketsToInclude}
        allMarkets={allMarkets}
      />
    </div>
  );
};

export default DriveTimeDistancesPage;
