import React, { Fragment } from "react";
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from "@material-ui/core";
import { Field } from "formik";
import ServiceRow from "./ServiceRow";
import { ProductSelectionOption } from "./ProductRow";
import { PossibleEjiService } from "../../generated/nest-graphql";

type ServiceTableProps = {
  partsStores: any;
  ejiType?: string;
  calculatePossibleEJIPriceInfo: any;
  parts: ProductSelectionOption[];
  serviceList: PossibleEjiService[];
  taxable: boolean;
};

export const ServicesTable: React.FC<ServiceTableProps> = ({
  partsStores,
  ejiType,
  calculatePossibleEJIPriceInfo,
  taxable,
  parts,
  serviceList,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-1/12">Quote to Customer</TableCell>
            <TableCell></TableCell>
            <TableCell className="w-5/12">Service</TableCell>
            <TableCell className="w-1/6">Customer Price</TableCell>
            <TableCell className="w-1/6">Technician Base Pay</TableCell>
            <TableCell className="w-1/12">Delete</TableCell>
            <TableCell className="w-1/12" />
          </TableRow>
        </TableHead>
        <TableBody>
          <Field name="services">
            {({ field: { value: services = [] } }) => (
              <Fragment>
                {services.map((row, serviceIdx) => (
                  <ServiceRow
                    key={serviceIdx}
                    row={row}
                    taxable={taxable}
                    serviceIdx={serviceIdx}
                    partsStores={partsStores}
                    ejiType={ejiType}
                    calculatePossibleEJIPriceInfo={calculatePossibleEJIPriceInfo}
                    parts={parts}
                    serviceList={serviceList}
                  />
                ))}
              </Fragment>
            )}
          </Field>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
