import React, { useEffect, useState } from "react";
import { Column } from "react-table";
import { dotPath } from "../../lib/functions";
import { defaultTo, map, pathOr, pipe, splitEvery } from "ramda";
import { SelectablePageableEntityTableRenderer } from "./SelectablePageableEntityTableRenderer";
import { Query } from "../../generated/nest-graphql";
import { useJobsFilter } from "../../contexts/jobs-filter-context";

interface SelectablePageableEntityTableProps<T extends object> {
  queryResult: Query;
  columns: Array<Column<T>>;
  loadMore?: () => void;
  queryKey: string;
  numRecords: number;
  limit: number;
  spec: (val: Record<string, any>) => Record<string, any>;
  title: string;
  endAdornments?: React.ReactNode;
}

export const SelectablePageableEntityTable = <T extends object>({
  queryResult,
  columns,
  loadMore,
  queryKey,
  numRecords,
  limit,
  spec,
  title,
  endAdornments,
}: SelectablePageableEntityTableProps<T>) => {
  const { state } = useJobsFilter();
  useEffect(() => {
    setPageIndex(0);
  }, [state]);
  const nextPage = async () => {
    await loadMore();
    setPageIndex((prev) => prev + 1);
  };
  const prevPage = () => {
    setPageIndex((prev) => prev - 1);
  };
  const [pageIndex, setPageIndex] = useState(0);
  // @ts-ignore
  const canNextPage: boolean = dotPath(`${queryKey}.pageInfo.hasNextPage`, queryResult);
  const canPrevPage = pageIndex !== 0;
  const dataToPass = pipe(pathOr([], [queryKey, "edges"]), map(spec), splitEvery(limit), (val) =>
    defaultTo([], val[pageIndex])
  )(
    // @ts-ignore
    queryResult
  );
  return (
    <SelectablePageableEntityTableRenderer
      title={title}
      currentPage={pageIndex}
      columns={columns}
      numRecords={numRecords}
      data={dataToPass}
      canNextPage={canNextPage}
      nextPage={nextPage}
      canPrevPage={canPrevPage}
      prevPage={prevPage}
      endAdornments={endAdornments}
    />
  );
};
