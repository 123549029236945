import { cond, where, equals } from "ramda";
import { exists } from "../../commonFunctions";
import { Event } from "../../generated/nest-graphql";
import { EVENTS } from "./ServiceConstants";

export const generateServiceEventMessages = (serviceEvents: Event[]) => {
  const transform = cond<Event[], string>([
    [
      where({ event: equals(EVENTS.UnableToRetrieveVehicleInformationFromMotor) }),
      (_) => {
        return `Unable to retrieve information from MOTOR API for vehicle.`;
      },
    ],
    [
      where({ event: equals(EVENTS.UnableToTranslateSymptomOrRequestedService) }),
      (_) => {
        return `Unable to translate a symptom or requested service.`;
      },
    ],
    [
      where({ event: equals(EVENTS.VehicleSymptomMissingProperties) }),
      (event) => {
        const vehicleSymptom = event.data?.vehicleSymptom;
        const axle = vehicleSymptom?.axle ?? "unknownAxle";
        const symptom = vehicleSymptom?.symptom ?? "unknownSymptom";
        return `Symptom is missing property(s): axle - ${axle}, symptom - ${symptom}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.SymptomServicesForBrakeTypeNotFound) }),
      (event) => {
        const vehicleSymptom = event.data?.vehicleSymptom;
        const axle = vehicleSymptom?.axle ?? "unknown";
        const symptom = vehicleSymptom?.symptom ?? "unknownSymptom";
        const brakeType = event.data?.brakeType ?? "unknownBrakeType";
        return `Services could not be generated properly for symptom ${symptom} for axle ${axle} with brake type: ${brakeType}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.SymptomNeedsSalesAssistance) }),
      (event) => {
        const vehicleSymptom = event.data?.vehicleSymptom;
        const axle = vehicleSymptom?.axle ?? "unknown";
        const symptom = vehicleSymptom?.symptom ?? "unknownSymptom";
        const brakeType = event.data?.brakeType ?? "unknownBrakeType";
        return `Symptom ${symptom} for ${axle} axle with brake type: ${brakeType} needs sales assistance.`;
      },
    ],
    [
      where({ event: equals(EVENTS.RequestedServiceNeedsSalesAssistance) }),
      (event) => {
        const requestedService = event.data?.requestedService ?? "unknownRequestedService";
        return `Requested service ${requestedService} needs sales assistance.`;
      },
    ],
    [
      where({ event: equals(EVENTS.ServicesForRequestedServiceNotFound) }),
      (event) => {
        const requestedService = event.data?.requestedService ?? "unknownRequestedService";
        const areStandalone = event.data?.areStandalone;
        const areStandaloneMessage = exists(areStandalone) ? (areStandalone ? "the standalone" : "the add-on") : "";
        return `Services could not be generated properly for ${areStandaloneMessage} requested service: ${requestedService}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.UnableToRetrieveDefaultServices) }),
      (event) => {
        const brakeType = event.data?.brakeType ?? "unknownBrakeType";
        return `Unable to retrieve default services for brake type: ${brakeType}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.UnableToAssignPartToItem) }),
      (event) => {
        const vehicleType = event.data?.vehicleType ?? "unknownVehicleType";
        const item = event.data?.item?.type ?? "unknownItem";
        return `Unable to assign a part to ${item} for vehicle type: ${vehicleType}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.UnableToFindPartForPartId) }),
      (event) => {
        const vehicleType = event.data?.vehicleType ?? "unknownVehicleType";
        const item = event.data?.item?.type ?? "unknownItem";
        return `Unable to assign a part to ${item} for vehicle type: ${vehicleType}.`;
      },
    ],
    [
      where({ event: equals(EVENTS.VehicleFoundOnVehicleExceptionList) }),
      (_) => {
        return `Unable to populate services with parts because vehicle was found on the vehicle exceptions list.`;
      },
    ],
    [
      where({ event: equals(EVENTS.FailedToPopulateServicesWithParts) }),
      (event) => {
        const vehicleType = event.data?.vehicleType ?? "unknownVehicleType";
        return `Unable to populate services with parts for vehicle type: ${vehicleType}.`;
      },
    ],
  ]);

  // map event to message
  return serviceEvents.map((event) => {
    return transform(event);
  });
};
