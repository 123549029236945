import { Color } from "@material-ui/lab/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "../util";
type SnackbarState = {
  showSnackBar: boolean;
  snackBarMessage?: string;
  snackBarType?: Color;
  snackBarAutoHideDuration?: number;
};
export const snackbarInitialState: SnackbarState = {
  showSnackBar: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: snackbarInitialState,
  reducers: {
    show: (state, action: PayloadAction<{ message: string; type: Color; autoHideDuration: number }>) => {
      state.showSnackBar = true;
      state.snackBarMessage = action.payload.message;
      state.snackBarType = action.payload.type;
      state.snackBarAutoHideDuration = action.payload.autoHideDuration;
    },
    close: (state) => {
      state.showSnackBar = false;
      state.snackBarMessage = null;
    },
  },
});

export const { show, close: snackbarClose } = snackbarSlice.actions;
export const useShowSuccess = () => {
  const dispatch = useAppDispatch();
  return ({
    message,
    type = "success",
    autoHideDuration = 3000,
  }: {
    message: string;
    type?: Color;
    autoHideDuration?: number;
  }) => dispatch(show({ message, type, autoHideDuration }));
};
export const useShowError = () => {
  const dispatch = useAppDispatch();
  return ({
    message,
    type = "error",
    autoHideDuration = 3000,
  }: {
    message: string;
    type?: Color;
    autoHideDuration?: number;
  }) => dispatch(show({ message, type, autoHideDuration }));
};
export const useShowInfo = () => {
  const dispatch = useAppDispatch();
  return ({
    message,
    type = "info",
    autoHideDuration = 3000,
  }: {
    message: string;
    type?: Color;
    autoHideDuration?: number;
  }) => dispatch(show({ message, type, autoHideDuration }));
};
export const useShowWarning = () => {
  const dispatch = useAppDispatch();
  return ({
    message,
    type = "warning",
    autoHideDuration = 3000,
  }: {
    message: string;
    type?: Color;
    autoHideDuration?: number;
  }) => dispatch(show({ message, type, autoHideDuration }));
};
