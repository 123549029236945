import * as React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetEstimateArgs, QueryGetJobsPaginatedV2Args } from "../../generated/nest-graphql";
import { AddJob } from "../Jobs/AddJob";
import { cond, head, path, prop, T } from "ramda";
import { JobDetails } from "../Jobs/JobDetails";
import { Button } from "../Buttons/Button";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { GET_JOBS_PAGINATED_V2 } from "../../graphql/queries/getJobsPaginatedV2";
import {
  DEFAULT_SERVICE_CATALOGUE_USED,
  SERVICE_CATALOGUE_USED_SERVICES,
  INITIAL_JOB_STATUS,
} from "../../lib/constants";
import { vehicleSymptomToVehicleSymptomFormValue } from "../Contacts/contactDetailsFormHelpers";
import { getSymptoms } from "../specs/estimateDetailSpec";
import { exists } from "../../commonFunctions";

export const EstimateJob: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const [enableAddJob, setEnableAddJob] = useState(false);
  const estimatesResult = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
    fetchPolicy: "cache-first",
  });
  const { data } = useQuery<Query, QueryGetJobsPaginatedV2Args>(GET_JOBS_PAGINATED_V2, {
    variables: {
      paginatedQueryInput: {
        limit: 1,
        filter: {
          "jobCopy.estimate": estimateId,
        },
        skip: 0,
      },
    },
  });
  if (!path(["getJobsPaginatedV2", "edges"], data) || !estimatesResult.data) return null;
  const {
    contact,
    items,
    market,
    serviceLocation,
    serviceLocationNotes,
    vehicleInfo,
    taxable,
    privateNotes,
    estimateNotes,
    services,
    priceInfo,
    serviceCatalogueUsed,
    partsLeadTimeInDays,
    callForPartsTicketNumber,
    partsNotes,
    vehicleSymptoms,
    requestedServices,
  } = estimatesResult.data.getEstimate;

  if (!path(["getJobsPaginatedV2", "edges", "length"], data)) {
    return (
      <div>
        {enableAddJob ? (
          <AddJob
            initialValues={{
              contact,
              taxable: !exists(contact?.taxExemptEin) ?? taxable ?? true,
              appointmentId: null,
              items,
              estimate: estimateId,
              market,
              serviceLocation,
              serviceLocationNotes,
              description: estimateNotes,
              status: INITIAL_JOB_STATUS,
              type: "Brake Repair Service",
              jobName: "",
              partsLocation: "",
              make: prop("make", vehicleInfo),
              model: prop("model", vehicleInfo),
              year: prop("year", vehicleInfo),
              extraInfo: prop("extraInfo", vehicleInfo),
              vin: vehicleInfo?.vin ?? null,
              licensePlate: vehicleInfo?.licensePlate ?? null,
              licensePlateState: vehicleInfo?.licensePlateState ?? null,
              privateNotes,
              serviceCatalogueUsed: serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
              services: cond([
                [
                  (_val) => serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES && services?.length > 0,
                  () => services,
                ],
                [T, () => []],
              ])(null),
              discounts: priceInfo?.discounts ?? [],
              promoCodes: priceInfo?.promoCodes ?? [],
              priceInfo,
              partsLeadTimeInDays,
              callForPartsTicketNumber,
              partsNotes,
              vehicleSymptoms: vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? null,
              requestedServices: requestedServices ?? null,
              symptoms: getSymptoms(estimatesResult.data.getEstimate) ?? "",
            }}
          />
        ) : (
          <Button onClick={() => setEnableAddJob(true)}>Add Job</Button>
        )}
      </div>
    );
  }
  const job = head(data.getJobsPaginatedV2.edges);
  return <JobDetails jobId={job.id} />;
};
