export const EVENTS = {
  UnableToRetrieveVehicleInformationFromMotor: 'UnableToRetrieveVehicleInformationFromMotor',
  UnableToTranslateSymptomOrRequestedService: 'UnableToTranslateSymptomOrRequestedService',
  VehicleSymptomMissingProperties: 'VehicleSymptomMissingProperties',
  SymptomServicesForBrakeTypeNotFound: 'SymptomServicesForBrakeTypeNotFound',
  SymptomNeedsSalesAssistance: 'SymptomNeedsSalesAssistance',
  RequestedServiceNeedsSalesAssistance: 'RequestedServiceNeedsSalesAssistance',
  ServicesForRequestedServiceNotFound: 'ServicesForRequestedServiceNotFound',
  UnableToRetrieveDefaultServices: 'UnableToRetrieveDefaultServices',
  UnableToAssignPartToItem: 'UnableToAssignPartToItem',
  UnableToFindPartForPartId: 'UnableToFindPartForPartId',
  VehicleFoundOnVehicleExceptionList: 'VehicleFoundOnVehicleExceptionList',
  FailedToPopulateServicesWithParts: 'FailedToPopulateServicesWithParts',
};