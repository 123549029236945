import React, { useCallback, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Button } from "../Buttons/Button";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import {
  Mutation,
  MutationSendEstimateForJobArgs,
  PartsStore,
  PossibleEjiService,
  Query,
  QueryCalculatePossibleEjiPriceInfoArgs,
  QueryGenerateServicesArgs,
  QueryGetAllPossibleServicesArgs,
} from "../../generated/nest-graphql";
import { ServicesReceiptSection } from "./ServicesReceiptSection";
import { Field } from "formik";
import { isNil, path } from "ramda";
import { ServicesTable } from "./ServicesTable";
import { CALCULATE_POSSIBLE_EJI_PRICE_INFO } from "../../graphql/queries/calculatePossibleEJIPriceInfo";
import {
  discountsToEjiDiscountInput,
  pricingConfigToEjiPricingConfigInput,
  promoCodesToPriceInfoInput,
  servicesToPossibleEjiServiceInput,
} from "../specs/servicesSpec";
import { ProductSelectionOption } from "./ProductRow";
import { GET_ALL_POSSIBLE_SERVICES } from "../../graphql/queries/getAllPossibleServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { pipe } from "fp-ts/lib/function";
import { useShowSuccess, useShowWarning } from "../../redux/slices/snackbar";
import { GENERATE_SERVICES } from "../../graphql/queries/generateServices";
import { generateServiceEventMessages } from "./ServiceFunctions";
import { doesNotExist } from "../../commonFunctions";
import { GET_VEHICLE_SERVICE_EXCEPTION } from "../../graphql/queries/getVehicleServiceException";
import { NuModal } from "../NuModal";
import gql from "graphql-tag";
import { SEND_ESTIMATE_FOR_JOB } from "../../graphql/mutations/sendEstimateForJob";
import { Alert } from "../Alert";
import { useAuth0 } from "@auth0/auth0-react";
import { vehiclesDetailsFields } from "../../graphql/queries/getVehicles";
import currency from "currency.js";

type ServicesSectionProps = {
  ejiType?: string;
  partsStores: PartsStore[];
  setValues: any;
  values: any;
  taxable: boolean;
  parts: ProductSelectionOption[];
  jobId?: string;
  parentSubmit?: () => Promise<void>;
};

type AddServiceProps = {
  calculatePossibleEJIPriceInfo: any;
  taxable: boolean;
  ejiType?: string;
  serviceList: PossibleEjiService[];
};

type ServiceOption = {
  label: string;
  value: PossibleEjiService;
};

export const GET_JOB_ESTIMATE_SENT_AT = gql`
  query getJob($id: ID!) {
    getJob(id: $id) {
      id
      vehicle {
        ${vehiclesDetailsFields}
      }
      vehicleId
      estimateSentAt
    }
  }
`;

const ServicesSection = ({
  partsStores,
  values,
  setValues,
  ejiType,
  parts,
  taxable,
  jobId,
  parentSubmit,
}: ServicesSectionProps) => {
  const showSuccess = useShowSuccess();
  const showWarning = useShowWarning();

  const canSendEstimateForJob = !!jobId;

  const [isExceptionVehicleModalVisible, setIsExceptionVehicleModalVisible] = useState(false);
  const [isCopyEstimateModalVisible, setIsCopyEstimateModalVisible] = useState(false);
  const [isSendEstimateConfirmation, setIsSendEstimateConfirmationVisible] = useState(false);

  const [isLoadingSendEstimate, setIsLoadingSendEstimate] = useState(false);

  const { user } = useAuth0();

  const { data: jobData, refetch: refetchGetJob, loading } = useQuery<Query>(GET_JOB_ESTIMATE_SENT_AT, {
    variables: {
      id: jobId,
    },
    skip: !jobId,

  });

  useEffect(() => {
    refetchGetJob();
  }, []);

  const vehicle = loading ? {} : jobData?.getJob.vehicle;

  const [sendEstimateForJob] = useMutation<Mutation, MutationSendEstimateForJobArgs>(SEND_ESTIMATE_FOR_JOB, {
    variables: {
      sendEstimateForJobInput: {
        jobId,
      },
    },
    onCompleted: () => {
      showSuccess({ message: "Estimate sent successfully" });
      refetchGetJob();
    },
    onError: (error) => {
      console.error("Error sending estimate", error);
      Sentry.captureException(error, {
        extra: {
          message: "Error sending estimate",
          jobId,
        },
        user: {
          email: user?.email,
        },
      });
      showWarning({ message: "Error sending estimate" });
    },
  });

  const handleSendEstimate = async () => {
    try {
      /*if (!values?.symptomDiagnosisCategory) {
        showWarning({ message: "You must select a symptom diagnosis category before sending an estimate" });
        return;
      }*/
      setIsLoadingSendEstimate(true);
      setIsSendEstimateConfirmationVisible(false);
      if (parentSubmit) {
        await parentSubmit();
      }
      await sendEstimateForJob();
    } catch (e) {
    } finally {
      setIsLoadingSendEstimate(false);
    }
  };

  const onClickSendEstimate = () => {
    setIsSendEstimateConfirmationVisible(true);
  };
  const onClickCopyEstimate = () => {
    if (canSendEstimateForJob) {
      setIsCopyEstimateModalVisible(true);
    } else {
      copyEstimateDetailsToClipboard();
    }
  };

  const [calculatePossibleEjiPriceInfo] = useLazyQuery<Query, QueryCalculatePossibleEjiPriceInfoArgs>(
    CALCULATE_POSSIBLE_EJI_PRICE_INFO,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (priceData) => {
        if (!priceData?.calculatePossibleEJIPriceInfo) return;
        const { ejiServices, ejiPriceInfo } = priceData.calculatePossibleEJIPriceInfo;
        if (!ejiServices || !ejiPriceInfo) return;
        setValues({
          ...values,
          promoCodes: ejiPriceInfo?.promoCodes ?? [],
          services: ejiServices,
          discounts: ejiPriceInfo?.discounts ?? [],
          priceInfo: ejiPriceInfo,
        });
      },
      onError: (error) => {
        console.log("Error in calculatePossibleEjiPriceInfo:", error);
      },
    }
  );

  useEffect(() => {
    if(values?.services?.some(item => !item.partsCalcs)) {
      const newServices = values.services.map((service) => {
        if(service.partsCalcs) return service
        return {
          ...service,
          partsCalcs: {
            overriddenCustomerPrice: service.items.some(
              (item) => item.category === "Part" && currency(item.customerPrice ?? 0, { fromCents: true }).intValue
            ),
          }
        }
      })
      calculatePossibleEjiPriceInfo({
        variables: {
          calculatePossibleEJIPriceInfoInput: {
            pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
            services: servicesToPossibleEjiServiceInput(newServices),
            discounts: discountsToEjiDiscountInput(values.discounts),
            promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
            marketName: values.market,
            taxable,
            calculateAllServices: ejiType === "INVOICE",
          },
        },
      });
    }
  }, [values])
  const [
    generateServices,
    {
      data: generateServicesData,
      called: generateServicesCalled,
      loading: generateServicesLoading,
    },
  ] = useLazyQuery<Query, QueryGenerateServicesArgs>(GENERATE_SERVICES, {
    // fetchPolicy: "network-only",
    // notifyOnNetworkStatusChange: true,
    onCompleted: (generateServicesData) => {
      setValues((currentValues) => {
        if (generateServicesData?.generateServices?.events?.length > 0) {
          showWarning({
            message: generateServiceEventMessages(generateServicesData?.generateServices?.events).join("\n"),
            autoHideDuration: null,
          });
        }
        return {
          ...currentValues,
          services: generateServicesData?.generateServices?.priceResult?.ejiServices ?? [],
          discounts: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo?.discounts ?? [],
          priceInfo: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
          serviceGenerationEvents: generateServicesData?.generateServices?.events ?? [],
        };
      });
    },
  });
  const { data: possibleServices } = useQuery<Query, QueryGetAllPossibleServicesArgs>(GET_ALL_POSSIBLE_SERVICES, {
    variables: {
      getAllPossibleServicesInput: {
        inEstimate: false,
      },
    },
  });

  //const hasVehicleData = !!values.year && !!values.make && !!values.model;
  const hasVehicleData = (vehicle && !!vehicle.year && !!vehicle.make && !!vehicle.model);
  const { data: vehicleExceptionData } = useQuery<Query>(GET_VEHICLE_SERVICE_EXCEPTION, {
    variables: {
      getVehicleServiceExceptionInput: {
        year: vehicle && Number(vehicle.year),
        make: vehicle?.make,
        model: vehicle?.model,
      },
    },
    skip: !hasVehicleData,
  });
  const isExceptionVehicle =
    hasVehicleData && !!vehicleExceptionData?.getVehicleServiceException?.id;

  const onSubmitGetDefault = useCallback(() => {
    if (isExceptionVehicle) {
      setIsExceptionVehicleModalVisible(true);
    }
    if (!generateServicesCalled) {
      generateServices({
        variables: {
          generateServicesInput: {
            year: vehicle.year, //path(["year"], values) ?? path(["contact", "lead", "VehicleInfo", "year"], values),
            make: vehicle.make, //path(["make"], values) ?? path(["contact", "lead", "vehicleInfo", "make"], values),
            model: vehicle.model, //path(["model"], values) ?? path(["contact", "lead", "vehicleInfo", "model"], values),
            market: path(["market"], values) ?? path(["contact", "lead", "market"], values),
            requestedServices:
              path(["requestedServices"], values) ?? path(["contact", "lead", "requestedServices"], values),
            vehicleSymptoms:
              path(["vehicleSymptoms"], values) ??
              pipe(path(["contact", "lead", "vehicleSymptoms"], values), (symptoms: any[]) =>
                symptoms?.map((s) => ({
                  axle: s?.axle,
                  symptom: s?.symptom,
                }))
              ),
            jobId: jobId,
          },
        },
      });
    }
    // Get values from cache
    if (generateServicesCalled && !generateServicesLoading) {
      setValues((currentValues) => {
        if (generateServicesData?.generateServices?.events?.length > 0) {
          showWarning({
            message: generateServiceEventMessages(generateServicesData?.generateServices?.events).join("\n"),
            autoHideDuration: null,
          });
        }
        return {
          ...currentValues,
          services: generateServicesData?.generateServices?.priceResult?.ejiServices ?? [],
          discounts: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo?.discounts ?? [],
          priceInfo: generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
          serviceGenerationEvents: generateServicesData?.generateServices?.events ?? [],
        };
      });
    }
  }, [
    jobId,
    generateServicesData?.generateServices?.priceResult?.ejiPriceInfo,
    generateServicesData?.generateServices?.priceResult?.ejiServices,
    generateServicesData?.generateServices?.events,
    generateServicesCalled,
    generateServicesLoading,
    generateServices,
    setValues,
    values,
    showWarning,
    isExceptionVehicle,
  ]);

  if (generateServicesLoading) return <FontAwesomeIcon icon={faSpinner as any} spin={true} />;
  if (!possibleServices || (!generateServicesCalled && doesNotExist(values.services))) {
    return (
      <Button onClick={onSubmitGetDefault} type={"button"}>
        + Generate Default Services
      </Button>
    );
  }
  const serviceList: PossibleEjiService[] = possibleServices.getAllPossibleServices;
  const copyEstimateDetailsToClipboardV1 = () => {
    const { services, priceInfo } = values;
    const servicesInEstimate = services?.filter((service) => service.inEstimate);
    const introString = `Here's your unique estimate, pricing includes mobile service and warranty:\n\n`;
    const recommendedServicesString =
      servicesInEstimate?.length > 0
        ? `Recommended Services: ${servicesInEstimate.map((service) => `${service.name}`).join(", ")}\n`
        : "";
    const discountString =
      (priceInfo?.discounts?.length > 0 || priceInfo?.promoCodes?.length > 0) &&
      Number(priceInfo.combinedDiscountTotal) !== 0
        ? `\nDiscount: – $${priceInfo?.combinedDiscountTotal}`
        : "";
    const totalString =
      Number(priceInfo?.amountDue) !== 0 ? `\nYour total: $${priceInfo?.finalSubTotal} + tax` : "";

    const endingString = "\n\nWhen and where would you like your service scheduled? 🗓️";
    navigator.clipboard.writeText(
      `${introString}${recommendedServicesString}${discountString}${totalString}${endingString}`
    );
    showSuccess({ message: "Estimate copied to clipboard" });
  };
  const copyEstimateDetailsToClipboardV2 = () => {
    const { services, priceInfo } = values;
    const servicesInEstimate = services?.filter((service) => service.inEstimate);
    const introString = `Here's your custom estimate, pricing includes mobile service and warranty:\n\n`;
    const recommendedServicesString =
      servicesInEstimate?.length > 0
        ? `Recommended Services: ${servicesInEstimate.map((service) => `${service.name}`).join(", ")}\n`
        : "";
    const discountString =
      (priceInfo?.discounts?.length > 0 || priceInfo?.promoCodes?.length > 0) &&
      Number(priceInfo.combinedDiscountTotal) !== 0
        ? `\nDiscount: – $${priceInfo?.combinedDiscountTotal}`
        : "";
    const totalString =
      Number(priceInfo?.amountDue) !== 0 ? `\nYour total: $${priceInfo?.finalSubTotal} + tax` : "";

    const endingString = "\n\nWhen and where would you like your service scheduled? 🗓️";
    navigator.clipboard.writeText(
      `${introString}${recommendedServicesString}${discountString}${totalString}${endingString}`
    );
    showSuccess({ message: "Estimate copied to clipboard" });
  };
  const marketsIncludedOnCopyEstimateExperiment = ["Austin", "Houston", "San Antonio", "Nashville", "Miami"];
  const market = values?.market ?? values?.contact?.lead?.market ?? null;
  const copyEstimateDetailsToClipboard = marketsIncludedOnCopyEstimateExperiment.includes(market)
    ? copyEstimateDetailsToClipboardV2
    : copyEstimateDetailsToClipboardV1;

  return (
    <div>
      <ServicesTable
        partsStores={partsStores}
        ejiType={ejiType}
        calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        parts={parts}
        taxable={taxable}
        serviceList={serviceList}
      />
      <div className="flex justify-between">
        <AddService
          taxable={taxable}
          calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
          ejiType={ejiType}
          serviceList={serviceList}
        />
        {canSendEstimateForJob && (
          <Box mr={2} className="flex items-center">
            <Button onClick={onClickSendEstimate} type={"button"} disabled={isLoadingSendEstimate}>
              {isLoadingSendEstimate ? <FontAwesomeIcon icon={faSpinner as any} spin={true} /> : "Send Estimate"}
            </Button>
          </Box>
        )}
        <Box mr={2} className="flex items-center">
          <Button onClick={onClickCopyEstimate} type={"button"}>
            Copy Estimate Details
          </Button>
        </Box>
      </div>
      <ServicesReceiptSection
        taxable={taxable}
        receiptValues={values?.priceInfo ?? {}}
        calculatePossibleEJIPriceInfo={calculatePossibleEjiPriceInfo}
        ejiType={ejiType}
        jobId={jobId}
      />
      <NuModal isOpen={isExceptionVehicleModalVisible} title="Exception Vehicle Reminder">
        <div>
          <p>This is considered an exception vehicle. Make sure to:</p>
          <br />
          <ul className="ml-4">
            <li className="list-disc">Provide parts lead time</li>
            <li className="list-disc">Check the rules for parts</li>
          </ul>
          <Button
            className="mt-4 float-right"
            type={"button"}
            onClick={() => {
              setIsExceptionVehicleModalVisible(false);
            }}
          >
            OK
          </Button>
        </div>
      </NuModal>

      <NuModal
        isOpen={isSendEstimateConfirmation}
        title={
           !jobData?.getJob?.estimateSentAt
            ? "Send Estimate SMS"
            : "Estimate SMS Already Sent"
        }
      >
        <div>
          {/*!values?.symptomDiagnosisCategory */ false? (
            <Alert severity="error" style={{ marginBottom: "24px" }}>
              You must select a symptom diagnosis category before sending an estimate.
              <br />
              <br />
              You can select it under the "Diagnosis and Recommendation" section.
            </Alert>
          ) : (
            <>
              {!!jobData?.getJob?.estimateSentAt && (
                <Alert severity="warning" style={{ marginBottom: "24px" }}>
                  Estimate already sent
                </Alert>
              )}
              <p>
                {!!jobData?.getJob?.estimateSentAt
                  ? "It seems the estimate was already sent for this job. Are you sure you want to send it again?"
                  : "Are you sure you want to send the estimate?"}
              </p>
              <br />
              <p>
                This action will <strong>send an SMS to the customer</strong> with their estimate.
              </p>
            </>
          )}

          <div className="flex flex-row float-right mt-8">
            {(
              <Button className="mr-4" type={"button"} onClick={handleSendEstimate}>
                {jobData?.getJob?.estimateSentAt ? "SEND AGAIN ANYWAY" : "SEND ESTIMATE"}
              </Button>
            )}

            <Button
              className=""
              type={"button"}
              onClick={() => {
                setIsSendEstimateConfirmationVisible(false);
              }}
            >
              CLOSE
            </Button>
          </div>
        </div>
      </NuModal>

      <NuModal isOpen={isCopyEstimateModalVisible} title={"Use the send button"}>
        <div>
          <p>
            We are now introducing the "Send Estimate" button. You probably want to use that instead of copying the
            estimate.
          </p>

          <div className="flex flex-row float-right mt-4">
            <Button
              className="mr-4"
              type={"button"}
              onClick={() => {
                setIsCopyEstimateModalVisible(false);
                copyEstimateDetailsToClipboard();
              }}
            >
              COPY ANYWAY
            </Button>

            <Button
              className=""
              type={"button"}
              onClick={() => {
                setIsCopyEstimateModalVisible(false);
              }}
            >
              CLOSE
            </Button>
          </div>
        </div>
      </NuModal>
    </div>
  );
};

const AddService = ({ calculatePossibleEJIPriceInfo, ejiType, serviceList, taxable }: AddServiceProps) => {
  const [selectedService, setSelectedService] = useState<ServiceOption | null>(null);
  const servicesToOptions = (serviceList: PossibleEjiService[]) => {
    return serviceList.map((service) => {
      return {
        label: service.name,
        value: service,
      };
    });
  };
  return (
    <div className="flex items-center my-4">
      <Autocomplete
        value={selectedService}
        onChange={(_, selectedOption: ServiceOption) => {
          setSelectedService(selectedOption);
        }}
        options={servicesToOptions(serviceList)}
        getOptionLabel={(option) => option.label}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Select Service" variant="outlined" />}
      />
      <Box ml={2}>
        <Field name={"services"}>
          {({ field: { value }, form: { values } }) => {
            const currentServices = value ?? [];
            return (
              <Button
                onClick={() => {
                  if (!isNil(selectedService)) {
                    const ejiService = {
                      ...selectedService.value,
                      partsCalcs: {
                        overriddenCustomerPrice: selectedService.value.items.some(
                          (item) => item.category === "Part" && currency(item.customerPrice ?? 0, { fromCents: true }).intValue
                        ),
                      },
                      items: selectedService.value.items
                        .filter(({ behavior }) => behavior === "Default")
                        .map((item) => ({
                          ...item,
                          orderItem: {
                            ...item.orderItem,
                            partsStore: !values.partsOrdered ? values?.technician?.homePartsStore ?? null : null,
                          },
                        })),
                    };
                    calculatePossibleEJIPriceInfo({
                      variables: {
                        calculatePossibleEJIPriceInfoInput: {
                          pricingConfig: pricingConfigToEjiPricingConfigInput(values.priceInfo?.pricingConfig),
                          services: servicesToPossibleEjiServiceInput([...currentServices, ejiService]),
                          discounts: discountsToEjiDiscountInput(values.discounts),
                          promoCodes: promoCodesToPriceInfoInput(values.promoCodes),
                          marketName: values.market,
                          taxable: taxable,
                          calculateAllServices: ejiType === "INVOICE",
                        },
                      },
                    });
                    setSelectedService(null);
                  }
                }}
                type={"button"}
              >
                + Add Service
              </Button>
            );
          }}
        </Field>
      </Box>
    </div>
  );
};

export default ServicesSection;
