import React from "react";
import { pluck } from "ramda";
import { AppointmentFilterSectionInternals } from "./AppointmentsFilterSectionInternals";
import { Technician } from "../../generated/nest-graphql";

export const AppointmentsFilterSection: React.FC<{
  initialTechnicians: string[];
  includeIsActive: boolean | null;
  setIncludeIsActive: (val: boolean) => void;
  setTechniciansToInclude: any;
  allTechnicians: Technician[];
}> = ({ setTechniciansToInclude, initialTechnicians, includeIsActive, setIncludeIsActive, allTechnicians }) => {
  const allTechIds = pluck("id", allTechnicians);
  return (
    <AppointmentFilterSectionInternals
      techniciansToInclude={initialTechnicians}
      setTechniciansToInclude={setTechniciansToInclude}
      includeIsActive={includeIsActive}
      setIncludeIsActive={setIncludeIsActive}
      technicians={allTechnicians}
      allTechnicianIds={allTechIds}
    />
  );
};
